import { Cached, ThumbUpAlt, ThumbDownAlt, SentimentSatisfied, SentimentDissatisfied, SentimentSatisfiedAlt, SentimentVerySatisfied, SentimentVeryDissatisfied } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Typography, Snackbar, TextField, IconButton, Radio, FormControlLabel, RadioGroup } from "@mui/material";
import { useEffect, useState, useCallback, useRef } from "react";
import { ReactComponent as JamieImage } from "images/Jamie.svg";
import { useMutation } from "@apollo/client";
import { Mutations } from "services/apollo";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, doc, orderBy, query, setDoc, updateDoc, addDoc, getDoc, getDocs, arrayUnion, limit } from "firebase/firestore";
import { useAuth } from "contexts/User";
import { useFirebase } from "contexts/Firebase";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import DemoServicesCreator from './JamieComponents/DemoServicesCreator';
import { ResetChatButton } from './JamieComponents/ResetChatButton';
import CancelButton from './JamieComponents/CancelButton';
import ClearButton from './JamieComponents/ClearButton';
// import SampleQuestionsButton from './JamieComponents/SampleQuestionsButton';
// import DynamicTemplate from "./JamieComponents/DynamicTemplate";


function SuperJamie() {
    const { user, userData } = useAuth();
    const [token, setToken] = useState<string | null>(null);
    const { firestore } = useFirebase();

    const [messageThread, setMessageThread] = useState<Array<{
        id: string;
        text: string;
        user: boolean;
        timestamp: string;
        type: string;
        dynamicTemplate: string;
        data: any;
    }>>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [chatType,] = useState("cold");
    const [hasCobra,] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [conversationId, setConversationId] = useState<string | null>(userData?.get("conversationId"));
    const [isNewConversation, setIsNewConversation] = useState(false);
    const [conversationStarted, setConversationStarted] = useState(false);
    const [showStartButton, setShowStartButton] = useState(true);

    const [messageStream, setMessageStream] = useState<string[]>([]);
    const [cancelMessage] = useMutation(Mutations.cancelMessage);
    const [clearMessages] = useMutation(Mutations.clearMessages);
    useEffect(() => {
        if (user) {
            user.getIdToken().then((token) => {
                setToken(token);
            });
        }
    }, [user]);
    const cancel = () => {
        cancelMessage();
        setUserMessage("");
    }
    const clear = () => {
        clearMessages();
        setUserMessage("");
        setMessageThread([]);
        setConversationStarted(false);
    };
    useEffect(() => {
        if (messageStream.join("") === messageThread[messageThread.length - 1]?.text) {
            console.log("Message Stream is the same as the last message in the thread")
            setMessageStream([]);
        }
    }, [messageStream, messageThread])

    useEffect(() => {
        const createUserSettings = async () => {
            if (user) {
                const userSettingsRef = doc(firestore, "users", user.uid, "settings", "chat");
                const userSettingsDoc = await getDoc(userSettingsRef);
                if (!userSettingsDoc.exists()) {
                    await setDoc(userSettingsRef, {
                        insuranceDataCollected: false,
                        selectedPlan: null,
                        enrollmentStatus: null,
                    });
                    console.log("User settings document created");
                }
            }
        };

        createUserSettings();
    }, [user, firestore]);

    const createNewConversation = useCallback(async (message: string, isPrompt = false) => {
        if (!user) {
            console.error("User is not authenticated");
            return;
        }

        console.log("Creating new conversation for user:", user.uid);
        const convosRef = collection(firestore, `users/${user.uid}/convos`);

        try {
            // Create the conversation data object first
            const convoData = {
                startTimestamp: new Date(),
                chatType: chatType,
                hasCobra: hasCobra,
                userId: user.uid,
                messages: [{
                    text: message,
                    user: true,
                    timestamp: new Date(),
                    type: isPrompt ? "prompt" : "message",
                    dynamicTemplate: "",
                    data: {}
                }],
                feedbacks: []
            };

            console.log("Conversation data to be saved:", convoData);

            // Add the document to Firestore
            const newConvoRef = await addDoc(convosRef, convoData);
            console.log("New conversation document created with ID:", newConvoRef.id);

            // Verify the saved data
            const savedConvoDoc = await getDoc(newConvoRef);
            const savedData = savedConvoDoc.data();
            console.log("Saved conversation data:", savedData);

            if (!savedData?.userId) {
                console.error("userId not found in saved data, attempting to update");
                // If userId is missing, try to update it explicitly
                await updateDoc(newConvoRef, {
                    userId: user.uid
                });
            }

            setConversationId(newConvoRef.id);
            await setDoc(doc(firestore, "users", user.uid), {
                conversationId: newConvoRef.id
            }, { merge: true });

            return newConvoRef.id;
        } catch (error) {
            console.error("Error creating new conversation:", error);
            // Additional error details
            if (error instanceof Error) {
                console.error("Error message:", error.message);
                console.error("Error stack:", error.stack);
            }
            throw error; // Re-throw the error for handling upstream
        }
    }, [user, firestore, chatType, hasCobra]);


    useEffect(() => {
        if (conversationId && user) {
            const verifyConversation = async () => {
                try {
                    const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                    const convoDoc = await getDoc(convoRef);
                    const convoData = convoDoc.data();
                    console.log("Verified conversation data:", convoData);

                    if (!convoData?.userId) {
                        console.warn("UserId missing in conversation, adding it now");
                        await updateDoc(convoRef, {
                            userId: user.uid
                        });
                    }
                } catch (err) {
                    console.error("Error verifying conversation:", err);
                }
            };

            verifyConversation();
        }
    }, [conversationId, user, firestore]);

    useEffect(() => {
        if (!conversationId) {
            if (userData && userData.get("conversationId")) {
                setConversationId(userData.get("conversationId"))
            } else if (userData && !userData.get("conversationId")) {
                createNewConversation("start", true);
            }
        }
    }, [userData, conversationId, createNewConversation])

    const sendMessageStreamImpl = useCallback(async (message: string, isPrompt = false, conversationId: string) => {
        const convoRef = doc(firestore, `users/${user?.uid}/convos/${conversationId}`);

        try {
            const messageId = doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/adminMessages`)).id;
            await updateDoc(convoRef, {
                messages: arrayUnion({
                    id: messageId,
                    text: message,
                    user: true, // Set user to true for user messages
                    timestamp: new Date(),
                    type: isPrompt ? "prompt" : "message",
                    dynamicTemplate: "",
                    data: {},
                })
            });

            console.log("User message added to conversation with ID:", conversationId);
        } catch (error) {
            console.error("Error adding user message to conversation:", error);
        }

        let stream: string[] = [];
        const sse = new EventSource(`${process.env.REACT_APP_JAMIE_SSE_URL}Admin?token=${token}&uid=${user?.uid}&message=${message}&isPrompt=${isPrompt}`,
            {
                withCredentials: true,
            });

        function getRealtimeData(e: any) {
            const parsed = JSON.parse(e.data);
            if (parsed.done) {
                // Add AI's complete response to the conversation
                updateDoc(convoRef, {
                    messages: arrayUnion({
                        id: doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/adminMessages`)).id,
                        text: stream.join(""),
                        user: false, // Set user to false for AI messages
                        timestamp: new Date(),
                        type: "ai",
                        dynamicTemplate: "",
                        data: {}
                    })
                });
                sse.close();
            } else {
                stream.push(parsed.content);
                setMessageStream([...stream]);
            }
        }
        sse.onmessage = e => getRealtimeData(e);
        sse.onerror = (e) => {
            console.error("Error with SSE", e)
            setMessageStream([]);
            sse.close();
        }
    }, [firestore, token, user?.uid]);

    const startConversation = async () => {
        const startMessage = "Hello";
        setConversationStarted(true);
        setShowStartButton(false);
        setShowAskQuestionButtons(true);
        // Directly send the message instead of relying on state updates
        setLoading(true);
        await sendMessageStream(startMessage);
        setLoading(false);
    };

    const sendMessageStream = useCallback(async (message: string, isPrompt = false) => {
        if (!conversationId) {
            console.error("No conversation ID found");
            return;
        }

        if (isNewConversation) {
            // Create a new conversation document
            const convoRef = doc(firestore, `users/${user?.uid}/convos/${conversationId}`);
            await setDoc(convoRef, {
                startTimestamp: new Date(),
                chatType: chatType,
                hasCobra: hasCobra,
                messages: [],
                feedbacks: []
            });
            setIsNewConversation(false);
        }

        await sendMessageStreamImpl(message, isPrompt, conversationId);
    }, [conversationId, isNewConversation, user?.uid, firestore, chatType, hasCobra, sendMessageStreamImpl]);

    const send = useCallback(async () => {
        const message = userMessage;
        setUserMessage("");
        setLoading(true);
        sendMessageStream(message);
        setLoading(false);
        setConversationStarted(true);
    }, [userMessage, sendMessageStream]);
    // const sendPrompt = async (message: string) => {
    //     setLoading(true);
    //     sendMessageStream(message, true);
    //     setLoading(false);
    // }

    const [messages, ,] = useCollection(
        query(
            collection(firestore, "users", user?.uid || "a", "adminMessages"),
            orderBy("timestamp", "asc")
        ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    useEffect(() => {
        if (messages) {
            const newMessages = messages.docs.map((doc) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    text: data.text,
                    user: data.user,
                    type: data.type,
                    dynamicTemplate: data.dynamicTemplate,
                    data: data.data,
                    timestamp: data.timestamp,
                };
            });

            console.log("All mapped messages:", newMessages);
            setMessageThread(newMessages);
        }
    }, [messages]);

    useEffect(() => {
        console.log("Current messageThread state:", messageThread);
    }, [messageThread]);

    const [feedback, setFeedback] = useState<{ [key: string]: string }>({});
    const [feedbackVisible, setFeedbackVisible] = useState<{ [key: string]: boolean }>({});
    const feedbackTimers = useRef<{ [key: string]: NodeJS.Timeout }>({});

    const [showAllFeedback, setShowAllFeedback] = useState(false);

    const handleFeedbackVisibility = useCallback((messageId: string) => {
        if (showAllFeedback) {
            setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
        } else {
            if (feedbackTimers.current[messageId]) {
                clearTimeout(feedbackTimers.current[messageId]);
            }
            feedbackTimers.current[messageId] = setTimeout(() => {
                setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
            }, 120000);
        }
    }, [showAllFeedback]);

    useEffect(() => {
        messageThread.forEach((message) => {
            if (message.type !== "human") {
                handleFeedbackVisibility(message.id);
            }
        });
    }, [messageThread, handleFeedbackVisibility]);

    const resetFeedbackTimer = (messageId: string) => {
        setFeedbackVisible((prev) => ({ ...prev, [messageId]: false }));
        handleFeedbackVisibility(messageId);
    };
    useEffect(() => {
        if (feedbackVisible) {
            console.log("Feedback visible:", feedbackVisible);
        }
    }, [feedbackVisible])
    useEffect(() => {
        messageThread.forEach((message) => {
            if (message.type !== "human") {
                handleFeedbackVisibility(message.id);
            }
        });
    }, [messageThread, handleFeedbackVisibility]);


    const handleSentiment = useCallback(async (sentimentType: string) => {
        console.log("handleSentiment called with sentimentType:", sentimentType);

        setFeedback((prev) => ({ ...prev, sentiment: sentimentType }));

        if (!user?.uid) {
            console.error("User is not authenticated");
            return;
        }

        try {
            if (conversationId) {
                // Update sentiment in the existing conversation
                const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                await updateDoc(convoRef, {
                    sentiment: sentimentType
                });
            } else {
                // If no conversation exists, store sentiment in user's settings
                const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
                await setDoc(settingsRef, { lastSentiment: sentimentType }, { merge: true });
            }
            console.log("Sentiment updated successfully in Firestore");
        } catch (error) {
            console.error("Error updating sentiment in Firestore:", error);
        }
    }, [firestore, user?.uid, conversationId]);

    const [selectedSentiment, setSelectedSentiment] = useState<string | null>(null);
    const [additionalFeedback, setAdditionalFeedback] = useState<string>("");
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [sentimentVisible, setSentimentVisible] = useState<boolean>(true);
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [canContact, setCanContact] = useState<boolean>(true);


    const handleFeedback = useCallback(async (messageId: string, feedbackType: string) => {
        console.log("handleFeedback called with messageId:", messageId, "and feedbackType:", feedbackType);
        try {
            if (conversationId && user?.uid) {
                const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                const messagesRef = collection(firestore, `users/${user.uid}/adminMessages`);

                const convoSnap = await getDoc(convoRef);
                const messageSnap = await getDoc(doc(messagesRef, messageId));

                if (convoSnap.exists() && messageSnap.exists()) {
                    const convoData = convoSnap.data();
                    const messages = convoData.messages || [];
                    const messageData = messageSnap.data();
                    // console.log("Message from separate collection:", messageData);
                    // console.log("Messages in conversation:", messages);
                    const messageTimestamp = messageData.timestamp.toDate().getTime();
                    // console.log("Timestamp of the message from separate collection:", messageTimestamp);
                    // Find the corresponding message in the conversation by matching timestamps with a 1-second tolerance
                    const targetMessage = messages.find((msg: any) => {
                        const msgTimestamp = msg.timestamp.toDate().getTime();
                        console.log(`Comparing timestamps: ${msgTimestamp} vs ${messageTimestamp}`);
                        return Math.abs(msgTimestamp - messageTimestamp) < 1000 && msg.type === messageData.type;
                    });

                    if (targetMessage) {
                        console.log("Matching message found in conversation:", targetMessage);

                        await updateDoc(convoRef, {
                            messages: messages.map((msg: any) =>
                                msg.id === targetMessage.id ? { ...msg, feedback: feedbackType } : msg
                            ),
                            feedbacks: arrayUnion({
                                additionalFeedback,
                                messageId: targetMessage.id, // Use the id from the conversation document
                                feedbackType,
                                timestamp: new Date(),
                                messageIdRef: messageId // Use the original messageId as the reference to the separate messages collection
                            })
                        });

                        setFeedback((prev) => ({ ...prev, [messageId]: feedbackType }));
                        // console.log("Feedback updated successfully for message:", messageId);
                    } else {
                        console.error("No matching message found in conversation document.");
                        // console.log("Available message timestamps:", messages.map((msg: any) => msg.timestamp.toDate().getTime()));
                    }
                } else {
                    console.error("Conversation document or message document not found");
                }
            } else {
                console.error("No conversation ID or user ID found");
            }
        } catch (error) {
            console.error("Error updating feedback: ", error);
        }
    }, [firestore, user?.uid, conversationId, additionalFeedback]);

    const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCanContact(event.target.value === 'yes');
    };

    useEffect(() => {
        if (feedbackSubmitted && selectedSentiment) {
            handleSentiment(selectedSentiment);
            setShowThankYou(true);
            setTimeout(() => {
                setSelectedSentiment(null);
                setAdditionalFeedback("");
                setFeedbackSubmitted(false);
                setShowThankYou(false);
                setSentimentVisible(false);
            }, 500000);
        }
    }, [feedbackSubmitted, selectedSentiment, handleSentiment]);

    const handleSentimentClick = (sentimentType: string) => {
        setSelectedSentiment(sentimentType);
        setSentimentVisible(true);
    };
    const handleSubmitFeedback = async () => {
        console.log("Additional feedback submitted:", additionalFeedback);
        setFeedbackSubmitted(true);
        setSentimentVisible(false);
        setIsTyping(false);

        // Ensure selectedSentiment is a string before updating the state
        const sentiment = selectedSentiment || "";

        // Update the state with the feedback
        setFeedback((prev) => ({
            ...prev,
            sentiment: sentiment,
            additionalFeedback: additionalFeedback
        }));

        console.log("Feedback and additional feedback updated in state");

        // Call handleFeedback for the last message in the thread
        if (messageThread.length > 0) {
            const lastMessage = messageThread[messageThread.length - 1];
            await handleFeedback(lastMessage.id, sentiment);
        }

        // Update the conversation document with the overall sentiment and additional feedback
        if (conversationId) {
            const convoRef = doc(firestore, `users/${user?.uid}/convos/${conversationId}`);
            await updateDoc(convoRef, {
                sentiment: sentiment,
                additionalFeedback: additionalFeedback,
                canContact: canContact
            });
            console.log("Overall sentiment and additional feedback added to conversation document");
        } else {
            console.error("No conversation ID found");
        }

        // Reset states after submission
        setSelectedSentiment(null);
        setAdditionalFeedback("");
        setShowAllFeedback(false);
        messageThread.forEach((message) => {
            if (message.type !== "human") {
                handleFeedbackVisibility(message.id);
            }
        });
    };

    // console.log("Rendering component. sentimentVisible:", sentimentVisible, "feedbackSubmitted:", feedbackSubmitted);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            setIsTyping(false);
        }, 60000); // 60 seconds

        return () => clearTimeout(typingTimeout);
    }, [userMessage]);

    const [, setCurrentQuestionIndex] = useState(0);
    const [showAskQuestionButtons, setShowAskQuestionButtons] = useState(true);
    const [questions] = useState([
        "What is COBRA?",
        "Can you recommend any health insurance plans?",
        "Can I update my preferences?",
        "Can I update my household information?",
        "Do I have a When benefit?",
        "Do I have any Services available?",
        "What is the status of my When benefit?",
        "Can you help me with my 401k?",
        "Can you help me with the job search?",
        "How do I update my personal information?",
        "Can I change my health plan?",
        "How do I add a dependent to my plan?",
        "How do I contact customer support?",
        "What are the available payment options?",
        "Can I get a summary of my coverage?",
    ]);

    const [isAskingQuestion, setIsAskingQuestion] = useState(false);


    useEffect(() => {
        if (isAskingQuestion) {
            send();
            setIsAskingQuestion(false);
        }
    }, [userMessage, isAskingQuestion, send]);


    const toggleAskQuestionButtons = () => {
        setShowAskQuestionButtons(prevState => !prevState);
    };

    const refreshQuestions = useCallback(() => {
        setCurrentQuestionIndex((prevIndex) => (prevIndex + 3) % questions.length);
    }, [questions.length]);

    useEffect(() => {
        refreshQuestions();
    }, [refreshQuestions]);

    const [, setConversationExists] = useState(false);

    useEffect(() => {
        const checkConversationExists = async () => {
            if (user && firestore) {
                const convoRef = collection(firestore, `users/${user.uid}/convos`);
                const q = query(convoRef, orderBy("startTimestamp", "desc"), limit(1));
                const querySnapshot = await getDocs(q);
                setConversationExists(!querySnapshot.empty);
            }
        };

        checkConversationExists();
    }, [user, firestore]);

    const resumeConversation = () => {
        setConversationStarted(true);
        setShowStartButton(false);
        setShowAskQuestionButtons(true);
    };

    const handleResetChat = () => {
        // This function will be passed to ResetChatButton
        startConversation();
    };

    const filteredMessageThread = messageThread.filter(message =>
        message.type === "dynamic" || // Always include dynamic messages
        (
            message.text !== "I have completed the form, please provide the recommended plans" &&
            message.text !== "I have updated my preferences. Please provide updated health insurance plan recommendations." &&
            message.text !== "Hello Jamie, I'd like to discuss my health insurance options and learn more about your capabilities." &&
            message.text !== "the user can see their recommended plan details on the page in the above table. Below the table, there is also a button to update preferences" &&
            message.text && message.text.trim() !== "" // Check if text exists and is not empty
        )
    );

    return (
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Box sx={{ backgroundColor: "background.default", width: '100%' }} id="jamie">
                <Container maxWidth={false}>
                    <Box sx={{ backgroundColor: "white.main", borderRadius: "12px", display: "flex", flexDirection: "column", height: "90vh" }}>
                        <Box sx={{ backgroundColor: "beige.main", borderRadius: "12px 12px 0px 0px", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", alignItems: "center", padding: "19px 16px", gap: "10px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "21px", ml: { xs: "0px", sm: "54px" } }}>
                                <JamieImage width="40px" height="40px"></JamieImage>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "3px" }}>
                                    <Typography color={"primary"}>Super Jamie</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ flex: 1, overflowY: "scroll", display: "flex", flexDirection: "column-reverse", gap: "14px", px: { xs: "12px", sm: "140px" }, py: "20px" }}>
                            {loading && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", padding: "10px 15px", backgroundColor: "#F1F1F1", borderRadius: "17px 17px 17px 2px", width: "fit-content", maxWidth: { xs: "100%", sm: "50%" } }}>
                                <CircularProgress color="primary" />
                            </Box>}
                            {messageStream && messageStream.length > 0 &&
                                <Box sx={{ display: "block", justifyContent: "flex-start", alignItems: "center", gap: "10px", marginRight: "auto", backgroundColor: "#F1F1F1", color: "black.main", p: 4, maxWidth: { xs: "100%", sm: "75%" }, borderRadius: "17px 17px 17px 2px" }}>
                                    <Markdown remarkPlugins={[remarkGfm]}>
                                        {messageStream.join("")}
                                    </Markdown>
                                </Box>
                            }
                            {filteredMessageThread.map((message, index) => {
                                const isLastMessage = index === filteredMessageThread.length - 1;
                                // if (message.type === "dynamic") {
                                //     return (
                                //         <DynamicTemplate
                                //             template={message.dynamicTemplate}
                                //             data={message.data}
                                //             key={message.id}
                                //             sendPrompt={sendPrompt}
                                //             userData={userData}
                                //             setCurrentSettings={setCurrentSettings}
                                //         />
                                //     );
                                // }
                                return (
                                    <Box key={message.id}>
                                        <Box sx={{
                                            display: "block",
                                            justifyContent: message.type === "human" ? "flex-end" : "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                            marginLeft: message.type === "human" ? "auto" : "initial",
                                            marginRight: message.type === "human" ? "initial" : "auto",
                                            backgroundColor: message.type === "human" ? "primary.main" : "#F1F1F1",
                                            color: message.type === "human" ? "white.main" : "black.main",
                                            p: 4,
                                            maxWidth: { xs: "100%", sm: "75%" },
                                            borderRadius: message.type === "human" ? "17px 17px 2px 17px" : "17px 17px 17px 2px",
                                            position: "relative",
                                            padding: message.type === "human" ? "2px 17px 2px 17px" : "3px 20px 30px 20px"
                                        }}>
                                            <Markdown remarkPlugins={[remarkGfm]}>
                                                {message.text}
                                            </Markdown>
                                            {message.type !== "human" && (
                                                <Box sx={{ position: "absolute", bottom: "10px", right: "10px", display: "flex", gap: "5px", paddingTop: { xs: "30px", sm: "30px" } }}>
                                                    <IconButton color="success" onClick={() => handleFeedback(message.id, "positive")}>
                                                        <ThumbUpAlt fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "positive" ? 0.4 : 0.2 }} />
                                                    </IconButton>
                                                    <IconButton color="success" onClick={() => handleFeedback(message.id, "negative")}>
                                                        <ThumbDownAlt fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "negative" ? 0.4 : 0.2 }} />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Box>
                                        {message.type !== "human" && feedbackVisible[message.id] && !isTyping && isLastMessage && (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", marginRight: "auto", backgroundColor: "#F1F1F1", color: "black.main", p: 4, maxWidth: { xs: "100%", sm: "75%" }, borderRadius: "17px 17px 17px 2px", mt: 2 }}>
                                                {showThankYou ? (
                                                    <Typography variant="h6" sx={{ mt: 2, alignContent: "center", paddingBottom: "1em" }}>Thank you for your feedback!</Typography>
                                                ) : sentimentVisible ? (
                                                    <>
                                                        <Typography fontSize="sm">How was your experience?</Typography>
                                                        <Box sx={{ display: "flex", gap: "5px" }}>
                                                            <IconButton onClick={() => handleSentimentClick("veryDissatisfied")}>
                                                                <SentimentVeryDissatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "veryDissatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("dissatisfied")}>
                                                                <SentimentDissatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "dissatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("neutral")}>
                                                                <SentimentSatisfiedAlt fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "neutral" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("satisfied")}>
                                                                <SentimentSatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "satisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("verySatisfied")}>
                                                                <SentimentVerySatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "verySatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                        </Box>
                                                        {selectedSentiment && (
                                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", mt: 2 }}>
                                                                <TextField
                                                                    label="Additional Feedback"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    value={additionalFeedback}
                                                                    onChange={(e) => setAdditionalFeedback(e.target.value)}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleSubmitFeedback}
                                                                >
                                                                    Submit Feedback
                                                                </Button>
                                                                {(selectedSentiment === "dissatisfied" || selectedSentiment === "veryDissatisfied") && (
                                                                    <>
                                                                        <Typography sx={{ fontSize: "small", mt: 2, alignContent: "center", paddingBottom: "1em" }}>Can a When agent contact you about your experience?</Typography>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                                                                            <RadioGroup row value={canContact ? 'yes' : 'no'} onChange={handleContactChange}>
                                                                                <FormControlLabel
                                                                                    value="yes"
                                                                                    control={<Radio />}
                                                                                    label={<Typography sx={{ fontSize: '0.875rem' }}>Yes</Typography>}
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="no"
                                                                                    control={<Radio />}
                                                                                    label={<Typography sx={{ fontSize: '0.875rem' }}>No</Typography>}
                                                                                />
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Box>
                                                        )}
                                                        <Button size="small" onClick={() => resetFeedbackTimer(message.id)}>I am still here</Button>
                                                    </>
                                                ) : null}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            }).reverse()}
                            {conversationStarted && <Typography fontSize={"11px"}></Typography>}
                        </Box>
                        {showStartButton && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                {messageThread.length >= 1 ? (
                                    <>
                                        <Button
                                            onClick={resumeConversation}
                                            color="primary"
                                            variant="contained"
                                            sx={{ padding: "10px 20px", margin: "10px", bgcolor: 'primary.light' }}
                                        >
                                            Resume Conversation
                                        </Button>
                                        <ResetChatButton
                                            user={user}
                                            firestore={firestore}
                                            chatType={chatType}
                                            hasCobra={hasCobra}
                                            clear={clear}
                                            setConversationId={setConversationId}
                                            setIsNewConversation={setIsNewConversation}
                                            setMessageThread={setMessageThread}
                                            setFeedback={setFeedback}
                                            setAdditionalFeedback={setAdditionalFeedback}
                                            setSelectedSentiment={setSelectedSentiment}
                                            setFeedbackSubmitted={setFeedbackSubmitted}
                                            setSentimentVisible={setSentimentVisible}
                                            setShowAllFeedback={setShowAllFeedback}
                                            onReset={handleResetChat}
                                            buttonText="Restart Conversation"
                                            sx={{ padding: "10px 20px", margin: "10px", bgcolor: 'primary.light' }}
                                            selectedEventType=""
                                            setIsResetting={() => {}}
                                            disabled={false}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        onClick={startConversation}
                                        color="primary"
                                        variant="contained"
                                        sx={{ padding: "10px 20px", margin: "10px", bgcolor: 'primary.light' }}
                                    >
                                        Start Conversation
                                    </Button>
                                )}
                            </Box>
                        )}
                        {/* BEGIN INPUT BAR */}
                        {conversationStarted && (
                            <Box sx={{ mt: "auto", backgroundColor: "white", p: 2 }}>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", width: "100%" }}>
                                        <TextField
                                            value={userMessage}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    send();
                                                }
                                            }}
                                            onChange={(e) => {
                                                setUserMessage(e.target.value);
                                                setIsTyping(true);
                                            }}
                                            sx={{ backgroundColor: "white.main", borderRadius: "0px 0px 12px 12px", padding: "10px 10px", margin: ".2em", width: "100%", maxWidth: { xs: "100%", sm: "100%" } }}
                                            inputProps={{
                                                style: {
                                                    padding: 10
                                                }
                                            }}
                                        />
                                        <Button onClick={send} variant="contained" sx={{ padding: "5px 5px 5px 5px", margin: "10px", bgcolor: 'primary.light' }}>
                                            Send
                                        </Button>
                                        <Button onClick={toggleAskQuestionButtons} variant="contained" sx={{ padding: "5px 0px 5px 0px", margin: "20px", maxWidth: "50%", bgColor: 'primary.light' }}>
                                            {showAskQuestionButtons ? "?" : "?"}
                                        </Button>
                                        {showAskQuestionButtons ? (
                                            <Button onClick={refreshQuestions} variant="contained" sx={{ padding: "5px 0px 5px 0px", margin: "10px", width: "90px", bgColor: "primary.light" }}>
                                                <Cached />
                                            </Button>
                                        ) : (
                                            <Box sx={{ width: "90px", height: "40px", margin: "10px" }}></Box> // Placeholder for cache button
                                        )}
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                        <CancelButton cancel={cancel} />
                                        <ClearButton clear={clear} />
                                        <Button
                                            onClick={() => {
                                                setSelectedSentiment(null);
                                                setAdditionalFeedback("");
                                                setFeedbackSubmitted(false);
                                                setSentimentVisible(true);
                                                setShowAllFeedback(true);
                                                setIsTyping(false);
                                                messageThread.forEach((message) => {
                                                    handleFeedbackVisibility(message.id);
                                                });
                                            }}
                                            color="primary"
                                            variant="contained"
                                            sx={{ padding: "5px 10px 5px 10px", margin: "10px", bgcolor: 'primary.light' }}
                                        >
                                            Submit Feedback
                                        </Button>
                                        <ResetChatButton
                                            user={user}
                                            firestore={firestore}
                                            chatType={chatType}
                                            hasCobra={hasCobra}
                                            clear={clear}
                                            setConversationId={setConversationId}
                                            setIsNewConversation={setIsNewConversation}
                                            setMessageThread={setMessageThread}
                                            setFeedback={setFeedback}
                                            setAdditionalFeedback={setAdditionalFeedback}
                                            setSelectedSentiment={setSelectedSentiment}
                                            setFeedbackSubmitted={setFeedbackSubmitted}
                                            setSentimentVisible={setSentimentVisible}
                                            setShowAllFeedback={setShowAllFeedback}
                                            selectedEventType=""
                                            setIsResetting={() => {}}
                                            disabled={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {/* END INPUT BAR */}
                    </Box>
                </Container>
            </Box>
            <Snackbar open={error !== ""} autoHideDuration={6000} onClose={() => { setError("") }} />
        </Box>
    );
}


export default SuperJamie;