import { Accordion, AccordionDetails, AccordionSummary, Card, Typography } from "@mui/material";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import { QuerySnapshot } from "firebase/firestore";
import { Case } from "services/GraphQl";

function CaseApiData({ theCase, apiData }: { theCase: Case; apiData: QuerySnapshot<any> }) {


  const cleanData = (data: any) => {
    let newData = { ...data };
    delete newData.ref;
    return newData;
  };
  
  return (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h2">Case Object</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <pre>{JSON.stringify(cleanData(theCase), null, 2)}</pre>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h2">Push API Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {apiData?.docs?.map((doc) => {
            return (
              <Accordion key={doc.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h3">
                    Request: {doc.id} {moment(doc.get("timestamp")?.toDate()).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <pre>{JSON.stringify(doc.data(), null, 2)}</pre>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

export default CaseApiData;
