import {
  Typography,
  Box,
  Button,
  Dialog,
  Card,
  Radio,
  RadioGroup,
  FormControlLabel,
  Paper
} from "@mui/material";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { useState } from "react";
import { Case, CaseDocument, CaseStatus, useUpdateCaseMutation } from "services/GraphQl";
import { useNotifications } from "contexts/Notification";
import { useAuth } from "contexts/User";
import { addDoc, collection as fbCollection } from "firebase/firestore";
import { AdminCaseActivityConverter } from "services/Firestore";
import { CaseActivityTypes } from "services/Interfaces";
import { parseEmailToName } from "pages/Admin/Case/utils";
import moment from "moment";

interface Props {
  open: boolean;
  onClose: () => void;
  theCase: Case;
}

export default function AdminCaseAssigneeDialog({ open, onClose, theCase }: Props) {
  const { firestore } = useFirebase();
  const { notifications } = useNotifications();
  const { user } = useAuth();
  const [selectedAssignee, setSelectedAssignee] = useState<string>(theCase.assignee || "");
  const [updateCase] = useUpdateCaseMutation({
    refetchQueries: [CaseDocument]
  });

  // Get list of admin users
  const [adminUsers] = useCollection(
    query(collection(firestore, 'adminUsers'), where('active', '==', true)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const handleSave = async () => {
    const oldAssignee = theCase.assignee;
    
    try {
      if (!theCase.id || !theCase.companyId || !theCase.caseType || !theCase.dataVersion) {
        notifications.error("Cannot update case: Required case data is missing");
        return;
      }

      await updateCase({
        variables: {
          caseId: theCase.id,
          companyId: theCase.companyId,
          caseInput: {
              assignee: selectedAssignee || null,
              caseType: theCase.caseType,
              dataVersion: theCase.dataVersion,
              status: CaseStatus.Open
          }
        }
      });

      notifications.success("Assignee updated successfully");
    } catch (error) {
      console.error("Error updating assignee:", error);
      notifications.error("Failed to update assignee");
    } finally {
      // Add activity record regardless of success/failure
      try {
        await addDoc(
          fbCollection(
            firestore,
            "companies",
            theCase.companyId,
            "cases",
            theCase.id!,
            "activity"
          ).withConverter(AdminCaseActivityConverter),
          {
            activity: CaseActivityTypes.CASE_UPDATED,
            type: "admin_action",
            userId: user?.uid || "",
            accessedBy: user?.uid || "",
            caseId: theCase.id!,
            companyId: theCase.companyId,
            displayName: user?.displayName || "Unknown",
            message: "Case assignee update attempted",
            changes: [{
              field: "Assignee",
              oldValue: oldAssignee || "None",
              newValue: selectedAssignee || "None"
            }],
            timestamp: moment(),
            id: ""
          }
        );
        onClose();
      } catch (activityError) {
        console.error("Error recording activity:", activityError);
        notifications.error("Failed to record activity");
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
    >
      <Card sx={{ 
        maxHeight: '80vh',
        height: '80vh', 
        display: 'flex',
        flexDirection: 'column',
        p: 3,
      }}>
        <Typography variant="body1"><strong>Select Case Assignee</strong></Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>Select the assignee for this case</Typography>
        
        <Box sx={{ 
          flex: 1,
          minHeight: 0, 
          overflow: 'hidden', 
        }}>
          <RadioGroup
            value={selectedAssignee}
            onChange={(e) => setSelectedAssignee(e.target.value)}
            sx={{
              height: '100%',
              overflowY: 'auto',
              pr: 1,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <FormControlLabel
                value=""
                control={<Radio />}
                label={
                  <Paper 
                    variant="outlined" 
                    sx={{ 
                      p: 2, 
                      width: "100%",
                      bgcolor: selectedAssignee === "" ? "action.selected" : "background.paper"
                    }}
                  >
                    <Typography variant="subtitle1">Unassigned</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Remove current assignee
                    </Typography>
                  </Paper>
                }
                sx={{
                  margin: 0,
                  width: "100%",
                  "& .MuiFormControlLabel-label": {
                    width: "100%"
                  }
                }}
              />
              
              {adminUsers?.docs.map((doc) => {
                const userData = doc.data();
                const parsedName = userData.email ? parseEmailToName(userData.email) : '';
                return (
                  <FormControlLabel
                    key={doc.id}
                    value={doc.id}
                    control={<Radio />}
                    label={
                      <Paper 
                        variant="outlined" 
                        sx={{ 
                          p: 2, 
                          width: "100%",
                          bgcolor: selectedAssignee === doc.id ? "action.selected" : "background.paper"
                        }}
                      >
                        <Typography variant="subtitle1">
                          {userData.name?.first ? 
                            `${userData.name.first} ${userData.name.last}` : 
                            parsedName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userData.email}
                        </Typography>
                      </Paper>
                    }
                    sx={{
                      margin: 0,
                      width: "100%",
                      "& .MuiFormControlLabel-label": {
                        width: "100%"
                      }
                    }}
                  />
                );
              })}
            </Box>
          </RadioGroup>
        </Box>

        <Box sx={{ 
          display: "flex", 
          gap: 2, 
          justifyContent: "flex-end", 
          mt: 2,
          pt: 2,
          borderTop: 1,
          borderColor: 'divider',
        }}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Card>
    </Dialog>
  );
} 