import { Button, Box, Card, Divider } from "@mui/material";
import CaseDetailsList from "./CaseDetailsList";
import CaseUserDetails from "./CaseUserDetails";
import { useState } from "react";
import { Case } from "services/GraphQl";

interface CaseInfoDropdownProps {
  theCase: Case;
  company: any;
  toggleEditDialog: () => void;
  toggleShareDialog: () => void;
  setCreateUserDialogOpen: (open: boolean) => void;
  setReleaseOpen: (open: boolean) => void;
  setReleaseBenefit: (benefit: boolean) => void;
  hasManagedHealth: boolean;
}

function CaseInfoDropdown({
  theCase,
  company,
  toggleEditDialog,
  toggleShareDialog,
  setCreateUserDialogOpen,
  setReleaseOpen,
  hasManagedHealth,
}: CaseInfoDropdownProps) {
  const [toggleDetails, setToggleDetails] = useState(true);
  const caseData = theCase;

  const userName = `${caseData?.contact?.firstName} ${caseData?.contact?.lastName}`

  return (
    <Card
      sx={{
        width: "100%",
        p: 4,
        mb: 4,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <CaseUserDetails
        company={company}
        userName={userName}
        toggleEditDialog={toggleEditDialog}
        toggleShareDialog={toggleShareDialog}
        setCreateUserDialogOpen={setCreateUserDialogOpen}
        setReleaseOpen={setReleaseOpen}
        hasManagedHealth={hasManagedHealth}
        caseData={caseData}
      />
      {toggleDetails && (
        <>
          <Divider />
          <Box
            sx={{
              py: 2,
            }}
          >
            <CaseDetailsList caseData={caseData} />
          </Box>
        </>
      )}
      <Button
        onClick={() => setToggleDetails(!toggleDetails)}
      >
        {toggleDetails ? "Hide Case Details" : "Show Case Details"}
      </Button>
    </Card>
  );
}

export default CaseInfoDropdown;
