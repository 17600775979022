// @ts-nocheck
/** @jsxImportSource @emotion/react */

import "./App.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CssBaseline from "@mui/material/CssBaseline";

import { Suspense, useContext, useEffect, useState } from "react";
import theme from "./theme";
import employerTheme from "./employerTheme";

import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import Content from "./components/common/Content";
import { useAuth } from "./contexts/User";
import { IntlProvider } from 'react-intl';
import AdminContent from "./components/common/AdminContent";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ModalContext } from "contexts/Modal";
import { Snackbar, Button, Alert, Box, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { ScrollToTop } from "components/common";
import CompanyContent from "components/common/CompanyContent";
import { NotificationsProvider } from "contexts/Notification";
import { useSettings } from "contexts/Settings";
import * as Sentry from "@sentry/react";
import { CustomApolloClientProvider } from "contexts/CustomApolloClient";
import { mixpanelService } from 'services/tracking';



declare global {
  interface Window {
    zE: any
  }
}
function App() {
  const {
    loaded,
    settings
  } = useSettings();
  const [servicesLoaded, setServicesLoaded] = useState<boolean>(false);
  const { modalState, modalDispatch } = useContext(ModalContext);
  const [workerLoaded, setWorkerLoaded] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    if (loaded) {
      if (process.env.NODE_ENV === 'production' && !settings.REACT_APP_FIREBASE_EMULATE) {
        Sentry.init({
          dsn: settings.REACT_APP_SENTRY_DSN,
          integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.captureConsoleIntegration({ levels: ['error'] })],
          // This sets the sample rate to be 0%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          // value 0 - 1.0
          replaysSessionSampleRate: 0,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1.0,
          environment: process.env.NODE_ENV,
          // Set tracesSampleRate to 1.0 to capture 100%
          // of transactions for performance monitoring.
          // We recommend adjusting this value in production
          tracesSampleRate: 1.0,
        });
      }

      mixpanelService.init(settings.REACT_APP_MIXPANEL_TOKEN || '', { debug: true, record_sessions_percent: 100, track_pageview: true, ignore_dnt: settings.REACT_APP_MIXPANEL_IGNORE_DNT === "true" ? true : false });
      mixpanelService.start_session_recording();
      if (process.env.NODE_ENV !== 'production') {
        //mixpanel.opt_out_tracking();
      }
      // mixpanel.clear_opt_in_out_tracking();


      setServicesLoaded(true);
    }
  }, [loaded, settings])


  if (!workerLoaded) {
    if (process.env.NODE_ENV === 'production') {
      console.log("Registering Service Worker");
      // no service worker for now due to load balancer trouble
      serviceWorkerRegistration.unregister();

      // serviceWorkerRegistration.register({
      //   onUpdate: (registration) => {
      //     console.log("New Version Available");
      //     modalDispatch({ type: "newVersion" });
      //   }
      // });
    } else {
      serviceWorkerRegistration.unregister();
    }
    setWorkerLoaded(true);
  }
  // const [user, loading, error] = useIdToken(auth, options);

  const { userClaims } = useAuth();
  let currentTheme = theme;
  let content = <Content />;
  if ((userClaims.admin || userClaims.roles?.admin?.viewer) && location.pathname.startsWith('/admin')) {
    currentTheme = employerTheme;
    content = <AdminContent />;
  }
  if (location.pathname.startsWith('/company')) {
    currentTheme = employerTheme;
    content = <CompanyContent />;
  }
  return (
    <NotificationsProvider>
      <IntlProvider locale="en" defaultLocale="en">
        <CustomApolloClientProvider>
          <HelmetProvider>
            <ThemeProvider theme={currentTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box className="App">
                  <CssBaseline />
                  <ScrollToTop />
                  <Helmet>
                    <title>For When</title>
                    <meta name="viewport" content="initial-scale=1, width=device-width" />

                  </Helmet>
                  <Suspense fallback={<Typography>Loading</Typography>}>
                    {loaded && servicesLoaded && content}
                  </Suspense>

                  <Snackbar open={modalState.newVersion} >
                    <Alert onClose={() => modalDispatch({ type: "newVersion" })} severity="info" sx={{ width: '100%' }}>
                      There is a new version available! <Button variant="text" sx={{ cursor: "pointer" }} onClick={async () => {
                        console.log("refreshing via SW")
                        const registration = await navigator.serviceWorker.getRegistration();
                        registration?.waiting?.addEventListener("statechange", event => {
                          console.log("SW: state change", event);
                          if (event.target.state === "activated") {
                            console.log("SW: activated");
                            window.location.reload()
                          }
                        });
                        registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                      }}>Refresh Now.</Button>
                    </Alert>
                  </Snackbar>
                </Box>
              </LocalizationProvider>
            </ThemeProvider>
          </HelmetProvider>
        </CustomApolloClientProvider>
      </IntlProvider >
    </NotificationsProvider>
  );
}



export default App;