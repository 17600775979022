/**
 * Calculates the cutoff date for enrollment applications based on the effective date.
 *
 * Business rules:
 * - If 1st is Monday, cutoff is previous Thursday at midnight PT
 * - If 1st is Tuesday, cutoff is previous Sunday at midnight PT
 * - If 1st is Wednesday, cutoff is previous Monday at midnight PT
 * - If 1st is Thursday, cutoff is previous Tuesday at midnight PT
 * - If 1st is Friday, cutoff is previous Wednesday at midnight PT
 * - If 1st is Saturday, cutoff is previous Thursday at midnight PT
 * - If 1st is Sunday, cutoff is previous Thursday at midnight PT
 *
 * @param effectiveDate The effective date of the plan (typically the 1st of a month)
 * @param useLegacyCutoff Optional flag to use legacy 72-hour cutoff rule
 * @returns The calculated cutoff date as a Date object
 */
import moment from "moment-timezone";

export function calcEnrollmentAppCutoff(effectiveDate: Date | string, useLegacyCutoff: boolean = false): Date {
  const effectiveMoment =
    typeof effectiveDate === "string"
      ? moment(effectiveDate).tz("America/Los_Angeles")
      : moment(effectiveDate).tz("America/Los_Angeles");

  if (useLegacyCutoff) {
    return effectiveMoment.clone().subtract(72, "hours").toDate();
  }

  const dayOfWeek = effectiveMoment.day();
  const cutoffMoment = effectiveMoment.clone();

  switch (dayOfWeek) {
    case 0: // Sunday
      cutoffMoment.subtract(3, "days");
      break; // Sunday → Thursday
    case 1: // Monday
      cutoffMoment.subtract(4, "days");
      break; // Monday → Thursday
    case 2: // Tuesday
      cutoffMoment.subtract(2, "days");
      break; // Tuesday → Sunday
    case 3: // Wednesday
      cutoffMoment.subtract(2, "days");
      break; // Wednesday → Monday
    case 4: // Thursday
      cutoffMoment.subtract(2, "days");
      break; // Thursday → Tuesday
    case 5: // Friday
      cutoffMoment.subtract(2, "days");
      break; // Friday → Wednesday
    case 6: // Saturday
      cutoffMoment.subtract(2, "days");
      break; // Saturday → Thursday
  }

  // Set to end of day (midnight) in PT
  return cutoffMoment.endOf("day").toDate();
}

/**
 * Determines if the application button should be disabled based on the cutoff date
 *
 * @param effectiveDate The effective date of the plan
 * @param bypassCutoff Feature flag to bypass the cutoff check
 * @param useLegacyCutoff Optional flag to use legacy 72-hour cutoff rule
 * @returns Boolean indicating if the button should be disabled
 */
export function shouldDisableApplicationButton(
  effectiveDate: string | null | undefined,
  bypassCutoff: boolean = false,
  useLegacyCutoff: boolean = false
): boolean {
  // If bypassing cutoff, always enable the button
  if (bypassCutoff) {
    return false;
  }

  // If no effective date, don't disable the button
  if (!effectiveDate) {
    return false;
  }

  // Parse the effective date string properly
  const parsedEffectiveDate =
    typeof effectiveDate === "string"
      ? moment.tz(effectiveDate, "YYYY-MM-DD", "America/Los_Angeles").toDate()
      : effectiveDate;

  const cutoffDate = calcEnrollmentAppCutoff(parsedEffectiveDate, useLegacyCutoff);
  const currentTime = Date.now();

  return currentTime > cutoffDate.getTime();
}
