import { LoadingButton } from "@mui/lab";
import { Box, Typography, Card, CardContent, Button, Divider, Link, useTheme, useMediaQuery, Dialog } from "@mui/material";
import ScheduleWithAgentModal from "components/Modals/ScheduleWithAgentModal";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { useSettings } from "contexts/Settings";
import { useAuth } from "contexts/User";
import { mixpanelService as mixpanel } from 'services/tracking';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Interfaces, Mutations, Queries, useMutation, useQuery } from 'services/apollo';
import { shouldDisableApplicationButton } from "utils/calcEnrollmentAppCutoff";

function SelectPlanModal(props: { plan: Interfaces.QuotitHealthPlanDetails, close: Function, userId?: string }) {
  const { settings } = useSettings();
  const navigate = useNavigate()
  const [scheduleWithAgent, setScheduleWithAgent] = useState<boolean>(false);
  const plan = props.plan;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { answers, user, userClaims } = useAuth();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const whenEnrollment = useRemoteConfig('whenEnrollment').asBoolean() || false;
  const FF_disableNewApplicationCutoffDate = useRemoteConfig("disableNewApplicationCutoffDate").asBoolean() || false;
  const [createApplication, { loading: loadingApplication }] = useMutation(Mutations.createApplication);
  const enrollmentUrl = useQuery<{ generateEnrollmentUrl: { redirectUrl: string } }, { effectiveDate: string, planId: string, carrierId: string }>(Queries.generateEnrollmentUrl, {
    variables: {
      effectiveDate: answers?.get("insuranceDetails.effectiveDate"),
      planId: plan.id.toString(),
      carrierId: plan.carrierId.toString()
    },
    notifyOnNetworkStatusChange: true,
  });
  console.log(isButtonDisabled)
  function redirect(url: string) {
    window.location.href = url;
  }

  const handleStartApplication = async () => {
    if (!user) {
      throw new Error('User is not logged in.');
    }
    try {
      const { data } = await createApplication({
        variables: {
          userId: props?.userId || user?.uid,
          planId: plan.id,
        },
      });

      // Check if application creation was successful
      if (data?.createApplication?.success) {
        if (userClaims?.admin) {
          navigate(`/admin/applications/${data?.createApplication?.applicationId}`);
        } else {
          navigate(`/account/applications/${data?.createApplication?.applicationId}`);
        }
      }
      else {
        console.error('Application creation failed or success status not received.');
      }

    } catch (error) {
      console.error('Error creating application', error);
    }
  };

  useEffect(() => {
    const applicationEffectiveDate = answers?.get("insuranceDetails.effectiveDate");

    const shouldDisableNewApplicationButton = shouldDisableApplicationButton(
      applicationEffectiveDate,
      FF_disableNewApplicationCutoffDate,
      false // Use new business day rules instead of legacy 72-hour rule
    );

    setIsButtonDisabled(shouldDisableNewApplicationButton);
  }, [answers, FF_disableNewApplicationCutoffDate]);

  useEffect(() => {
    console.log(JSON.stringify(enrollmentUrl.data))
  }, [enrollmentUrl])

  useEffect(() => {
    mixpanel.track("SelectHealthPlan", {
      plan: {
        name: props.plan.name,
        carrier: props.plan.carrier.name,
        id: props.plan.id,
        type: props.plan.planType
      }
    });
  });

  return (
    <Card
      sx={{
        backgroundColor: "white.main",
        height: { xs: "100vh", sm: "auto" },
        minWidth: "50vw",
        overflowY: "scroll",
        p: 4,
      }}
    >
      <CardContent
        sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0,
        }}
      >
        <Box>
          <Typography id="select-plan-modal-title" variant="h2" fontWeight={600} align="center" color={"primary.main"}>
            How to Enroll
          </Typography>
          <Typography align="center">
            {plan.carrier.name} - {plan.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4,
            justifyContent: "center",
          }}
        >
          {(plan.contractStatus === "Contracted" ||
            plan.contractStatus === "Unknown" ||
            plan.insuranceType === "HealthOnExchange") && (
            <Box
              width={"100%"}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {!whenEnrollment ? (
                <>
                  <Typography variant="h2" align="center" data-testid="how-to-enroll">
                    Enroll Online
                  </Typography>
                  <Typography>
                    You are able to enroll with {plan.carrier.name} online by clicking the button below. You will be
                    required to create a separate enrollment account, and input additional information during the
                    enrollment process. This helps us keep your sensitive health data secure!
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      !enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl ||
                      !enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl.includes("http")
                    }
                    onClick={() => {
                      redirect(enrollmentUrl?.data?.generateEnrollmentUrl?.redirectUrl || "");
                    }}
                  >
                    Enroll Now
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h2" align="center" data-testid="submit-online-application">
                    Submit an online application
                  </Typography>
                  <Typography>
                    When can submit your application online on your behalf. Complete our enrollment form and our team
                    will process your enrollment. Click “Start application” to get started!
                  </Typography>
                  <Typography variant="caption">
                    Note: You may submit an online application up to 1 business day before the start date of the
                    application. If you would like to get enrolled in a plan after the online cutoff, please call our
                    Member Services team at +1 831-288-6412. Our team is available Monday through Friday, 9am to 7pm
                    Central Time
                  </Typography>
                  <LoadingButton
                    loading={loadingApplication}
                    data-test-id="start-application-button"
                    disabled={isButtonDisabled}
                    onClick={handleStartApplication}
                    variant="contained"
                    color="primary"
                  >
                    Start application
                  </LoadingButton>
                </>
              )}
            </Box>
          )}
          {(plan.contractStatus === "Contracted" ||
            plan.contractStatus === "Unknown" ||
            plan.insuranceType === "HealthOnExchange") && (
            <Divider
              orientation={isSm ? "vertical" : "horizontal"}
              flexItem
              variant="middle"
              sx={{ backgroundColor: "brightOrange.main" }}
            />
          )}
          <Box
            width={"100%"}
            sx={{
              maxWidth: "600px",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {whenEnrollment ? (
              <>
                <Typography variant="h2" align="center">
                  Get Help or Enroll live with an agent
                </Typography>
                <Typography>
                  When&apos;s team of licensed insurance agents are here to help you select a plan and get you enrolled.
                  To speak with one of our agents, call us at &nbsp;
                  <Link
                    href={"tel:" + settings.REACT_APP_CONCIERGE_PHONE}
                    color="primary"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {settings.REACT_APP_CONCIERGE_PHONE}
                  </Link>
                  &nbsp;Our team is available Monday through Friday, 9am to 7pm Central Time.
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h2" align="center">
                  Get Expert Help
                </Typography>
                <Typography>
                  Our team of When Licensed Insurance Agents are here to help you find a plan, enroll, and assist with
                  other health insurance-related questions.
                </Typography>
                <Link href={"tel:" + settings.REACT_APP_CONCIERGE_PHONE} color="primary">
                  Call: {settings.REACT_APP_CONCIERGE_PHONE}
                </Link>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ mx: "auto" }}>
          <Button
            variant="outlined"
            onClick={() => {
              props.close();
            }}
          >
            Close
          </Button>
        </Box>

        <Dialog
          open={scheduleWithAgent}
          onClose={() => {
            setScheduleWithAgent(false);
          }}
          fullScreen={isSm ? false : true}
          maxWidth="lg"
        >
          <ScheduleWithAgentModal
            title="Schedule a Call with an Agent About This Policy"
            close={() => {
              setScheduleWithAgent(false);
            }}
          />
        </Dialog>
      </CardContent>
    </Card>
  );
}

export default SelectPlanModal;
