import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Container, 
  Card, 
  Button, 
  Box, 
  ToggleButton, 
  ToggleButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack
} from "@mui/material";
import {
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentSatisfiedAlt,
  SentimentSatisfied,
  SentimentVerySatisfied
} from '@mui/icons-material';
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, Route, Routes } from "react-router-dom";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { useFirebase } from '../../contexts/Firebase';

interface Message {
  data: {
    dynamicTemplate: string;
    text: string;
  };
  timestamp: string;
  type: string;
  user: boolean;
}

interface Feedback {
  additionalFeedback?: string;
  feedbackType: 'positive' | 'negative' | 'veryDissatisfied' | 'dissatisfied' | 'neutral' | 'satisfied' | 'verySatisfied';
  messageId: string;
  messageIdRef: string;
  timestamp: any;
}

interface Conversation {
  id: string;
  chatType: string;
  feedbacks: Feedback[];
  hasCobra: boolean;
  messages: Message[];
  startTimestamp: any;
  sentiment?: string;
  additionalFeedback?: string;
}

function JamieAdmin() {
  return (
    <Routes>
      <Route path="/" element={<ConversationList />} />
    </Routes>
  );
}

function ConversationList() {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [filteredConversations, setFilteredConversations] = useState<Conversation[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [generalFilter, setGeneralFilter] = useState<string | null>(null);
  const [satisfactionFilter, setSatisfactionFilter] = useState<string>('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const navigate = useNavigate();
  const { firestore, auth } = useFirebase();

  const isPositiveFeedback = (feedbackType: string): boolean => {
    return ['positive', 'satisfied', 'verySatisfied'].includes(feedbackType);
  };

  const isNegativeFeedback = (feedbackType: string): boolean => {
    return ['negative', 'dissatisfied', 'veryDissatisfied'].includes(feedbackType);
  };

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("User is not authenticated");
        }

        const db = getFirestore();
        const convosRef = collection(db, `users/${currentUser.uid}/convos`);
        const querySnapshot = await getDocs(convosRef);
        
        const fetchedConversations: Conversation[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Conversation;
          fetchedConversations.push({
            ...data,
            id: doc.id,
            startTimestamp: data.startTimestamp
          });
        });
        
        setConversations(fetchedConversations);
        setFilteredConversations(fetchedConversations);
      } catch (err) {
        console.error("Error fetching conversations:", err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversations();
  }, [firestore, auth]);

  useEffect(() => {
    let filtered = [...conversations];

    if (generalFilter) {
      filtered = filtered.filter(conversation => {
        const hasFeedback = conversation.feedbacks && conversation.feedbacks.length > 0;
        if (!hasFeedback) return false;
        
        if (generalFilter === 'positive') {
          return conversation.feedbacks.some(f => isPositiveFeedback(f.feedbackType));
        } else {
          return conversation.feedbacks.some(f => isNegativeFeedback(f.feedbackType));
        }
      });
    }

    if (satisfactionFilter) {
      filtered = filtered.filter(conversation => {
        return conversation.feedbacks?.some(
          feedback => feedback.feedbackType === satisfactionFilter
        );
      });
    }

    setFilteredConversations(filtered);
  }, [generalFilter, satisfactionFilter, conversations]);

  const handleGeneralFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: string | null,
  ) => {
    setGeneralFilter(newFilter);
    setSatisfactionFilter('');
  };

  const handleSatisfactionFilterChange = (event: any) => {
    setSatisfactionFilter(event.target.value);
    setGeneralFilter(null);
  };

  const parseDate = (dateValue: any): Date => {
    if (dateValue instanceof Date) {
      return dateValue;
    }
    
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
      
      const parts = dateValue.split(' ');
      if (parts.length >= 6) {
        const [month, day, year, time, timezone] = parts;
        const [hours, minutes, seconds] = time.split(':');
        const newDateString = `${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezone.slice(3, -1)}`;
        return new Date(newDateString);
      }
    }
    
    if (typeof dateValue === 'object' && dateValue.seconds) {
      return new Date(dateValue.seconds * 1000);
    }
    
    return new Date(NaN);
  };

  const getFeedbackSummary = (feedbacks: Feedback[]) => {
    if (!feedbacks || feedbacks.length === 0) return '—';
    
    const feedbackCounts = feedbacks.reduce((acc, f) => {
      acc[f.feedbackType] = (acc[f.feedbackType] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const positive = Object.entries(feedbackCounts)
      .filter(([type]) => isPositiveFeedback(type))
      .reduce((sum, [_, count]) => sum + count, 0);
      
    const negative = Object.entries(feedbackCounts)
      .filter(([type]) => isNegativeFeedback(type))
      .reduce((sum, [_, count]) => sum + count, 0);
      
    const neutral = feedbackCounts['neutral'] || 0;

    return `👍 ${positive} | 👎 ${negative}${neutral > 0 ? ` | 😐 ${neutral}` : ''}`;
  };

  const getFeedbackDetails = (feedbacks: Feedback[]) => {
    if (!feedbacks || feedbacks.length === 0) return null;
    
    const sentimentCounts = feedbacks.reduce((acc, feedback) => {
      if (['positive', 'negative'].includes(feedback.feedbackType)) return acc;
      acc[feedback.feedbackType] = (acc[feedback.feedbackType] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);
    
    const sentimentIcons = {
      veryDissatisfied: <SentimentVeryDissatisfied fontSize="small" />,
      dissatisfied: <SentimentDissatisfied fontSize="small" />,
      neutral: <SentimentSatisfiedAlt fontSize="small" />,
      satisfied: <SentimentSatisfied fontSize="small" />,
      verySatisfied: <SentimentVerySatisfied fontSize="small" />
    };

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {Object.entries(sentimentCounts).map(([sentiment, count]) => (
          <Stack key={sentiment} direction="row" alignItems="center" spacing={0.5}>
            {sentimentIcons[sentiment as keyof typeof sentimentIcons]}
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {count}
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  const conversationColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 220 },
    { field: 'chatType', headerName: 'Chat Type', width: 130 },
    { field: 'hasCobra', headerName: 'Has Cobra', width: 130, valueGetter: (params) => params.row.hasCobra ? 'Yes' : 'No' },
    { 
      field: 'startTimestamp', 
      headerName: 'Start Time', 
      width: 200, 
      valueGetter: (params) => {
        const date = parseDate(params.row.startTimestamp);
        return date;
      },
      valueFormatter: (params) => {
        const date = params.value as Date;
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString();
      },
      sortComparator: (v1, v2) => v1.getTime() - v2.getTime(),
    },
    { 
      field: 'messages', 
      headerName: 'Messages', 
      width: 130, 
      valueGetter: (params) => (params.row.messages || []).length
    },
    { 
      field: 'feedbacks', 
      headerName: 'Feedback Summary', 
      width: 180,
      valueGetter: (params) => params.row.feedbacks,
      valueFormatter: (params) => getFeedbackSummary(params.value),
    },
    {
      field: 'feedbackDetails',
      headerName: 'Sentiment Details',
      width: 150,
      valueGetter: (params) => params.row.feedbacks,
      renderCell: (params) => getFeedbackDetails(params.value),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate(`/admin/jadmin/${params.row.id}`)}
        >
          View Details
        </Button>
      )
    }
  ];

  return (
    <Container 
      maxWidth={false} 
      sx={{
        px: 4,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        pt: "20px",
      }}
    >
      <Typography variant="h4" component="h1">Jamie Admin Dashboard</Typography>
      {error && <Typography color="error">Error: {error.message}</Typography>}
      {loading && <Typography>Loading...</Typography>}
      {!loading && (
        <>
          <Typography variant="h5" component="h2">Conversations</Typography>
          <Box sx={{ 
            mb: 2,
            display: 'flex',
            gap: 2,
            alignItems: 'flex-end'
          }}>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Feedback Type:</Typography>
              <ToggleButtonGroup
                value={generalFilter}
                exclusive
                onChange={handleGeneralFilterChange}
                aria-label="feedback filter"
              >
                <ToggleButton value="positive" aria-label="positive feedback">
                  Positive 👍
                </ToggleButton>
                <ToggleButton value="negative" aria-label="negative feedback">
                  Negative 👎
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Satisfaction Level</InputLabel>
              <Select
                value={satisfactionFilter}
                label="Satisfaction Level"
                onChange={handleSatisfactionFilterChange}
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="verySatisfied">Very Satisfied</MenuItem>
                <MenuItem value="satisfied">Satisfied</MenuItem>
                <MenuItem value="neutral">Neutral</MenuItem>
                <MenuItem value="dissatisfied">Dissatisfied</MenuItem>
                <MenuItem value="veryDissatisfied">Very Dissatisfied</MenuItem>
                <MenuItem value="positive">Positive</MenuItem>
                <MenuItem value="negative">Negative</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Card>
          <DataGrid
              getRowId={(row) => row.id}
              autoHeight
              columns={conversationColumns}
              rows={filteredConversations}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'startTimestamp', sort: 'desc' }],
                },
              }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowClassName={(params) => `sentiment-row-${params.row.id}`}
              sx={{
                '& .MuiDataGrid-row': {
                  transition: 'background-color 0.2s ease',
                },
              }}
              getRowSpacing={params => ({
                top: 4,
                bottom: 4,
              })}
            />
          </Card>
        </>
      )}
    </Container>
  );
}

export default JamieAdmin;