import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Firestore, doc, updateDoc } from "firebase/firestore";
import { User } from "firebase/auth";
import { useState } from "react";

interface ResetSelectedPlanButtonProps {
    user: User | null;
    firestore: Firestore;
    onReset: () => void;
}

const ResetSelectedPlanButton = ({ user, firestore, onReset }: ResetSelectedPlanButtonProps) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleReset = async () => {
        if (!user) {
            console.error("No user found");
            return;
        }

        try {
            const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
            
            // Update the document to remove selectedPlan and selectedPlanDetails
            await updateDoc(settingsRef, {
                selectedPlan: null,
                selectedPlanDetails: null,
                selectedBenefitPlan: null
            });
            
            // Call the callback function to update the parent state
            onReset();
            
            console.log("Selected plan data deleted successfully");
        } catch (error) {
            console.error("Error deleting selected plan data:", error);
        } finally {
            handleClose();
        }
    };

    return (
        <>
            <Button 
                variant="contained" 
                color="error"
                onClick={handleClickOpen}
            >
                Reset Selected Plan
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Reset Selected Plan?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to reset the selected plan? This will clear your current plan selection.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleReset} color="error">
                        Reset
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ResetSelectedPlanButton; 