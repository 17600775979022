/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, IconButton, Select, MenuItem, TextField, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CircularProgress, InputLabel } from "@mui/material";
import { Edit, Check, Cancel, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { doc, updateDoc, getDoc, getDocs, collection } from "firebase/firestore";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import Household from "components/HealthPlan/Household";
import { useRemoteConfig } from "contexts/RemoteConfig";
// import { SelectChangeEvent } from '@mui/material/Select';
import PlanSelectionDialog from './PlanSelectionDialog';
import EnrollmentPreferences from "./EnrollmentPreferences";

interface PremiumTier {
    employeeContribution: number;
    employerContribution: number;
    totalPremium: number;
}

interface FourTierPremium {
    employeeOnly: PremiumTier;
    employeePlusChildren: PremiumTier;
    employeePlusSpouse: PremiumTier;
    family: PremiumTier;
}

interface BenefitPlan {
    id: string;
    name: string;
    carrier: string;
    planNetworkType: string;
    individualDeductible: number;
    familyDeductible: number;
    individualOutOfPocketMaximum: number;
    familyOutOfPocketMaximum: number;
    fourTierPremium: FourTierPremium;
}

interface EnrollmentInformationProps {
    sendPrompt: (message: string) => void;
    setCurrentSettings: React.Dispatch<React.SetStateAction<{
        insuranceStatus: boolean | null;
        effectiveDate: string | null;
        selectedPlan: any;
        whenBenefits: any[];
        benefitPlan: string | null;
    }>>;
}

const EnrollmentInformation: React.FC<EnrollmentInformationProps> = React.memo(({ 
    sendPrompt,
    setCurrentSettings 
}) => {
    const { firestore } = useFirebase();
    const { user, answers, userData } = useAuth();
    const [conversationId] = useState<string | null>(userData?.get("conversationId"));
    const ideon = useRemoteConfig("ideon").asBoolean();
    const [members, setMembers] = useState<{ [key: string]: any }>({});
    const [editHouseholdOpen, setEditHouseholdOpen] = useState(false);
    const [editZipcode, setEditZipcode] = useState(false);
    const [editCounty, setEditCounty] = useState(false);
    const [zipcode, setZipcode] = useState("");
    const [county, setCounty] = useState("");
    const [editEffectiveDate, setEditEffectiveDate] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState("");
    const [GET_GEOGRAPHY] = useLazyQuery(Queries.GET_GEOGRAPHY);
    const [counties, setCounties] = useState<{ text: string, value: { fips: string, name: string, state: string, zipcode: string } }[]>([]);
    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string, source?: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });
    const [promptSent, setPromptSent] = useState(true);
    const [native, setNative] = useState(false);
    const [checkPremiumTaxCredits, setCheckPremiumTaxCredits] = useState(true);
    const [errors, setErrors] = useState<{ native?: string, premiumTaxCredits?: string }>({});
    const [oldInsuranceData, setOldInsuranceData] = useState<any>(null);

    const [householdIncome, setHouseholdIncome] = useState<number>(0);
    const [taxFilingStatus, setTaxFilingStatus] = useState<string>("");
    const [benefitPlans, setBenefitPlans] = useState<BenefitPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<string>("");
    const [showPlanSelection, setShowPlanSelection] = useState(false);
    const [planConfirmed, setPlanConfirmed] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [hasUnsavedPreferences, setHasUnsavedPreferences] = useState(false);

    // Memoized functions
        const saveInsuranceData = useCallback(async (oldData: any, newData: any) => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }

        try {
            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoSnapshot = await getDoc(convoRef);

            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];

                if (messages.length > 0) {
                    const recentMessage = messages[messages.length - 1];
                    recentMessage.data = {
                        ...recentMessage.data,
                        oldInsuranceDetails: {
                            ...oldData,
                            householdIncome: oldData.householdIncome || householdIncome,
                            taxFilingStatus: oldData.taxFilingStatus || taxFilingStatus
                        },
                        newInsuranceDetails: {
                            ...newData,
                            householdIncome: newData.householdIncome || householdIncome,
                            taxFilingStatus: newData.taxFilingStatus || taxFilingStatus
                        }
                    };
                    await updateDoc(convoRef, { messages: messages });
                }
            }
        } catch (error) {
            console.error("Error saving insurance data:", error);
        }
    }, [user?.uid, conversationId, firestore, householdIncome, taxFilingStatus]);


    const updateAnswers = useCallback(async (key: string, value: any) => {
        if (answers && user?.uid) {
            const insuranceDetailsRef = doc(firestore, `users/${user.uid}/answers/${user.uid}`);
            
            const oldDataSnap = await getDoc(insuranceDetailsRef);
            const oldData = oldDataSnap.data()?.insuranceDetails || {};
    
            if (key === "insuranceDetails.householdIncome") {
                await updateDoc(insuranceDetailsRef, {
                    [key]: value,
                    "insuranceDetails.income": value
                });
            } else {
                await updateDoc(insuranceDetailsRef, { [key]: value });
            }
    
            const newDataSnap = await getDoc(insuranceDetailsRef);
            const newData = newDataSnap.data()?.insuranceDetails || {};
    
            await saveInsuranceData(oldData, newData);
        }
    }, [answers, firestore, user, saveInsuranceData]);

    const getCounties = useCallback(async (zipcode: string) => {
        const counties = await GET_GEOGRAPHY({
            query: Queries.GET_GEOGRAPHY,
            variables: { zipcode: zipcode }
        });
        setCounties(counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => ({
            text: county.name,
            value: county
        })));
    }, [GET_GEOGRAPHY]);

    const saveBenefitPlanToConversation = useCallback(async (planId: string) => {
        if (!user?.uid || !conversationId) {
            console.error("User is not authenticated or conversation ID is missing");
            return;
        }
    
        try {
            const selectedPlan = benefitPlans.find(plan => plan.id === planId);
            if (!selectedPlan) {
                console.error("Selected plan not found");
                return;
            }
    
            // Create plan details object to reuse
            const planDetails = {
                id: selectedPlan.id,
                name: selectedPlan.name,
                carrier: selectedPlan.carrier,
                planNetworkType: selectedPlan.planNetworkType,
                individualDeductible: selectedPlan.individualDeductible,
                familyDeductible: selectedPlan.familyDeductible,
                individualOutOfPocketMaximum: selectedPlan.individualOutOfPocketMaximum,
                familyOutOfPocketMaximum: selectedPlan.familyOutOfPocketMaximum,
                fourTierPremium: selectedPlan.fourTierPremium
            };
    
            // Save to conversation
            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoSnapshot = await getDoc(convoRef);
    
            if (convoSnapshot.exists()) {
                const convoData = convoSnapshot.data();
                const messages = convoData.messages || [];
    
                if (messages.length > 0) {
                    const recentMessage = messages[messages.length - 1];
                    recentMessage.data = {
                        ...recentMessage.data,
                        selectedBenefitPlan: planDetails
                    };
                    await updateDoc(convoRef, { messages });
                }
            }
    
            // Save to chat settings
            const chatSettingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
            await updateDoc(chatSettingsRef, {
                selectedBenefitPlan: planDetails
            });
    
            console.log("Benefit plan details saved to conversation and chat settings");
        } catch (error) {
            console.error("Error saving benefit plan details:", error);
        }
    }, [user?.uid, conversationId, firestore, benefitPlans]);    

    const handlePlanSelect = useCallback((planId: string) => {
        setSelectedPlan(planId);
    }, []);

    const handlePlanConfirmation = useCallback(async () => {
        if (selectedPlan) {
            await updateAnswers("insuranceDetails.selectedPlan", selectedPlan);
            await saveBenefitPlanToConversation(selectedPlan);
            setPlanConfirmed(true);
            setShowPlanSelection(false);
        }
    }, [selectedPlan, updateAnswers, saveBenefitPlanToConversation]);

    const handleContinueClick = useCallback(async () => {
        if (!planConfirmed) {
            setShowPlanSelection(true);
            return;
        }

        setPromptSent(true);
        
        const insuranceDetailsRef = doc(firestore, `users/${user?.uid}/answers/${user?.uid}`);
        const newInsuranceDataSnap = await getDoc(insuranceDetailsRef);
        const newInsuranceData = newInsuranceDataSnap.data()?.insuranceDetails || {};

        await saveInsuranceData(oldInsuranceData, newInsuranceData);
        setOldInsuranceData(newInsuranceData);

        await loadRates({
            variables: {
                effectiveDate: effectiveDate,
                source: ideon ? "ideon" : "quotit"
            },
            fetchPolicy: "network-only"
        });
        sendPrompt("I have completed the form, please provide the recommended plans");
    }, [planConfirmed, firestore, user?.uid, oldInsuranceData, saveInsuranceData, loadRates, effectiveDate, ideon, sendPrompt]);

    const handleHouseholdSave = useCallback(async () => {
        const insuranceDetailsRef = doc(firestore, `users/${user?.uid}/answers/${user?.uid}`);
        const oldInsuranceDataSnap = await getDoc(insuranceDetailsRef);
        const oldData = oldInsuranceDataSnap.data()?.insuranceDetails || {};
        
        const newInsuranceDataSnap = await getDoc(insuranceDetailsRef);
        const newData = newInsuranceDataSnap.data()?.insuranceDetails || {};
        
        await saveInsuranceData(oldData, newData);
        setPromptSent(false);
        setEditHouseholdOpen(false);
    }, [user?.uid, firestore, saveInsuranceData]);

    const togglePreferences = () => {
        setShowPreferences(!showPreferences);
    };

    const handlePreferencesSaved = useCallback(() => {
        setPromptSent(false);
    }, []);

    const handlePreferencesChanged = useCallback((hasUnsavedChanges: boolean) => {
        setPromptSent(true);
        setHasUnsavedPreferences(hasUnsavedChanges);
    }, []);

    // Effects
    useEffect(() => {
        const fetchInitialInsuranceData = async () => {
            if (user?.uid) {
                const insuranceDetailsRef = doc(firestore, `users/${user.uid}/answers/${user.uid}`);
                const insuranceDataSnap = await getDoc(insuranceDetailsRef);
                const initialInsuranceData = insuranceDataSnap.data()?.insuranceDetails || {};
                setOldInsuranceData(initialInsuranceData);
                if (initialInsuranceData.income) {
                    setHouseholdIncome(initialInsuranceData.income);
                }
                if (initialInsuranceData.taxFilingStatus) {
                    setTaxFilingStatus(initialInsuranceData.taxFilingStatus);
                }
            }
        };
        fetchInitialInsuranceData();
    }, [user, firestore]);

    useEffect(() => {
        const fetchBenefitPlans = async () => {
            try {
                const benefitPlansRef = collection(firestore, 'companies/when/benefitPlans');
                const benefitPlansSnapshot = await getDocs(benefitPlansRef);
                const plans = benefitPlansSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                } as BenefitPlan));
                setBenefitPlans(plans);
            } catch (error) {
                console.error("Error fetching benefit plans:", error);
            }
        };

        fetchBenefitPlans();
    }, [firestore]);

    useEffect(() => {
        const mems = answers?.get('insuranceDetails.household.members');
        if (mems === undefined) {
            setMembers({});
        } else {
            setMembers(mems);
        }
        if (answers?.get("insuranceDetails.zipcode")) {
            setZipcode(answers?.get("insuranceDetails.zipcode"));
        }
        
        const defaultEffectiveDate = moment().add(1, 'months').startOf('month').format("YYYY-MM-DD");
        if (!answers?.get("insuranceDetails.effectiveDate")) {
            setEffectiveDate(defaultEffectiveDate);
            updateAnswers("insuranceDetails.effectiveDate", defaultEffectiveDate);
        } else {
            setEffectiveDate(answers.get("insuranceDetails.effectiveDate"));
        }

        if (answers?.get("insuranceDetails.native")) {
            setNative(answers?.get("insuranceDetails.native"));
        }
        if (answers?.get("insuranceDetails.checkPremiumTaxCredits") !== undefined) {
            setCheckPremiumTaxCredits(answers.get("insuranceDetails.checkPremiumTaxCredits"));
        } else {
            setCheckPremiumTaxCredits(true);
            updateAnswers("insuranceDetails.checkPremiumTaxCredits", true);
        }
    }, [answers, updateAnswers]);

    // Memoized values
    const currentHousehold = React.useMemo(() => 
        answers?.get('insuranceDetails.household.members'),
        [answers]
    );

    const householdTableRows = React.useMemo(() => 
        Object.entries(members).map(([key, member]) => (
            <TableRow
                key={key}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {member.name}
                </TableCell>
                <TableCell align="center">{member.dob}</TableCell>
                <TableCell align="center">{member.gender}</TableCell>
            </TableRow>
        )),
        [members]
    );

    return (
        <Box sx={{
            display: "block",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginLeft: "auto",
            backgroundColor: "beige.main",
            p: 4,
            maxWidth: { xs: "100%", sm: "75%" },
            borderRadius: "17px 17px 2px 17px",
        }}>
            <Typography 
                sx={{ 
                    borderBottom: '1px solid',
                    pb: 1,
                    mb: 2
                }}
            >
                Your Enrollment Details
            </Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    mb: 1
                }}>
                    {!editEffectiveDate && (
                        <>
                            <Typography>Effective Date: {moment(effectiveDate).format("MMMM D, YYYY")}</Typography>
                            <IconButton size="small" onClick={() => { setEditEffectiveDate(true) }}><Edit fontSize="small" /></IconButton>
                        </>
                    )}
                    {editEffectiveDate && (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                        }}>
                            <Typography>Effective Date: </Typography>
                            <Select 
                                size="small"
                                value={effectiveDate} 
                                onChange={(e) => { setEffectiveDate(e.target.value) }}
                            >
                                {Array.from(Array(4).keys()).map((i) => {
                                    const date = moment().add(i, "months").startOf("month");
                                    return (
                                        <MenuItem key={date.format("YYYY-MM-DD")}
                                            value={date.format("YYYY-MM-DD")}>
                                            {date.format("MMMM D, YYYY")}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <IconButton size="small" onClick={() => { setPromptSent(false); updateAnswers("insuranceDetails.effectiveDate", effectiveDate); setEditEffectiveDate(false) }}><Check fontSize="small" /></IconButton>
                            <IconButton size="small" onClick={() => { setEditEffectiveDate(false) }}><Cancel fontSize="small" /></IconButton>
                        </Box>
                    )}
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    mb: 1
                }}>
                    {!editZipcode && (
                        <>
                            <Typography>Zipcode: {answers?.get("insuranceDetails.zipcode")}</Typography>
                            <IconButton size="small" onClick={() => { setEditZipcode(true) }}><Edit fontSize="small" /></IconButton>
                        </>
                    )}
                    {editZipcode && (
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                        }}>
                            {!editCounty && (
                                <>
                                    <Typography>Zipcode: </Typography>
                                    <TextField 
                                        size="small"
                                        value={zipcode} 
                                        onChange={(e) => { setZipcode(e.target.value) }}
                                    />
                                    <IconButton size="small" onClick={() => { getCounties(zipcode); setEditCounty(true) }}><Check fontSize="small" /></IconButton>
                                </>
                            )}
                            {editCounty && (
                                <>
                                    <Typography>County: </Typography>
                                    <Select 
                                        size="small"
                                        value={county} 
                                        onChange={(e) => { setCounty(e.target.value) }}
                                    >
                                        {counties.map((county) => (
                                            <MenuItem key={county.text}
                                                value={county.text}>
                                                {county.text}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <IconButton size="small" onClick={() => {
                                        updateAnswers("insuranceDetails.zipcode", zipcode);
                                        updateAnswers("insuranceDetails.county", counties.find(p => p.text === county)?.value);
                                        setPromptSent(false);
                                        setEditZipcode(false);
                                        setEditCounty(false);
                                    }}><Check fontSize="small" /></IconButton>
                                </>
                            )}
                            <IconButton size="small" onClick={() => { setEditZipcode(false); setEditCounty(false); }}><Cancel fontSize="small" /></IconButton>
                        </Box>
                    )}
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Date of Birth</TableCell>
                                <TableCell align="center">Gender</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {householdTableRows}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button variant="outlined" onClick={() => setEditHouseholdOpen(true)}>
                    Update Household
                </Button>
                <Dialog
                    open={editHouseholdOpen}
                    onAbort={() => setEditHouseholdOpen(false)}
                    fullWidth
                    maxWidth="lg"
                >
                    <Box sx={{
                        p: 4,
                        width: "100%"
                    }}>
                        <Household 
                            saved={handleHouseholdSave} 
                            cancel={() => setEditHouseholdOpen(false)} 
                        />                        
                    </Box>
                </Dialog>
            </Box>

            {/* Premium Tax Credits Check */}
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mt: 2 }}>
                <FormLabel sx={{ fontWeight: 'bold', top: '10px' }}>Do you want to check for premium tax credits? &nbsp;</FormLabel>
                <RadioGroup
                    sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginLeft: { sm: 'auto' } }}
                    row
                    name="premiumTaxCredits"
                    value={checkPremiumTaxCredits ? "true" : "false"}
                    onChange={(e) => {
                        const newValue = e.target.value === 'true';
                        setCheckPremiumTaxCredits(newValue);
                        setPromptSent(false);
                        updateAnswers("insuranceDetails.checkPremiumTaxCredits", newValue);
                        if (newValue) {
                            setErrors(prevErrors => ({ ...prevErrors, premiumTaxCredits: '' }));
                        }
                    }}
                >
                    <FormControlLabel value="true" control={<Radio />} label="Yes" />
                    <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
            </FormControl>

            {checkPremiumTaxCredits && (
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: 1,
                        mt: 1
                    }}>
                        <TextField
                            size="small"
                            label="Household Income"
                            value={householdIncome}
                            onChange={(e) => {
                                const newIncome = parseInt(e.target.value, 10) || 0;
                                setHouseholdIncome(newIncome);
                                updateAnswers("insuranceDetails.householdIncome", newIncome);
                                setPromptSent(false);
                            }}
                        />
                        <FormControl size="small">
                            <InputLabel id="tax-filing-status-label">Tax Filing Status</InputLabel>
                            <Select
                                labelId="tax-filing-status-label"
                                label="Tax Filing Status"
                                value={taxFilingStatus}
                                onChange={(e) => {
                                    const newStatus = e.target.value as string;
                                    setTaxFilingStatus(newStatus);
                                    updateAnswers("insuranceDetails.taxFilingStatus", newStatus);
                                    setPromptSent(false);
                                }}
                            >
                                <MenuItem value="Single">Single</MenuItem>
                                <MenuItem value="HeadOfHousehold">Head of Household</MenuItem>
                                <MenuItem value="QualifyingWidower">Qualifying Widower</MenuItem>
                                <MenuItem value="MarriedFilingJointly">Married filing jointly</MenuItem>
                                <MenuItem value="MarriedFilingSeparately">Married filing separately</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Native American Check */}
                    <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mt: 2 }}>
                        <FormLabel sx={{ fontWeight: 'bold', top: '10px' }}>Are you a Native American or Alaskan Native? &nbsp;</FormLabel>
                        <RadioGroup
                            sx={{ flexDirection: { xs: 'column', sm: 'row' }, marginLeft: { sm: 'auto' } }}
                            row
                            name="native"
                            value={native ? "true" : "false"}
                            onChange={(e) => {
                                const newValue = e.target.value === 'true';
                                setNative(newValue);
                                setPromptSent(false);
                                updateAnswers("insuranceDetails.native", newValue);
                                if (newValue) {
                                    setErrors(prevErrors => ({ ...prevErrors, native: '' }));
                                }
                            }}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </>
            )}

            {/* Plan selection status and button */}
            {planConfirmed && (
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography>
                        Current Plan: {benefitPlans.find(p => p.id === selectedPlan)?.name}
                    </Typography>
                    <Button
                        size="small"
                        sx={{ 
                            padding: '8px 16px',
                            border: '1px solid',
                            borderColor: 'primary.main', 
                            margin: '10px'
                        }}
                        onClick={() => {
                            setPlanConfirmed(false);
                            setShowPlanSelection(true);
                        }}
                    >
                        Select a Different Benefit Plan
                    </Button>
                </Box>
            )}

            {/* Preferences Section */}
            <Box sx={{ mt: 2, borderTop: '1px solid #ddd', pt: 2 }}>
                <Button
                    onClick={togglePreferences}
                    sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        color: 'primary.main',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                    }}
                    endIcon={showPreferences ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                    <Typography color="primary" sx={{ fontWeight: 'bold' }}>
                        {showPreferences ? 'Hide Preferences' : 'Show Preferences'}
                    </Typography>
                </Button>
                {showPreferences && (
                    <Box sx={{ mt: 2, px: 2 }}>
                        <EnrollmentPreferences 
                            onPreferencesSaved={handlePreferencesSaved}
                            onPreferencesChanged={handlePreferencesChanged}
                        />
                    </Box>
                )}
            </Box>

            <Box sx={{ minHeight: '60px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {hasUnsavedPreferences ? (
                    <Typography color="warning.main" sx={{ textAlign: 'center', pt: 2 }}>
                        Please save your preferences before continuing
                    </Typography>
                ) : (
                    <Box sx={{ height: '24px' }} /> 
                )}
                <Button
                    sx={{ width: '100%', mt: 2, mb: 2 }}
                    variant="contained"
                    disabled={promptSent || hasUnsavedPreferences}
                    color="primary"
                    onClick={handleContinueClick}
                >
                    Continue
                </Button>
            </Box>

            {rateQuery.loading && (
                <Typography>
                    Refreshing Plans... <br /> <br />
                    <CircularProgress />
                </Typography>
            )}

            {/* Plan selection dialog */}
            <PlanSelectionDialog
                open={showPlanSelection}
                onClose={() => setShowPlanSelection(false)}
                selectedPlan={selectedPlan}
                onPlanSelect={handlePlanSelect}
                onConfirm={handlePlanConfirmation}
                benefitPlans={benefitPlans}
                household={currentHousehold}
                setCurrentSettings={setCurrentSettings}
            />

            {errors.premiumTaxCredits && (
                <Typography color="error">
                    {errors.premiumTaxCredits}
                </Typography>
            )}
        </Box>
    );
});

EnrollmentInformation.displayName = 'EnrollmentInformation';

export default EnrollmentInformation;