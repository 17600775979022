import { Task } from "@when-insurance/interfaces/dist/Case";
import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { AdminCaseActivity, CaseActivity, Company, ICompanyPrivateSetting, CompanyUser, DataPipeline, ExitLetter, SeveranceBenefit, SeverancePackage, WhenBenefitSetting } from "services/Interfaces";
// import { CaseConverter } from "@when-insurance/interfaces/dist/Case";
// export { CaseConverter, type Case };



export const DataPipelineConverter: FirestoreDataConverter<DataPipeline> = {
  toFirestore(dataPipeline: DataPipeline): DocumentData {
    const convert: any = {
      ...dataPipeline,
    }
    if (convert.startTime)
      convert.startTime = convert.startTime.toDate();
    if (convert.endTime)
      convert.endTime = convert.endTime.toDate();
    return {
      ...convert,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<DataPipeline>,
    options: SnapshotOptions
  ): DataPipeline {
    const data = snapshot.data(options);
    return {
      name: data.name,
      type: data.type,
      startTime: data.startTime ? moment(data.startTime.toDate()) : undefined,
      endTime: data.endTime ? moment(data.endTime.toDate()) : undefined,
      objectsProcessed: data.objectsProcessed,
      duration: data.duration,
      errors: data.errors,
      id: snapshot.id,
      ref: snapshot.ref
    };
  }
}
export const CompanyConverter: FirestoreDataConverter<Company> = {
  toFirestore(company: Company): DocumentData {
    return {
      ...company,
      hris: company.hris ? {
        ...company.hris,
        lastImport: company.hris.lastImport ? company.hris.lastImport.toDate() : undefined
      } : undefined,
      createdOn: company.createdOn ? company.createdOn.toDate() : moment().toDate(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Company>,
    options: SnapshotOptions
  ): Company {
    const data = snapshot.data(options);
    const hris = data.hris;
    const branding = data?.branding;
    if (hris?.lastImport) {
      hris.lastImport = moment((hris.lastImport as any).toDate());
    }
    return {
      name: data.name,
      logo: data.logo,
      code: data.code,
      hrisSetup: data.hrisSetup,
      hris: hris,
      branding: branding,
      landingPages: data.landingPages,
      verificationStep: Boolean(data.verificationStep),
      emailDomain: data.emailDomain,
      createdOn: data.createdOn ? moment(data.createdOn.toDate()) : moment(),
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};
export const CompanyUserConverter: FirestoreDataConverter<CompanyUser> = {
  toFirestore(companyUser: CompanyUser): DocumentData {
    console.log("update companyUser", {
      ...companyUser,
      name: {
        first: companyUser.name.first,
        last: companyUser.name.last,
        lowercase: `${companyUser.name.first.toLowerCase()} ${companyUser.name.last.toLowerCase()}`,
        display: `${companyUser.name.first} ${companyUser.name.last}`
      },
      createdAt: companyUser.createdAt ? companyUser.createdAt?.toDate() : moment().toDate(),
      updatedAt: moment().toDate(),
    })
    return {
      ...companyUser,
      name: {
        first: companyUser.name.first,
        last: companyUser.name.last,
        lowercase: `${companyUser.name.first.toLowerCase()} ${companyUser.name.last.toLowerCase()}`,
        display: `${companyUser.name.first} ${companyUser.name.last}`
      },
      createdAt: companyUser.createdAt ? companyUser.createdAt?.toDate() : moment().toDate(),
      updatedAt: moment().toDate(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<CompanyUser>,
    options: SnapshotOptions
  ): CompanyUser {
    const data = snapshot.data(options);
    return {
      name: data.name,
      email: data.email,
      roles: data.roles,
      active: data.active,
      inviteEmail: data.inviteEmail,
      userId: data.userId,
      createdAt: data.createdAt ? moment(data.createdAt.toDate()) : undefined,
      updatedAt: data.updatedAt ? moment(data.updatedAt.toDate()) : undefined,
      updatedBy: data.updatedBy,
      updatedByUid: data.updatedByUid,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

function createActivityConverter<
  T extends CaseActivity
>(): FirestoreDataConverter<T> {
  return {
    toFirestore(activity: T): DocumentData {
      return {
        ...activity,
        timestamp: moment(activity.timestamp)?.toISOString(),
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot<T>,
      options: SnapshotOptions
    ): T {
      const data = snapshot.data(options);
      return {
        activity: data.activity,
        type: data.type,
        userId: data.userId,
        caseId: data.caseId,
        companyId: data.companyId,
        displayName: data.displayName,
        message: data.message,
        timestamp: moment(data.timestamp),
        changes: data.changes,
        id: snapshot.id,
        ref: snapshot.ref,
      } as unknown as T;
    },
  };
}

export const CaseActivityConverter = createActivityConverter<CaseActivity>();
export const AdminCaseActivityConverter =
  createActivityConverter<AdminCaseActivity>();

export const TaskConverter: FirestoreDataConverter<Task> = {
  toFirestore(task: Task): DocumentData {
    return {
      ...task,
      deadline: task.deadline ? {
        ...task.deadline,
        date: task.deadline?.date?.toDate()
      } : undefined,
      createdAt: task.createdAt?.toDate(),
      updatedAt: task.updatedAt?.toDate(),
      completedOn: task.completedOn?.toISOString()
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Task>,
    options: SnapshotOptions
  ): Task {
    const data = snapshot.data(options);
    return {
      name: data.name,
      caseId: data.caseId,
      type: data.type,
      companyId: data.companyId,
      caseName: data.caseName,
      deadline: data.deadline ? {
        beforeOrAfter: data.deadline?.beforeOrAfter,
        days: data.deadline?.days,
        date: data.deadline?.date ? moment(data.deadline?.date?.toDate()) : undefined
      } : undefined,
      assignedTo: data.assignedTo,
      assignedToUsers: data.assignedToUsers,
      status: data.status,
      notes: data.notes,
      createdAt: moment(data.createdAt?.toDate()) || undefined,
      createdBy: data.createdBy,
      updatedAt: moment(data.updatedAt?.toDate()) || undefined,
      updatedBy: data.updatedBy,
      completedBy: data.completedBy,
      completedOn: data.completedOn ? moment(data.completedOn) : undefined,
      completedByUid: data.completedByUid,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const ExitLetterConverter: FirestoreDataConverter<ExitLetter> = {
  toFirestore(exitLetter: ExitLetter): DocumentData {
    return {
      ...exitLetter,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ExitLetter {
    const data = snapshot.data(options);
    return {
      name: data.name,
      notes: data.notes,
      body: data.body,
      active: data.active,
      deletedAt: data.delatedAt,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const SeverancePackageConverter: FirestoreDataConverter<SeverancePackage> = {
  toFirestore(severancePackage: SeverancePackage): DocumentData {
    return {
      ...severancePackage,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SeverancePackage {
    const data = snapshot.data(options);
    return {
      name: data.name,
      description: data.description,
      active: data.active,
      deletedAt: data.delatedAt,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const SeveranceBenefitConverter: FirestoreDataConverter<SeveranceBenefit> = {
  toFirestore(severanceBenefit: SeveranceBenefit): DocumentData {
    return {
      ...severanceBenefit,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SeveranceBenefit {
    const data = snapshot.data(options);
    return {
      active: data.active,
      type: data.type,
      name: data.name,
      structure: data.structure,
      term: data.term,
      amountOrCoverage: data.amountOrCoverage,
      serviceId: data.serviceId,
      conditions: data.conditions,
      id: snapshot.id,
      ref: snapshot.ref,
      calculation: data.calculation
    };
  },
};
export const CompanyPrivateSettingConverter: FirestoreDataConverter<ICompanyPrivateSetting> = {
  toFirestore(privateSettings: ICompanyPrivateSetting): DocumentData {
    return {
      ...privateSettings,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ICompanyPrivateSetting {
    const data = snapshot.data(options);
    return {
      billCustomerId: data.billCustomerId,
      invoice: data.invoice,
      fees: data.fees,
      automation: data.automation,
      whenBenefitUseOnCobra: data.whenBenefitUseOnCobra,
      integrationStartDate: data.integrationStartDate,
    };
  },
};
export const CompanyWhenSettingConverter: FirestoreDataConverter<WhenBenefitSetting> = {
  toFirestore(whenBenefitSettings: WhenBenefitSetting): DocumentData {
    return {
      ...whenBenefitSettings,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WhenBenefitSetting {
    const data = snapshot.data(options);
    return {
      timing: data.timing
    };
  },
};
