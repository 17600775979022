import { Cached, KeyboardArrowDown, ThumbUpAlt, ThumbDownAlt, SentimentSatisfied, SentimentDissatisfied, SentimentSatisfiedAlt, SentimentVerySatisfied, SentimentVeryDissatisfied } from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Menu, Typography, Fade, Link, Snackbar, TextField, IconButton, Card, Divider, Select, MenuItem, Radio, FormControlLabel, RadioGroup, Grid, Alert, SelectChangeEvent } from "@mui/material";
import { useEffect, useState, useCallback, useRef } from "react";
import { ReactComponent as InfoIcon } from "images/InfoIcon.svg";
import { ReactComponent as JamieImage } from "images/Jamie.svg";
import { useMutation } from "@apollo/client";
import { Mutations } from "services/apollo";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, doc, orderBy, query, setDoc, updateDoc, addDoc, getDoc, getDocs, arrayUnion, limit, deleteDoc } from "firebase/firestore";
import { useAuth } from "contexts/User";
import { useFirebase } from "contexts/Firebase";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import WhenBenefitCreator from './JamieComponents/WhenBenefitCreator';
// import DemoServicesCreator from './JamieComponents/DemoServicesCreator';
import Recommendations from './JamieComponents/Recommendations';
import EnrollmentInformation from './JamieComponents/EnrollmentInformation';
import PreferencesTemplate from "./JamieComponents/PreferencesTemplate";
import EnrollNow from './JamieComponents/EnrollNow';
import UploadResume from './JamieComponents/UploadResume';
import { ResetChatButton } from './JamieComponents/ResetChatButton';
import CancelButton from './JamieComponents/CancelButton';
import ClearButton from './JamieComponents/ClearButton';
import ResetInsuranceButton from './JamieComponents/ResetInsuranceButton';
import ResetSelectedPlanButton from './JamieComponents/ResetSelectedPlanButton';
import HumanHandoff from './JamieComponents/HumanHandoff';
// import SampleQuestionsButton from './JamieComponents/SampleQuestionsButton';
import CreateBenefitPlans from './JamieComponents/CreateBenefitPlans';
import { EventTypes, getInitialMessage, formatEventType } from './JamieComponents/utils';
import { User } from 'firebase/auth';

function TypedMessage({ 
    text, 
    speed, 
    finished, 
    className, 
    autoScroll, 
    testId,
    isInitialMessage,
    messageThreadLength,
    onAnimationComplete 
}: { 
    text: string, 
    speed: number, 
    finished: Function, 
    className?: string, 
    autoScroll?: Function, 
    testId?: string,
    isInitialMessage: boolean,
    messageThreadLength: number,
    onAnimationComplete?: () => void
}) {
    const shouldAnimateRef = useRef<boolean>(isInitialMessage && messageThreadLength <= 1);
    
    const [message, setMessage] = useState<string>(shouldAnimateRef.current ? "" : text);
    const [, setNewInterval] = useState<any>();
    const [complete, setComplete] = useState<boolean>(!shouldAnimateRef.current);
    const ref = useRef<string>("");
    ref.current = message;
    const scrollSpan = useRef<any>();

    useEffect(() => {
        if (!shouldAnimateRef.current) {
            setMessage(text);
            if (!complete) {
                setComplete(true);
                finished();
            }
        }
    }, [text, complete, finished]);

    useEffect(() => {
        if (!shouldAnimateRef.current || complete) {
            return;
        }

        const newInterval = setInterval(() => {
            if (ref.current.length < text.length) {
                setMessage(prev => prev + text[prev.length]);
            } else {
                setComplete(true);
                finished();
                onAnimationComplete?.();
            }
        }, speed);
        
        setNewInterval(newInterval);
        
        return () => {
            clearInterval(newInterval);
        };
    }, [complete, text, speed, finished, onAnimationComplete]);

    useEffect(() => {
        if (scrollSpan.current && typeof autoScroll === "function") {
            autoScroll();
        }
    }, [message, autoScroll]);

    return (
        <Box>
            <Typography
                data-testid={testId || ""}
                className={className}
                sx={{
                    backgroundColor: '#F1F1F1',
                    borderRadius: '17px 17px 17px 2px',
                    boxSizing: 'border-box',
                    color: 'black.main',
                    display: 'block',
                    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                    fontSize: '16px',
                    fontWeight: 400,
                    height: 'auto',
                    width: 'auto',
                    letterSpacing: '0.15008px',
                    lineHeight: '24px',
                    m: 0,
                    maxWidth: { xs: "100%", sm: "75%" },
                    p: 1,
                    pt:2,
                    textAlign: 'left',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    WebkitFontSmoothing: 'antialiased'
                }}
                variant="body1"
                dangerouslySetInnerHTML={{ __html: message }}
            />
            <span ref={scrollSpan}></span>
        </Box>
    );
}

function JamiePrototype() {
    const { user, userData } = useAuth();
    const { firestore } = useFirebase();
    const [helpfulInfoAnchorEl, setHelpfulInfoAnchorEl] = useState<null | HTMLElement>(null);
    const helpfulInfoOpen = Boolean(helpfulInfoAnchorEl);
    const handleClickHelpfulInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHelpfulInfoAnchorEl(event.currentTarget);
    };
    const handleCloseHelpfulInfo = () => {
        setHelpfulInfoAnchorEl(null);
    };
    const [messageThread, setMessageThread] = useState<Array<{
        id: string;
        text: string;
        user: boolean;
        timestamp: string;
        type: string;
        dynamicTemplate: string;
        data: any;
    }>>([]);
    const [loading, setLoading] = useState(false);
    const [, setError] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [chatType, setChatType] = useState("cold");
    const [hasCobra, setHasCobra] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [conversationId, setConversationId] = useState<string | null>(userData?.get("conversationId"));
    const [selectedEventType, setSelectedEventType] = useState<string>(EventTypes.VOLUNTARY_TERMINATION);

    const [isNewConversation, setIsNewConversation] = useState(false);
    const [showAskQuestionButtons, setShowAskQuestionButtons] = useState(true);
    const [, setIsResetting] = useState<boolean>(false);

    const [messageStream, setMessageStream] = useState<string[]>([]);
    const [cancelMessage] = useMutation(Mutations.cancelMessage);
    const [clearMessages] = useMutation(Mutations.clearMessages);
    
    const cancel = () => {
        cancelMessage();
        setUserMessage("");
    }
    const clear = () => {
        clearMessages();
        setUserMessage("");
        setMessageThread([]);
    };
    useEffect(() => {
        if (messageStream.join("") === messageThread[messageThread.length - 1]?.text) {
            console.log("Message Stream is the same as the last message in the thread")
            setMessageStream([]);
        }
    }, [messageStream, messageThread])

    useEffect(() => {
        const createUserSettings = async () => {
            if (user) {
                const userSettingsRef = doc(firestore, "users", user.uid, "settings", "chat");
                const userSettingsDoc = await getDoc(userSettingsRef);
                if (!userSettingsDoc.exists()) {
                    await setDoc(userSettingsRef, {
                        insuranceDataCollected: false,
                        selectedPlan: null,
                        enrollmentStatus: null,
                    });
                    console.log("User settings document created");
                }
            }
        };

        createUserSettings();
    }, [user, firestore]);

    const createNewConversation = useCallback(async (message: string, isPrompt = false) => {
        if (!user) {
            console.error("User is not authenticated");
            return;
        }
    
        console.log("Creating new conversation for user:", user.uid);
        const convosRef = collection(firestore, `users/${user.uid}/convos`);
        
        try {
            // Create the conversation data object first
            const convoData = {
                startTimestamp: new Date(),
                chatType: chatType,
                hasCobra: hasCobra,
                userId: user.uid,
                eventType: selectedEventType,
                messages: [{
                    text: message,
                    user: true, // Set user to true for user messages
                    timestamp: new Date(),
                    type: isPrompt ? "prompt" : "message",
                    dynamicTemplate: "",
                    data: {}
                }],
                feedbacks: [],
                currentSettings: {
                    insuranceStatus: null,
                    effectiveDate: null,
                    selectedPlan: null,
                    whenBenefits: [],
                    benefitPlan: null,
                    eventType: selectedEventType
                }
            };
    
            console.log("Conversation data to be saved:", convoData);
    
            // Add the document to Firestore
            const newConvoRef = await addDoc(convosRef, convoData);
            console.log("New conversation document created with ID:", newConvoRef.id);
    
            // Verify the saved data
            const savedConvoDoc = await getDoc(newConvoRef);
            const savedData = savedConvoDoc.data();
            console.log("Saved conversation data:", savedData);
    
            if (!savedData?.userId) {
                console.error("userId not found in saved data, attempting to update");
                // If userId is missing, try to update it explicitly
                await updateDoc(newConvoRef, {
                    userId: user.uid
                });
            }
    
            setConversationId(newConvoRef.id);
            await setDoc(doc(firestore, "users", user.uid), {
                conversationId: newConvoRef.id
            }, { merge: true });
    
            return newConvoRef.id;
        } catch (error) {
            console.error("Error creating new conversation:", error);
            throw error;
        }
    }, [firestore, user, chatType, hasCobra, selectedEventType]);
    

    useEffect(() => {
        if (conversationId && user) {
            const verifyConversation = async () => {
                try {
                    const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                    const convoDoc = await getDoc(convoRef);
                    const convoData = convoDoc.data();
                    console.log("Verified conversation data:", convoData);
                    
                    if (!convoData?.userId) {
                        console.warn("UserId missing in conversation, adding it now");
                        await updateDoc(convoRef, {
                            userId: user.uid
                        });
                    }
                } catch (err) {
                    console.error("Error verifying conversation:", err);
                }
            };
            
            verifyConversation();
        }
    }, [conversationId, user, firestore]);

    useEffect(() => {
        if (!conversationId) {
            if (userData && userData.get("conversationId")) {
                setConversationId(userData.get("conversationId"))
            } else if (userData && !userData.get("conversationId")) {
                createNewConversation("start", true);
            }
        }
    }, [userData, conversationId, createNewConversation])

    const sendMessageStreamImpl = useCallback(async (message: string, isPrompt = false, conversationId: string) => {
        const convoRef = doc(firestore, `users/${user?.uid}/convos/${conversationId}`);
        
        try {
            const messageId = doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/messages`)).id;
            await updateDoc(convoRef, {
                messages: arrayUnion({
                    id: messageId,
                    text: message,
                    user: true,
                    timestamp: new Date(),
                    type: isPrompt ? "prompt" : "message",
                    dynamicTemplate: "",
                    data: {},
                })
            });
            
            console.log("User message added to conversation with ID:", conversationId);
        } catch (error) {
            console.error("Error adding user message to conversation:", error);
        }
    
        let stream: string[] = [];
        let hasError = false;

        try {
            const sse = new EventSource(
                `${process.env.REACT_APP_JAMIE_SSE_URL}?uid=${user?.uid}&message=${message}&isPrompt=${isPrompt}`,
                { withCredentials: true }
            );

            const handleSSEError = async () => {
                if (hasError) return; // Prevent multiple error messages
                hasError = true;
                
                console.error("SSE connection failed");
                setMessageStream([]);
                sse.close();

                const currentTime = new Date();
                
                // Create error and human handoff messages
                const messages = [
                    {
                        id: doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/messages`)).id,
                        text: "I apologize, but I'm having trouble processing your request at the moment.",
                        user: false,
                        timestamp: currentTime.toISOString(),
                        type: "ai",
                        dynamicTemplate: "",
                        data: {}
                    },
                    {
                        id: doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/messages`)).id,
                        text: "",
                        user: false,
                        timestamp: currentTime.toISOString(),
                        type: "dynamic",
                        dynamicTemplate: "human",
                        data: {}
                    }
                ];

                try {
                    // Update Firestore
                    await updateDoc(convoRef, {
                        messages: arrayUnion(...messages)
                    });

                    // Update local state
                    setMessageThread(prevThread => [...prevThread, ...messages]);
                } catch (error) {
                    console.error("Error updating messages after SSE error:", error);
                }
            };

            const getRealtimeData = (e: any) => {
                try {
                    const parsed = JSON.parse(e.data);
                    if (parsed.done) {
                        // Add AI's complete response to the conversation
                        updateDoc(convoRef, {
                            messages: arrayUnion({
                                id: doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/messages`)).id, 
                                text: stream.join(""),
                                user: false,
                                timestamp: new Date(),
                                type: "ai",
                                dynamicTemplate: "",
                                data: {}
                            })
                        });
                        sse.close();
                    } else {
                        stream.push(parsed.content);
                        setMessageStream([...stream]);
                    }
                } catch (parseError) {
                    console.error("Error parsing SSE data:", parseError);
                    handleSSEError();
                }
            };

            sse.onmessage = getRealtimeData;
            sse.onerror = handleSSEError;

        } catch (error) {
            console.error("Error setting up SSE connection:", error);
            // Handle initial connection error
            const errorMessage = {
                id: doc(collection(firestore, `users/${user?.uid}/convos/${conversationId}/messages`)).id,
                text: "I apologize, but I'm unable to connect to the service at the moment.",
                user: false,
                timestamp: new Date().toISOString(),
                type: "ai",
                dynamicTemplate: "",
                data: {}
            };

            await updateDoc(convoRef, {
                messages: arrayUnion(errorMessage)
            });
            setMessageThread(prevThread => [...prevThread, errorMessage]);
        }
    }, [firestore, user?.uid]);

    const sendMessageStream = useCallback(async (message: string, isPrompt = false) => {
        if (!conversationId) {
            console.error("No conversation ID found");
            return;
        }

        if (!user?.uid) {
            console.error("No user found");
            return;
        }

        if (isNewConversation) {
            // Create a new conversation document
            const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
            const convoData = {
                startTimestamp: new Date(),
                chatType: chatType,
                hasCobra: hasCobra,
                userId: user.uid,
                eventType: selectedEventType,
                messages: [],
                feedbacks: [],
                currentSettings: {
                    insuranceStatus: null,
                    effectiveDate: null,
                    selectedPlan: null,
                    whenBenefits: [],
                    benefitPlan: null,
                    eventType: selectedEventType
                }
            };
            
            console.log("Setting new conversation data:", convoData);
            await setDoc(convoRef, convoData);
            setIsNewConversation(false);
        }

        await sendMessageStreamImpl(message, isPrompt, conversationId);
    }, [conversationId, isNewConversation, user?.uid, firestore, chatType, hasCobra, selectedEventType, sendMessageStreamImpl]);

    const send = useCallback(async () => {
        const message = userMessage;
        setUserMessage("");
        setLoading(true);
        sendMessageStream(message);
        setLoading(false);
    }, [userMessage, sendMessageStream]);
    const sendPrompt = async (message: string) => {
        setLoading(true);
        sendMessageStream(message, true);
        setLoading(false);
    }

    const [messages, ,] = useCollection(
        query(
            collection(firestore, "users", user?.uid || "a", "messages"),
            orderBy("timestamp", "asc")
        ),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [, setMessagesLoaded] = useState(false);

    useEffect(() => {
        if (messages) {
            const newMessages = messages.docs.map((doc) => {
                const data = doc.data();
                return {
                    id: doc.id,
                    text: data.text,
                    user: data.user,
                    type: data.type,
                    dynamicTemplate: data.dynamicTemplate,
                    data: data.data,
                    timestamp: data.timestamp,
                };
            });

            // Preserve the initial message by combining it with new messages
            setMessageThread(prevThread => {
                const initialMessage = prevThread.find(msg => msg.id === 'initial-message');
                if (initialMessage) {
                    return [initialMessage, ...newMessages];
                }
                return newMessages;
            });
            setMessagesLoaded(true); // Mark messages as loaded
        }
    }, [messages]);
    
    useEffect(() => {
        console.log("Current messageThread state:", messageThread);
    }, [messageThread]);

    const [feedback, setFeedback] = useState<{ [key: string]: string }>({});
    const [feedbackVisible, setFeedbackVisible] = useState<{ [key: string]: boolean }>({});
    const feedbackTimers = useRef<{ [key: string]: NodeJS.Timeout }>({});

    const [showAllFeedback, setShowAllFeedback] = useState(false);

    const handleFeedbackVisibility = useCallback((messageId: string) => {
        if (showAllFeedback) {
            setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
            // Clear any existing timer when showing all feedback
            if (feedbackTimers.current[messageId]) {
                clearTimeout(feedbackTimers.current[messageId]);
                delete feedbackTimers.current[messageId];
            }
        } else {
            // Clear any existing timer
            if (feedbackTimers.current[messageId]) {
                clearTimeout(feedbackTimers.current[messageId]);
                delete feedbackTimers.current[messageId];
            }
            
            // Set up a new timer
            feedbackTimers.current[messageId] = setTimeout(() => {
                setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
            }, 120000);
        }
    }, [showAllFeedback]);

    useEffect(() => {
        messageThread.forEach((message) => {
            if (message.type !== "human") {
                handleFeedbackVisibility(message.id);
            }
        });
    }, [messageThread, handleFeedbackVisibility]);

    const resetFeedbackTimer = useCallback((messageId: string) => {
        // Clear any existing timer
        if (feedbackTimers.current[messageId]) {
            clearTimeout(feedbackTimers.current[messageId]);
            delete feedbackTimers.current[messageId];
        }
        
        // Hide the feedback immediately
        setFeedbackVisible((prev) => ({ ...prev, [messageId]: false }));
        
        // Set up a new timer
        feedbackTimers.current[messageId] = setTimeout(() => {
            setFeedbackVisible((prev) => ({ ...prev, [messageId]: true }));
        }, 120000);
    }, []);

    const handleSentiment = useCallback(async (sentimentType: string) => {
        console.log("handleSentiment called with sentimentType:", sentimentType);

        setFeedback((prev) => ({ ...prev, sentiment: sentimentType }));

        if (!user?.uid) {
            console.error("User is not authenticated");
            return;
        }

        try {
            if (conversationId) {
                // Update sentiment in the existing conversation
                const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                await updateDoc(convoRef, {
                    sentiment: sentimentType
                });
            } else {
                // If no conversation exists, store sentiment in user's settings
                const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
                await setDoc(settingsRef, { lastSentiment: sentimentType }, { merge: true });
            }
            console.log("Sentiment updated successfully in Firestore");
        } catch (error) {
            console.error("Error updating sentiment in Firestore:", error);
        }
    }, [firestore, user?.uid, conversationId]);

    const [selectedSentiment, setSelectedSentiment] = useState<string | null>(null);
    const [additionalFeedback, setAdditionalFeedback] = useState<string>("");
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [sentimentVisible, setSentimentVisible] = useState<boolean>(true);
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [canContact, setCanContact] = useState<boolean>(true);


    const handleFeedback = useCallback(async (messageId: string, feedbackType: string) => {
        console.log("handleFeedback called with messageId:", messageId, "and feedbackType:", feedbackType);
        try {
            if (conversationId && user?.uid) {
                const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                const messagesRef = collection(firestore, `users/${user.uid}/messages`);
                
                const convoSnap = await getDoc(convoRef);
                const messageSnap = await getDoc(doc(messagesRef, messageId));
                
                if (convoSnap.exists() && messageSnap.exists()) {
                    const convoData = convoSnap.data();
                    const messages = convoData.messages || [];
                    const messageData = messageSnap.data();
                    // console.log("Message from separate collection:", messageData);
                    // console.log("Messages in conversation:", messages);
                    const messageTimestamp = messageData.timestamp.toDate().getTime();
                    // console.log("Timestamp of the message from separate collection:", messageTimestamp);
                    // Find the corresponding message in the conversation by matching timestamps with a 1-second tolerance
                    const targetMessage = messages.find((msg: any) => {
                        const msgTimestamp = msg.timestamp.toDate().getTime();
                        console.log(`Comparing timestamps: ${msgTimestamp} vs ${messageTimestamp}`);
                        return Math.abs(msgTimestamp - messageTimestamp) < 1000 && msg.type === messageData.type;
                    });
                    
                    if (targetMessage) {
                        console.log("Matching message found in conversation:", targetMessage);
    
                        await updateDoc(convoRef, {
                            messages: messages.map((msg: any) => 
                                msg.id === targetMessage.id ? { ...msg, feedback: feedbackType } : msg
                            ),
                            feedbacks: arrayUnion({
                                additionalFeedback,
                                messageId: targetMessage.id, // Use the id from the conversation document
                                feedbackType,
                                timestamp: new Date(),
                                messageIdRef: messageId // Use the original messageId as the reference to the separate messages collection
                            })
                        });
    
                        setFeedback((prev) => ({ ...prev, [messageId]: feedbackType }));
                        // console.log("Feedback updated successfully for message:", messageId);
                    } else {
                        console.error("No matching message found in conversation document.");
                        // console.log("Available message timestamps:", messages.map((msg: any) => msg.timestamp.toDate().getTime()));
                    }
                } else {
                    console.error("Conversation document or message document not found");
                }
            } else {
                console.error("No conversation ID or user ID found");
            }
        } catch (error) {
            console.error("Error updating feedback: ", error);
        }
    }, [firestore, user?.uid, conversationId, additionalFeedback]);

    const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCanContact(event.target.value === 'yes');
    };

    useEffect(() => {
        if (feedbackSubmitted && selectedSentiment) {
            handleSentiment(selectedSentiment);
            setShowThankYou(true);
            setTimeout(() => {
                setSelectedSentiment(null);
                setAdditionalFeedback("");
                setFeedbackSubmitted(false);
                setShowThankYou(false);
                setSentimentVisible(false);
            }, 500000); 
        }
    }, [feedbackSubmitted, selectedSentiment, handleSentiment]);

    const handleSentimentClick = (sentimentType: string) => {
        setSelectedSentiment(sentimentType);
        setSentimentVisible(true);
    };
    const handleSubmitFeedback = async () => {
        console.log("Additional feedback submitted:", additionalFeedback);
        setFeedbackSubmitted(true);
        setSentimentVisible(false);
        setIsTyping(false);
    
        // Ensure selectedSentiment is a string before updating the state
        const sentiment = selectedSentiment || "";
        
        // Update the state with the feedback
        setFeedback((prev) => ({
            ...prev,
            sentiment: sentiment,
            additionalFeedback: additionalFeedback
        }));
    
        console.log("Feedback and additional feedback updated in state");
    
        // Call handleFeedback for the last message in the thread
        if (messageThread.length > 0) {
            const lastMessage = messageThread[messageThread.length - 1];
            await handleFeedback(lastMessage.id, sentiment);
        }
    
        // Update the conversation document with the overall sentiment and additional feedback
        if (conversationId) {
            const convoRef = doc(firestore, `users/${user?.uid}/convos/${conversationId}`);
            await updateDoc(convoRef, {
                sentiment: sentiment,
                additionalFeedback: additionalFeedback,
                canContact: canContact
            });
            console.log("Overall sentiment and additional feedback added to conversation document");
        } else {
            console.error("No conversation ID found");
        }
    
        // Reset states after submission
        setSelectedSentiment(null);
        setAdditionalFeedback("");
        setShowAllFeedback(false);
        messageThread.forEach((message) => {
            if (message.type !== "human") {
                handleFeedbackVisibility(message.id);
            }
        });
    };

    // console.log("Rendering component. sentimentVisible:", sentimentVisible, "feedbackSubmitted:", feedbackSubmitted);

    useEffect(() => {
        const typingTimeout = setTimeout(() => {
            setIsTyping(false);
        }, 60000); // 60 seconds

        return () => clearTimeout(typingTimeout);
    }, [userMessage]);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions] = useState([
        "What is COBRA?",
        "Can you recommend any health insurance plans?",
        "Can I update my preferences?",
        "Can you help me find health insurance?",
        "Can I update my household information?",
        "Do I have a When benefit?",
        "Do I have any Services available?",
        "What is the status of my When benefit?",
        "Can you help me with my 401k?",
        "Can you help me with the job search?",
        "How do I update my personal information?",
        "Can I change my health plan?",
        "How do I add a dependent to my plan?",
        "How do I contact customer support?",
        "What are the available payment options?",
        "Can I get a summary of my coverage?",
    ]);

    const [isAskingQuestion, setIsAskingQuestion] = useState(false);

    const askQuestion = (text: string) => {
        setUserMessage(text);
        setShowAskQuestionButtons(false);
        setIsAskingQuestion(true);
    };

    useEffect(() => {
        if (isAskingQuestion) {
            send();
            setIsAskingQuestion(false);
        }
    }, [userMessage, isAskingQuestion, send]);


    const toggleAskQuestionButtons = () => {
        setShowAskQuestionButtons(prevState => !prevState);
    };

    const refreshQuestions = useCallback(() => {
        setCurrentQuestionIndex((prevIndex) => (prevIndex + 3) % questions.length);
    }, [questions.length]);

    useEffect(() => {
        refreshQuestions();
    }, [refreshQuestions]);

    const [, setConversationExists] = useState(false);

    useEffect(() => {
        const checkConversationExists = async () => {
            if (user && firestore) {
                const convoRef = collection(firestore, `users/${user.uid}/convos`);
                const q = query(convoRef, orderBy("startTimestamp", "desc"), limit(1));
                const querySnapshot = await getDocs(q);
                setConversationExists(!querySnapshot.empty);
            }
        };

        checkConversationExists();
    }, [user, firestore]);

    const filteredMessageThread = messageThread.filter(message => 
        message.type === "dynamic" || // Always include dynamic messages
        (
            message.text !== "I have completed the form, please provide the recommended plans" &&
            message.text !== "I have updated my preferences. Please provide updated health insurance plan recommendations." &&
            message.text !== "Hello Jamie, I'd like to discuss my health insurance options and learn more about your capabilities." &&
            message.text !== "the user can see their recommended plan details on the page in the above table. Below the table, there is also a button to update preferences" &&
            message.text && message.text.trim() !== "" // Check if text exists and is not empty
        )
    );

    const handleEventTypeChange = async (event: SelectChangeEvent<string>) => {
        const newEventType = event.target.value;
        setSelectedEventType(newEventType);
        
        if (!user?.uid) {
            console.error("No user found");
            return;
        }

        try {
            const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
            const caseTypeValue = newEventType.toUpperCase().replace(/ /g, '_');
            await setDoc(settingsRef, {
                caseType: caseTypeValue,
                updatedAt: new Date()
            }, { merge: true });
            
            setSnackbarMessage("Event type updated successfully");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error updating event type:", error);
            setError("Failed to update event type");
            setSnackbarMessage("Error updating event type");
            setSnackbarOpen(true);
        }
    };

    const [isExistingConversation, setIsExistingConversation] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [caseTypeLoaded, setCaseTypeLoaded] = useState(false);
    const [isInitialMessageAnimating, setIsInitialMessageAnimating] = useState(true);

    useEffect(() => {
        const loadConversation = async () => {
            if (user?.uid && conversationId) {
                setIsLoading(true);
                try {
                    const convoRef = doc(firestore, `users/${user.uid}/convos/${conversationId}`);
                    const convoDoc = await getDoc(convoRef);
                    
                    if (convoDoc.exists()) {
                        const convoData = convoDoc.data();
                        if (convoData.messages && convoData.messages.length > 0) {
                            setIsExistingConversation(true);
                            // Filter out any empty or invalid messages
                            const validMessages = convoData.messages.filter((msg: any) => 
                                msg && msg.text && msg.text.trim() !== ""
                            );
                            
                            // Set the message thread with all valid messages
                            setMessageThread(validMessages);
                            setIsInitialMessageAnimating(false);
                            
                            // Load any existing feedback
                            const feedbackData = convoData.feedbacks || [];
                            const feedbackMap: { [key: string]: string } = {};
                            feedbackData.forEach((feedback: any) => {
                                if (feedback.messageId) {
                                    feedbackMap[feedback.messageId] = feedback.feedbackType;
                                }
                            });
                            setFeedback(feedbackMap);
                        } else {
                            // No messages in conversation, set as new conversation
                            setIsExistingConversation(false);
                        }
                    } else {
                        // Conversation doesn't exist, set as new conversation
                        setIsExistingConversation(false);
                    }
                } catch (error) {
                    console.error("Error loading conversation:", error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };

        loadConversation();
    }, [user?.uid, conversationId, firestore]);

    // Load case type only if it's a new conversation
    useEffect(() => {
        const loadCaseType = async () => {
            if (user?.uid && firestore && !isExistingConversation) {
                const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
                const settingsDoc = await getDoc(settingsRef);
                if (settingsDoc.exists()) {
                    const caseType = settingsDoc.data()?.caseType;
                    if (caseType) {
                        const displayCaseType = Object.entries(EventTypes).find(
                            ([, value]) => value.toUpperCase().replace(/ /g, '_') === caseType
                        )?.[1];
                        if (displayCaseType) {
                            setSelectedEventType(displayCaseType);
                        }
                    }
                }
                setCaseTypeLoaded(true);
            }
        };

        loadCaseType();
    }, [user?.uid, firestore, isExistingConversation]);

    // Generate initial message only for new conversations
    useEffect(() => {
        const loadInitialMessage = async () => {
            if (user && firestore && caseTypeLoaded && !isExistingConversation && messageThread.length === 0) {
                setIsInitialMessageAnimating(true); // Set animation state when creating new message
                let companyName = "[company_name]";
                if (user) {
                    const userDoc = await getDoc(doc(firestore, "users", user.uid));
                    const companyId = userDoc.data()?.companyId;
                    if (companyId) {
                        const companyDoc = await getDoc(doc(firestore, "companies", companyId));
                        if (companyDoc.exists()) {
                            companyName = companyDoc.data()?.name || "[company_name]";
                        }
                    }
                }

                const initialMessage = getInitialMessage(selectedEventType, companyName);
                
                setMessageThread([{
                    id: 'initial-message',
                    text: initialMessage,
                    user: false,
                    timestamp: new Date().toISOString(),
                    type: 'text',
                    dynamicTemplate: '',
                    data: {}
                }]);
            }
        };

        loadInitialMessage();
    }, [user, firestore, selectedEventType, caseTypeLoaded, isExistingConversation, messageThread.length]);

    useEffect(() => {
        console.log("Current messageThread state:", messageThread);
    }, [messageThread]);

    // Clean up timers on component unmount
    useEffect(() => {
        // Store ref value in a variable inside the effect
        const timers = feedbackTimers.current;
        
        return () => {
            // Use the stored variable in cleanup
            Object.keys(timers).forEach(key => {
                if (timers[key]) {
                    clearTimeout(timers[key]);
                }
            });
        };
    }, []); // Empty dependency array since we only want this to run on mount/unmount

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleDeleteWhenBenefits = async () => {
        if (!user) {
            console.error("User is not authenticated");
            return;
        }

        try {
            const whenBenefitsRef = collection(firestore, `users/${user.uid}/whenBenefits`);
            const querySnapshot = await getDocs(whenBenefitsRef);
            
            // Delete each document in the collection
            const deletePromises = querySnapshot.docs.map(doc => 
                deleteDoc(doc.ref)
            );
            
            await Promise.all(deletePromises);
            
            // Update the current settings state immediately after deletion
            setCurrentSettings(prev => ({
                ...prev,
                whenBenefits: [] // Reset whenBenefits to empty array
            }));
            
            setSnackbarMessage("When benefits deleted successfully");
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error deleting When benefits:", error);
            setSnackbarMessage("Error deleting When benefits");
            setSnackbarOpen(true);
        }
    };

    const handleResetChat = () => {
        setIsInitialMessageAnimating(true);
        setConversationId(null);
        setMessageThread([]); // Clear the message thread explicitly
        setIsExistingConversation(false); // Reset the conversation state
        clear();
    };

    const [currentSettings, setCurrentSettings] = useState<{
        insuranceStatus: boolean | null;
        effectiveDate: string | null;
        selectedPlan: any;
        whenBenefits: any[];
        benefitPlan: string | null;
    }>({
        insuranceStatus: null,
        effectiveDate: null,
        selectedPlan: null,
        whenBenefits: [],
        benefitPlan: null
    });

    useEffect(() => {
        const fetchCurrentSettings = async () => {
            if (!user?.uid) return;

            try {
                // Fetch chat settings
                const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
                const settingsDoc = await getDoc(settingsRef);
                const settingsData = settingsDoc.data();

                // Fetch answers document for insurance details
                const answersRef = doc(firestore, `users/${user.uid}/answers/${user.uid}`);
                const answersDoc = await getDoc(answersRef);
                const answersData = answersDoc.data();
                const insuranceDetailsData = answersData?.insuranceDetails;

                // Fetch when benefits with remaining amount
                const whenBenefitsRef = collection(firestore, `users/${user.uid}/whenBenefits`);
                const whenBenefitsSnapshot = await getDocs(whenBenefitsRef);
                const whenBenefits = whenBenefitsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));

                // Format the effective date if it exists
                const formattedDate = insuranceDetailsData?.effectiveDate 
                    ? new Date(insuranceDetailsData.effectiveDate + 'T00:00:00').toLocaleDateString()
                    : null;

                setCurrentSettings({
                    insuranceStatus: settingsData?.insuranceDataCollected ?? null,
                    effectiveDate: formattedDate,
                    selectedPlan: settingsData?.selectedPlanDetails ?? null,
                    whenBenefits: whenBenefits,
                    benefitPlan: settingsData?.selectedBenefitPlan?.name ?? null
                });
            } catch (error) {
                console.error("Error fetching current settings:", error);
            }
        };

        fetchCurrentSettings();
    }, [user?.uid, firestore]);

    return (
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Container maxWidth={false}>
            <Card sx={{ p: 4, display: "flex", flexDirection: "column", gap: 1, width: '100%' }}> 
                    <Typography variant="h1">Jamie Prototype</Typography>
                    <Typography variant="h3">Customize Inputs</Typography>
                    <Divider />
                    <Grid container spacing={2}>
                        {/* Column 1: Chat Settings */}
                        <Grid item xs={12} md={4}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <Typography variant="h6">Chat Settings</Typography>
                                <Select 
                                    value={chatType} 
                                    onChange={(e) => { setChatType(e.target.value) }}
                                    fullWidth
                                >
                                    <MenuItem value="cold">Cold start</MenuItem>
                                    <MenuItem value={"exitCase"}>From an exit Case</MenuItem>
                                </Select>
                                <Select 
                                    value={hasCobra} 
                                    onChange={(e) => { setHasCobra(e.target.value === "true") }}
                                    fullWidth
                                >
                                    <MenuItem selected={!hasCobra} value={"false"}>No Cobra Uploaded</MenuItem>
                                    <MenuItem selected={hasCobra} value={"true"}>Cobra Uploaded</MenuItem>
                                </Select>
                            </Box>
                        </Grid>

                        {/* Column 2: Event Settings */}
                        <Grid item xs={12} md={4}>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                                <Typography variant="h6">Event Settings</Typography>
                                <Select
                                    value={selectedEventType}
                                    onChange={handleEventTypeChange}
                                    fullWidth
                                >
                                    {Object.values(EventTypes).map((eventType) => (
                                        <MenuItem key={eventType} value={eventType}>
                                            {formatEventType(eventType)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Box sx={{ 
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    '& .MuiButton-root': {
                                        backgroundColor: 'rgba(144, 238, 144, 0.1)',
                                        border: '1px solid rgba(76, 175, 80, 0.3)',
                                        color: '#2E7D32',
                                        marginBottom: 1,
                                        flex: 1,
                                        '&:hover': {
                                            backgroundColor: '#4CAF50',
                                            color: '#FFFFFF',
                                            border: '1px solid #4CAF50'
                                        }
                                    }
                                }}>
                                    <CreateBenefitPlans firestore={firestore} />
                                    <WhenBenefitCreator 
                                        onBenefitCreated={(newBenefit) => {
                                            setCurrentSettings(prev => ({
                                                ...prev,
                                                whenBenefits: [...prev.whenBenefits, newBenefit]
                                            }));
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>

                        {/* Column 3: Reset Options */}
                        <Grid item xs={12} md={4}>
                            <Box sx={{ 
                                display: "flex", 
                                flexDirection: "column", 
                                gap: 2,
                                '& .MuiButton-root': {
                                    backgroundColor: 'rgba(144, 238, 144, 0.1)', // Light pastel green background
                                    border: '1px solid rgba(76, 175, 80, 0.3)', // Light green outline
                                    color: '#4CAF50', // Green text
                                    marginBottom: 0,
                                    '&:hover': {
                                        backgroundColor: '#4CAF50', // Solid green on hover
                                        color: '#FFFFFF', // White text on hover
                                        border: '1px solid #4CAF50' // Matching border on hover
                                    },
                                    '&.MuiButton-containedError': {
                                        backgroundColor: 'rgba(255, 99, 71, 0.1)', // Light pastel red background
                                        border: '1px solid rgba(244, 67, 54, 0.3)', // Light red outline
                                        color: '#F44336', // Red text
                                        '&:hover': {
                                            backgroundColor: '#F44336', // Solid red on hover
                                            color: '#FFFFFF', // White text on hover
                                            border: '1px solid #F44336' // Matching border on hover
                                        }
                                    }
                                }
                            }}>
                                <Typography variant="h6">Reset Options</Typography>
                                <ResetInsuranceButton user={user ?? null} firestore={firestore} />
                                <ResetSelectedPlanButton 
                                    user={user ?? null} 
                                    firestore={firestore} 
                                    onReset={() => setCurrentSettings(prev => ({
                                        ...prev,
                                        selectedPlan: null
                                    }))}
                                />
                                <Button 
                                    variant="contained" 
                                    color="error"
                                    onClick={handleDeleteWhenBenefits}
                                    fullWidth
                                >
                                    Delete When Benefits
                                </Button>
                            </Box>
                        </Grid>

                        {/* Column 4: Current Settings */}
                        <Grid item xs={12}>
                            <Box sx={{ 
                                display: "flex", 
                                flexDirection: "column", 
                                gap: 2,
                                mt: 4,
                                backgroundColor: 'grey.100',
                                p: 2,
                                borderRadius: 1
                            }}>
                                <Typography variant="h6">Current Settings</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography variant="body2">Chat Type: {chatType}</Typography>
                                            <Typography variant="body2">COBRA Status: {hasCobra ? 'Uploaded' : 'Not Uploaded'}</Typography>
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography variant="body2">Selected Event: {formatEventType(selectedEventType)}</Typography>
                                            <Typography variant="body2">When Benefits: {currentSettings.whenBenefits.length > 0 
                                                ? `${currentSettings.whenBenefits.length} configured ($${currentSettings.whenBenefits[0]?.maxBenefit ?? 0} total, $${currentSettings.whenBenefits[0]?.remainingBenefit ?? 0} remaining)`
                                                : '0 configured'}</Typography>
                                            <Typography variant="body2">Selected Benefit Plan: {currentSettings.benefitPlan || 'None'}</Typography>
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography variant="body2">Data Collected: {currentSettings.insuranceStatus ? 'Yes' : 'No'}</Typography>
                                            <Typography variant="body2">Effective Date: {currentSettings.effectiveDate || 'Not set'}</Typography>
                                        </Box>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <Typography variant="body2">Selected Plan: {currentSettings.selectedPlan ? 'Yes' : 'No'}</Typography>
                                            {currentSettings.selectedPlan && (
                                                <>
                                                    <Typography variant="body2">
                                                        Plan ID: {currentSettings.selectedPlan.id}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        Plan Name: {currentSettings.selectedPlan.name}
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
            <Box sx={{ backgroundColor: "background.default", py: 4, width: '100%' }} id="jamie">
                <Container maxWidth={false}> 
                    <Box sx={{ backgroundColor: "white.main", borderRadius: "12px", display: "flex", flexDirection: "column", height: "80vh" }}>
                        <Box sx={{ backgroundColor: "beige.main", borderRadius: "12px 12px 0px 0px", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", alignItems: "center", padding: "19px 16px", gap: "10px" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "21px", ml: { xs: "0px", sm: "54px" } }}>
                                <JamieImage width="40px" height="40px"></JamieImage>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "3px" }}>
                                    <Typography color={"primary"}>Jamie, Health Insurance Advocate</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <Box sx={{ backgroundColor: "#26BE00", borderRadius: "100%", width: "8px", height: "8px" }}></Box>
                                        <Typography fontSize="11px">Online</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Button onClick={handleClickHelpfulInfo} disableRipple sx={{ backgroundColor: "primary.main", borderRadius: "12px", padding: "6px 20px", display: "flex", maxWidth: "277px", width: "100%", alignItems: "center", justifyContent: "space-between", "&:hover": { backgroundColor: "primary.main" } }}>
                                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                    <InfoIcon width="16px"></InfoIcon>
                                    <Typography color={"white.main"} sx={{ pl: "10px" }}>Helpful <Box component={"span"} fontWeight={600}>Information</Box></Typography>
                                </Box>
                                <KeyboardArrowDown color="secondary"></KeyboardArrowDown>
                            </Button>
                            <Menu id="basic-menu" anchorEl={helpfulInfoAnchorEl} open={helpfulInfoOpen} onClose={handleCloseHelpfulInfo} MenuListProps={{ 'aria-labelledby': 'basic-button', sx: { py: 0 } }} TransitionComponent={Fade} PaperProps={{ sx: { borderRadius: "0px 0px 12px 12px", mt: "-10px" } }}>
                                <Box sx={{ backgroundColor: "primary.main", px: "20px", py: "24px", maxWidth: "277px", display: "flex", flexDirection: "column", gap: 2 }}>
                                    <Typography color="white.main">If you are worried about sharing your information with Jamie please <Link href="/privacy" fontWeight={600} color={"white.main"}>review our privacy policy</Link></Typography>
                                </Box>
                            </Menu>
                        </Box>
                        <Box sx={{ flex: 1, overflowY: "scroll", display: "flex", flexDirection: "column-reverse", gap: "14px", px: { xs: "12px", sm: "140px" }, py: "20px" }}>
                            {loading && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "60px", padding: "10px 15px", backgroundColor: "#F1F1F1", borderRadius: "17px 17px 17px 2px", width: "fit-content", maxWidth: { xs: "100%", sm: "50%" } }}>
                                <CircularProgress color="primary" />
                            </Box>}
                            {messageStream && messageStream.length > 0 &&
                                <Box sx={{ display: "block", justifyContent: "flex-start", alignItems: "center", gap: "10px", marginRight: "auto", backgroundColor: "#F1F1F1", color: "black.main", p: 4, maxWidth: { xs: "100%", sm: "75%" }, borderRadius: "17px 17px 17px 2px" }}>
                                    <Markdown remarkPlugins={[remarkGfm]}>
                                        {messageStream.join("")}
                                    </Markdown>
                                </Box>
                            }
                            {filteredMessageThread.map((message, index) => {
                                const isLastMessage = index === filteredMessageThread.length - 1;
                                if (message.type === "dynamic") {
                                    return (
                                        <DynamicTemplate 
                                            template={message.dynamicTemplate}
                                            data={message.data}
                                            key={message.id}
                                            sendPrompt={sendPrompt}
                                            userData={userData}
                                            setCurrentSettings={setCurrentSettings}
                                        />
                                    );
                                }
                                return (
                                    <Box key={message.id}>
                                        <Box sx={{
                                            display: "block",
                                            justifyContent: message.type === "human" ? "flex-end" : "flex-start",
                                            alignItems: "center",
                                            gap: "10px",
                                            marginLeft: message.type === "human" ? "auto" : "initial",
                                            marginRight: message.type === "human" ? "initial" : "auto",
                                            backgroundColor: message.type === "human" ? "primary.main" : "#F1F1F1",
                                            color: message.type === "human" ? "white.main" : "black.main",
                                            p: 4,
                                            maxWidth: { xs: "100%", sm: "75%" },
                                            borderRadius: message.type === "human" ? "17px 17px 2px 17px" : "17px 17px 17px 2px",
                                            position: "relative",
                                            padding: message.type === "human" ? "2px 17px 2px 17px" : "3px 20px 30px 20px"
                                        }}>
                                            {message.id === 'initial-message' ? (
                                                <TypedMessage 
                                                    text={message.text}
                                                    speed={15}
                                                    finished={() => {}}
                                                    className=""
                                                    testId="initial-message"
                                                    isInitialMessage={!isLoading && message.id === 'initial-message' && !isExistingConversation}
                                                    messageThreadLength={filteredMessageThread.length}
                                                    onAnimationComplete={() => setIsInitialMessageAnimating(false)}
                                                />
                                            ) : (
                                                <Markdown remarkPlugins={[remarkGfm]}>
                                                    {message.text}
                                                </Markdown>
                                            )}
                                            {message.type !== "human" && (
                                                <Box sx={{ position: "absolute", bottom: "10px", right: "10px", display: "flex", gap: "5px", paddingTop: { xs: "30px", sm: "30px" } }}>
                                                    <IconButton color="success" onClick={() => handleFeedback(message.id, "positive")}>
                                                        <ThumbUpAlt fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "positive" ? 0.4 : 0.2 }} />
                                                    </IconButton>
                                                    <IconButton color="success" onClick={() => handleFeedback(message.id, "negative")}>
                                                        <ThumbDownAlt fontSize="small" sx={{ color: "green", opacity: feedback[message.id] === "negative" ? 0.4 : 0.2 }} />
                                                    </IconButton>
                                                </Box>
                                            )}
                                        </Box>
                                        {message.type !== "human" && feedbackVisible[message.id] && !isTyping && isLastMessage && (
                                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", marginRight: "auto", backgroundColor: "#F1F1F1", color: "black.main", p: 4, maxWidth: { xs: "100%", sm: "75%" }, borderRadius: "17px 17px 17px 2px", mt: 2 }}>
                                                {showThankYou ? (
                                                    <Typography variant="h6" sx={{ mt: 2, alignContent: "center", paddingBottom: "1em" }}>Thank you for your feedback!</Typography>
                                                ) : sentimentVisible ? (
                                                    <>
                                                        <Typography fontSize="sm">How was your experience?</Typography>
                                                        <Box sx={{ display: "flex", gap: "5px" }}>
                                                            <IconButton onClick={() => handleSentimentClick("veryDissatisfied")}>
                                                                <SentimentVeryDissatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "veryDissatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("dissatisfied")}>
                                                                <SentimentDissatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "dissatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("neutral")}>
                                                                <SentimentSatisfiedAlt fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "neutral" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("satisfied")}>
                                                                <SentimentSatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "satisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleSentimentClick("verySatisfied")}>
                                                                <SentimentVerySatisfied fontSize="small" sx={{ color: "gray", opacity: selectedSentiment === "verySatisfied" ? 0.4 : 0.2 }} />
                                                            </IconButton>
                                                        </Box>
                                                        {selectedSentiment && (
                                                            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px", mt: 2 }}>
                                                                <TextField
                                                                    label="Additional Feedback"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    multiline
                                                                    rows={3}
                                                                    value={additionalFeedback}
                                                                    onChange={(e) => setAdditionalFeedback(e.target.value)}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={handleSubmitFeedback}
                                                                >
                                                                    Submit Feedback
                                                                </Button>
                                                                {(selectedSentiment === "dissatisfied" || selectedSentiment === "veryDissatisfied") && (
                                                                    <>
                                                                        <Typography sx={{ fontSize: "small", mt: 2, alignContent: "center", paddingBottom: "1em" }}>Can a When agent contact you about your experience?</Typography>
                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                                                                            <RadioGroup row value={canContact ? 'yes' : 'no'} onChange={handleContactChange}>
                                                                                <FormControlLabel
                                                                                    value="yes"
                                                                                    control={<Radio />}
                                                                                    label={<Typography sx={{ fontSize: '0.875rem' }}>Yes</Typography>}
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="no"
                                                                                    control={<Radio />}
                                                                                    label={<Typography sx={{ fontSize: '0.875rem' }}>No</Typography>}
                                                                                />
                                                                            </RadioGroup>
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </Box>
                                                        )}
                                                        <Button size="small" onClick={() => resetFeedbackTimer(message.id)}>I am still here</Button>
                                                    </>
                                                ) : null}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            }).reverse()}
                        </Box>
                        <Box sx={{ mt: "auto", backgroundColor: "white", p: 2 }}>
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                                {showAskQuestionButtons && !isLoading && (
                                    <Box sx={{ display: "flex", flexDirection: "row", justify: "center", gap: "10px" }}>
                                        {questions.slice(currentQuestionIndex, currentQuestionIndex + 3).map((question, index) => (
                                            <Button 
                                                key={index} 
                                                onClick={() => askQuestion(question)} 
                                                variant="contained" 
                                                disabled={isInitialMessageAnimating}
                                                sx={{ 
                                                    padding: "10px 20px 10px 20px", 
                                                    borderRadius: "20px", 
                                                    margin: "10px", 
                                                    bgcolor: 'primary.light', 
                                                    borderColor: 'primary',
                                                    opacity: isInitialMessageAnimating ? 0.5 : 1
                                                }}
                                            >
                                                {question}
                                            </Button>
                                        ))}
                                    </Box>
                                )}
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", width: "100%" }}>
                                    <TextField
                                        value={userMessage}
                                        disabled={isInitialMessageAnimating}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !isInitialMessageAnimating) {
                                                send();
                                            }
                                        }}
                                        onChange={(e) => {
                                            setUserMessage(e.target.value);
                                            setIsTyping(true);
                                        }}
                                        sx={{ 
                                            backgroundColor: "white.main", 
                                            borderRadius: "0px 0px 12px 12px", 
                                            padding: "10px 10px", 
                                            margin: ".2em", 
                                            width: "100%", 
                                            maxWidth: { xs: "100%", sm: "100%" },
                                            '& .Mui-disabled': {
                                                backgroundColor: '#f5f5f5',
                                                cursor: 'not-allowed'
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                padding: 10
                                            }
                                        }}
                                    />
                                    <Button 
                                        onClick={send} 
                                        variant="contained" 
                                        disabled={isInitialMessageAnimating}
                                        sx={{ 
                                            padding: "5px 5px 5px 5px", 
                                            margin: "10px", 
                                            bgcolor: 'primary.light',
                                            '&.Mui-disabled': {
                                                backgroundColor: '#e0e0e0',
                                                color: '#9e9e9e'
                                            }
                                        }}
                                    >
                                        Send
                                    </Button>
                                    <Button 
                                        onClick={toggleAskQuestionButtons} 
                                        variant="contained" 
                                        disabled={isInitialMessageAnimating}
                                        sx={{ 
                                            padding: "5px 0px 5px 0px", 
                                            margin: "20px", 
                                            maxWidth: "50%", 
                                            bgColor: 'primary.light',
                                            '&.Mui-disabled': {
                                                backgroundColor: '#e0e0e0',
                                                color: '#9e9e9e'
                                            }
                                        }}
                                    >
                                        {showAskQuestionButtons ? "?" : "?"}
                                    </Button>
                                    {showAskQuestionButtons ? (
                                        <Button 
                                            onClick={refreshQuestions} 
                                            variant="contained" 
                                            disabled={isInitialMessageAnimating}
                                            sx={{ 
                                                padding: "5px 0px 5px 0px", 
                                                margin: "10px", 
                                                width: "90px", 
                                                bgColor: "primary.light",
                                                '&.Mui-disabled': {
                                                    backgroundColor: '#e0e0e0',
                                                    color: '#9e9e9e'
                                                }
                                            }} 
                                        >
                                            <Cached />
                                        </Button>
                                    ) : (
                                        <Box sx={{ width: "90px", height: "40px", margin: "10px" }}></Box> // Placeholder for cache button
                                    )}
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                                    <CancelButton cancel={cancel} disabled={isInitialMessageAnimating} />
                                    <ClearButton clear={clear} disabled={isInitialMessageAnimating} />
                                    <Button
                                        onClick={() => {
                                            setSelectedSentiment(null);
                                            setAdditionalFeedback("");
                                            setFeedbackSubmitted(false);
                                            setSentimentVisible(true);
                                            setShowAllFeedback(true);
                                            setIsTyping(false);
                                            messageThread.forEach((message) => {
                                                handleFeedbackVisibility(message.id);
                                            });
                                        }}
                                        color="primary"
                                        variant="contained"
                                        disabled={isInitialMessageAnimating}
                                        sx={{ 
                                            padding: "5px 10px 5px 10px", 
                                            margin: "10px", 
                                            bgcolor: 'primary.light',
                                            '&.Mui-disabled': {
                                                backgroundColor: '#e0e0e0',
                                                color: '#9e9e9e'
                                            }
                                        }}
                                    >
                                        Submit Feedback
                                    </Button>
                                    <ResetChatButton 
                                        user={user?.uid ? { uid: user.uid } as User : null}
                                        firestore={firestore}
                                        chatType={chatType}
                                        hasCobra={hasCobra}
                                        selectedEventType={selectedEventType}
                                        clear={clear}
                                        setConversationId={setConversationId}
                                        setIsNewConversation={setIsNewConversation}
                                        setMessageThread={setMessageThread}
                                        setFeedback={setFeedback}
                                        setAdditionalFeedback={setAdditionalFeedback}
                                        setSelectedSentiment={setSelectedSentiment}
                                        setFeedbackSubmitted={setFeedbackSubmitted}
                                        setSentimentVisible={setSentimentVisible}
                                        setShowAllFeedback={setShowAllFeedback}
                                        setIsResetting={setIsResetting}
                                        disabled={isInitialMessageAnimating}
                                        onReset={handleResetChat}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

function DynamicTemplate({ 
    template, 
    data, 
    sendPrompt, 
    userData,
    setCurrentSettings
}: { 
    template: string, 
    data: any, 
    sendPrompt: (message: string) => void,
    userData: any,
    setCurrentSettings: React.Dispatch<React.SetStateAction<{
        insuranceStatus: boolean | null;
        effectiveDate: string | null;
        selectedPlan: any;
        whenBenefits: any[];
        benefitPlan: string | null;
    }>>
}) {
    if (template === "showRecommendedPlans") {
        return (
            <Box sx={{ 
                maxWidth: { xs: "100%", sm: "75%" },
                marginRight: "auto"
            }}>
                <Recommendations 
                    sendPrompt={sendPrompt} 
                    conversationId={userData?.get("conversationId")} 
                    onPlanSelect={(planDetails) => {
                        console.log("Plan selected:", planDetails);
                        setCurrentSettings(prev => ({
                            ...prev,
                            selectedPlan: planDetails,
                        }));
                    }}
                />
            </Box>
        )
    }
    if (template === "resumeViewer") {
        return (
            <Box sx={{ 
                maxWidth: { xs: "100%", sm: "75%" },
                marginRight: "auto"
            }}>
                <Card sx={{ p: 2, backgroundColor: "#DDD" }}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {data.content}
                    </Markdown>
                </Card>
            </Box>
        )
    }
    if (template === "uploadResume") {
        return (
            <Box>
                <UploadResume />
            </Box>
        )
    }
    if (template === "enrollInSelectedPlan") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                borderRadius: "17px 17px 2px 17px",
            }}>
                <EnrollNow />
            </Box>
        );
    }
    if (template === "showEnrollmentInformation" || template === "updateEnrollmentInformation") {
        return (
            <EnrollmentInformation 
                sendPrompt={sendPrompt} 
                setCurrentSettings={setCurrentSettings}
            />
        );
    }
    if (template === "updatePreferences") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                width: "100%",
                borderRadius: "17px 17px 2px 17px",
            }}>
                <PreferencesTemplate sendPrompt={sendPrompt} />
            </Box>
        );
    }
    if (template === "human") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                width: "100%",
                borderRadius: "17px 17px 2px 17px",
            }}>
                <HumanHandoff sendPrompt={sendPrompt} />
            </Box>
        );
    }
    return (
        <Box sx={{ 
            maxWidth: { xs: "100%", sm: "75%" },
            marginRight: "auto"
        }}>
            <Typography>Missing template: {template}</Typography>
        </Box>
    );
}
export default JamiePrototype;