
import { SeveranceBenefit, SeveranceBenefitStructure, SeveranceBenefitType } from "@when-insurance/interfaces/dist/Company";
import { BenefitTier } from "@when-insurance/interfaces";
import { IEmployee } from "@when-insurance/interfaces/dist/Employee";

export * from "@when-insurance/interfaces/dist/Activity";
export * from "@when-insurance/interfaces/dist/Application";
// export * from "@when-insurance/interfaces/dist/Case";
export * from "@when-insurance/interfaces/dist/Company";
export * from "@when-insurance/interfaces/dist/DataPipeline";
export * from "@when-insurance/interfaces/dist/Employee";
export * from "@when-insurance/interfaces/dist/HealthInsurance";
export * from "@when-insurance/interfaces/dist/Hipaa";
export * from "@when-insurance/interfaces/dist/Sales";
export * from "@when-insurance/interfaces/dist/ServiceMarketplace";
// export * from "@when-insurance/interfaces/dist/SeverancePackage";
export * from "@when-insurance/interfaces/dist/User";
export * from "@when-insurance/interfaces/dist/WhenBenefit";
export * from "@when-insurance/interfaces/dist/";

export type Employee = IEmployee;
export interface ICompanyPrivateSetting {
  billCustomerId: string;
  invoice: {
    interval: string;
    preference?: string[];
  };
  fees: {
    subscription: number;
    whenBenefit: number;
  };
  automation: {
    employerPlatformLessWorkflowEnable: boolean;
    numberOfDays: number;
    errorNotificationContactEmail: string;
  };
  whenBenefitUseOnCobra: boolean;
  integrationStartDate: string;
}

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export const generateDescription = (benefit: SeveranceBenefit, benefitTier: string | undefined): string => {
  let benefitDescription = "";
  // console.log('where is',benefit)
  if (!benefit.calculation) {
    return ""
  }

  if (benefit.type === SeveranceBenefitType.PAY) {
    // Description for SeveranceBenefitType.PAY
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in severance pay.`;
    } else if (benefit.structure === SeveranceBenefitStructure.FIXED_PERIODS) {
      benefitDescription = `This benefit configuration provides severance pay in the amount of ${benefit.calculation.percent}% of regular pay in severance for ${benefit.calculation.periods} ${benefit.calculation.periodMeasure}.`;
    } else if (benefit.structure === SeveranceBenefitStructure.CUSTOM_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% of regular pay for each year of service rounded ${benefit.calculation.round}.`;
    }
  } else if (benefit.type === SeveranceBenefitType.CASH) {
    // Description for SeveranceBenefitType.CASH
    benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in cash.`;
  } else if (benefit.type === SeveranceBenefitType.COBRA) {
    // Description for SeveranceBenefitType.COBRA
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      benefitDescription = `This benefit configuration provides a flat dollar amount of $${benefit.calculation.flatAmount} dollars in COBRA subsidy.`;
    } else if (benefit.structure === SeveranceBenefitStructure.FIXED_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% in COBRA premium for ${benefit.calculation.periods} months.`;
    } else if (benefit.structure === SeveranceBenefitStructure.CUSTOM_PERIODS) {
      benefitDescription = `This benefit configuration provides ${benefit.calculation.percent}% in monthly COBRA premium for each year of service rounded ${benefit.calculation.round}.`;
    }
  } else if (benefit.type === SeveranceBenefitType.MANAGED_HEALTH) {
    // Description for SeveranceBenefitType.CASH
    if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT_BY_COVERAGE) {
      const individualAmount: number | undefined = benefit?.calculation?.individualAmount;
      const formattedIndividualAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(individualAmount ?? 0);
      const familyAmount: number | undefined = benefit?.calculation?.familyAmount;
      const formattedFamilyAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(familyAmount ?? 0)
      benefitDescription = `This benefit configuration provides a flat dollar amount of Health Insurance Reimbursement in the amount of ${formattedIndividualAmount} for single employees and ${formattedFamilyAmount} for employees with families.`;
    }
    else if (benefit.structure === SeveranceBenefitStructure.FLAT_AMOUNT) {
      let amount;
      if (benefitTier === BenefitTier.individual) {
        amount = benefit?.calculation?.individualAmount
      } else if (benefitTier === BenefitTier.family) {
        amount = benefit?.calculation?.familyAmount
      } else {
        amount = benefit?.calculation?.flatAmount
      }
      const formattedAmount: string = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(amount ?? 0);
      benefitDescription = `This benefit configuration provides a flat dollar amount of  ${formattedAmount} in health insurance reimbursements`;
    }
  }

  return benefitDescription;
};

export function formatPhoneNumber(phoneNumber: any) {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const formattedNumber = digitsOnly.replace(/^(\d{1})(\d{3})(\d{3})(\d{4})$/, '+$1 $2 $3 $4');
  return formattedNumber;
}