/** @jsxImportSource @emotion/react */
import { Box, TextField, Button, Typography, Grid, Card, Container, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { useAuth } from "../contexts/User";
import { useLocation, useNavigate } from "react-router-dom";
import OtpVerificationDialog from "components/User/loginOtpVerifyDialog";
import { mixpanelService as mixpanel } from 'services/tracking';

function SSO(props: any) {
  let navigate = useNavigate();
  const { authed, loginSSO, loading, generateOtpforSMSmfa } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [mfaError, setMfaError] = useState<string>("");
  const [verificationId, setVerificationId] = useState<string>("");
  const [openOtpVerificationDialog, setOpenOtpVerificationDialog] = useState<boolean>(false);
  const [loginLoading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  const onEmailChange = (e: any) => setEmail(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    if (email === "") {
      setError("Please enter your email");
      return;
    }
    setLoading(true);
    loginSSO(email)
      .then((result) => {
        if (result !== null) {
          setLoading(false);
          navigate("/account/dashboard");
        }
      }).catch((error) => {
        const errorCode = error.code;
        if (errorCode === "auth/multi-factor-auth-required") {
          setError("")
          generateOtpforSMSmfa(error)
            .then((result) => {
              if (result !== null && result !== undefined) {
                setVerificationId(result);
                setOpenOtpVerificationDialog(true);
                setMfaError(error)
              }
            })
            .catch((err) => {
              setError("An error occurred. Please try again later.");
            });

        } else {
          console.error("sso login error", error);
          setError(error.message);
          setLoading(false);
        }
      });
  }
  interface CustomizedState {
    path: string
  }
  function defaultAfterLogin() {
    mixpanel.track("Login");
    //go to dashboard
    const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
    console.log("login path redirect", state?.path);
    navigate(state?.path || "/account/dashboard");
  }
  useEffect(() => {
    if (authed) {
      navigate("/account/dashboard");
    }
  }, [authed, navigate]);
  if (loading) {
    return (
      <Box></Box>
    );
  }
  if (authed) {
    return (
      <Box sx={{
        display: authed ? "flex" : "none",
      }}>You are already logged in</Box>
    );
  }
  return (
    <Container id="loginForm" maxWidth="lg" sx={{
      textAlign: "center",
      justifyContent: "center",
      pb: { xs: 4 }
    }}>
      <Typography variant="body2">Protecting Your Access to Care</Typography>
      <Typography variant="h4" sx={{
        paddingBottom: "2rem",
      }}>Sign in to your When account</Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={5}>
          <Card color="primary" sx={{
            backgroundColor: "primary.main",
            color: "white.main",
            textAlign: "left",
          }}>
            <CardHeader title="Don't Have an Account?"></CardHeader>
            <CardContent sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3
            }}>
              <Typography color="white.main" variant="body1">Jamie, When’s Health Insurance Advocate, is built to find health insurance policies for people in every stage of their lives. Get started with Jamie and we will help match you with the right plan, or create your When account now!</Typography>
              <Button id="getStarted" variant="contained" color="secondary" onClick={() => { navigate("/jamie") }}>Get Started with Jamie</Button>
              {/* <br /><br />
              <Typography color="white.main" variant="body1">If you enrolled via your employer, click the link below to locate your employer policy and create your account.</Typography>
              <br />
              <Link underline="none" color="secondary" href="/lookup">Find My Employer Policy</Link> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: authed ? "none" : "flex",
              flexDirection: "column",
              gap: "1rem",
            }}>
              <TextField data-testid="emailTextField" id="email" color="secondary" sx={{ borderRadius: "10px" }} label="Email Address" onChange={onEmailChange} value={email} required></TextField>
              <Typography variant="caption" data-testid="signErrorMessage" color="error" >{error}</Typography>


              <Button variant="contained"
                data-testid="loginButton"
                id="loginButton"
                type="submit"
                sx={{
                  maxWidth: { sm: "220px" }
                }}
                color="secondary"
                disabled={loginLoading}>Sign In</Button>
            </Box>
          </form>
        </Grid>
      </Grid>
      {(verificationId !== "") && openOtpVerificationDialog &&
        <OtpVerificationDialog
          isOpen={openOtpVerificationDialog}
          onClose={() => { setOpenOtpVerificationDialog(false) }}
          verificationId={verificationId}
          mfaError={mfaError}
          afterLogin={defaultAfterLogin} />}
    </Container>
  );
}
export default SSO;