/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Container,
  Divider,
  Dialog,
} from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  collection,
  orderBy,
  query,
  getDoc,
  setDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ViewDetailsModalQuotit from "components/HealthPlan/ViewDetailsModalQuotit";
import PlanOptionsJamieAI from "./PlanOptionsJamieAI";
// import SelectPlanModalQuotit from "components/HealthPlan/SelectPlanModalQuotit";
import PropTypes from "prop-types";
import BenefitPlanDetailsDialog from "./BenefitPlanDetailsDialog";

interface RecommendationsProps {
  sendPrompt: (message: string) => void;
  conversationId?: string;
  onPlanSelect?: (planDetails: any) => void;
}

interface PremiumTier {
  employeeContribution: number;
  employerContribution: number;
  totalPremium: number;
}

interface FourTierPremium {
  employeeOnly: PremiumTier;
  employeePlusChildren: PremiumTier;
  employeePlusSpouse: PremiumTier;
  family: PremiumTier;
}

interface BenefitPlan {
  id: string;
  name: string;
  carrier: string;
  individualDeductible: number;
  familyDeductible: number;
  individualOutOfPocketMaximum: number;
  familyOutOfPocketMaximum: number;
  fourTierPremium: FourTierPremium;
  coverageTierStructure: string;
}

interface CurrentBenefitPlanProps {
  conversationId?: string;
}

// Add interface for message structure
interface ConversationMessage {
  data?: {
    selectedBenefitPlan?: {
      id: string;
      name: string;
      carrier: string;
      planNetworkType: string;
      individualDeductible: number;
      familyDeductible: number;
      individualOutOfPocketMaximum: number;
      familyOutOfPocketMaximum: number;
    };
  };
}

// Add new component for current benefit plan display
const CurrentBenefitPlan: React.FC<CurrentBenefitPlanProps> = React.memo(
  ({ conversationId }) => {
    const { firestore } = useFirebase();
    const { user } = useAuth();
    const [benefitPlan, setBenefitPlan] = useState<any>(null);
    const [planDetailsOpen, setPlanDetailsOpen] = useState(false);

    useEffect(() => {
      const fetchBenefitPlan = async () => {
        if (!user?.uid) return;

        try {
          const chatSettingsRef = doc(
            firestore,
            `users/${user.uid}/settings/chat`,
          );
          const chatSettingsSnapshot = await getDoc(chatSettingsRef);

          if (chatSettingsSnapshot.exists()) {
            const chatSettings = chatSettingsSnapshot.data();
            if (chatSettings.selectedBenefitPlan) {
              console.log(
                "Found benefit plan:",
                chatSettings.selectedBenefitPlan,
              );
              setBenefitPlan(chatSettings.selectedBenefitPlan);
            }
          }
        } catch (error) {
          console.error("Error fetching benefit plan:", error);
        }
      };

      fetchBenefitPlan();
    }, [user?.uid, firestore]);

    if (!benefitPlan) return null;

    return (
      <>
        <Box
          sx={{
            borderRadius: "10px",
            overflow: "hidden",
            width: "95%",
          }}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: "#F2EDE7",
              display: "flex",
              flexDirection: "column",
              height: { xs: 110, sm: 150 },
              marginTop: { xs: 1, sm: 0 },
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                  mb: 1,
                }}
              >
                {benefitPlan.name}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                }}
              >
                {benefitPlan.planNetworkType}
              </Typography>
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 120,
                  borderBottom: "2px solid #F2EDE7",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                >
                  Monthly Premium
                </Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>
                  $
                  {benefitPlan.fourTierPremium?.employeeOnly?.totalPremium?.toLocaleString() ||
                    "0"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your Contribution: $
                  {benefitPlan.fourTierPremium?.employeeOnly?.employeeContribution?.toLocaleString() ||
                    "0"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  height: 120,
                  borderBottom: "2px solid #F2EDE7",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                >
                  Insurer
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    mt: 1,
                  }}
                >
                  {benefitPlan.carrier}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 1,
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 80,
                  borderBottom: "2px solid #F2EDE7",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                >
                  Deductible
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  ${benefitPlan.individualDeductible?.toLocaleString()}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 1,
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 80,
                  borderBottom: "2px solid #F2EDE7",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                >
                  Max Out of Pocket
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  ${benefitPlan.individualOutOfPocketMaximum?.toLocaleString()}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              p: 1,
              backgroundColor: "#FFFFFF",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Button variant="contained" color="primary" fullWidth disabled>
              Current Plan
            </Button>
            <Button
              variant="text"
              color="primary"
              fullWidth
              onClick={() => setPlanDetailsOpen(true)}
              sx={{
                textDecoration: "underline",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              View Plan Details
            </Button>
          </Box>
        </Box>

        <BenefitPlanDetailsDialog
          open={planDetailsOpen}
          onClose={() => setPlanDetailsOpen(false)}
          selectedPlan={benefitPlan.id}
          onConfirm={() => setPlanDetailsOpen(false)}
          benefitPlans={[benefitPlan]}
          household={null}
        />
      </>
    );
  },
);

// Add prop types validation
CurrentBenefitPlan.propTypes = {
  conversationId: PropTypes.string,
};

CurrentBenefitPlan.displayName = "CurrentBenefitPlan";

const Recommendations: React.FC<RecommendationsProps> = ({
  sendPrompt,
  conversationId,
  onPlanSelect
}) => {
  const { firestore } = useFirebase();
  const { user, answers, userData } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedPlanForModal, setSelectedPlanForModal] =
    React.useState<any>(null);
  const [planDetailsOpen, setPlanDetailsOpen] = React.useState<boolean>(false);
  const [selectPlanOpen, setSelectPlanOpen] = React.useState<boolean>(false);
  const [imageError, setImageError] = React.useState<boolean>(false);

  const [loadRates, rateQuery] = useLazyQuery<
    { total: number },
    { effectiveDate: string }
  >(Queries.getRates, { notifyOnNetworkStatusChange: true });

  const handleImageError = () => {
    setImageError(true);
  };

  const handlePlanSelection = async (planId: string, planDetails: any) => {
    if (!user?.uid || !conversationId) {
      console.error("User is not authenticated or conversation ID is missing");
      return;
    }

    try {
      await setDoc(
        doc(firestore, "users", user.uid, "settings", "chat"),
        {
          selectedPlan: planId,
          selectedPlanDetails: planDetails,
        },
        { merge: true },
      );

      const convoRef = doc(
        firestore,
        `users/${user.uid}/convos/${conversationId}`,
      );
      const convoSnapshot = await getDoc(convoRef);

      if (convoSnapshot.exists()) {
        const convoData = convoSnapshot.data();
        const messages = convoData.messages || [];

        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          lastMessage.selectedPlanId = planId;

          await updateDoc(convoRef, {
            messages: messages,
          });
        }
      }
    } catch (error) {
      console.error("Error updating selected plan:", error);
    }

    sendPrompt("I have selected a plan and I would like to enroll.");
  };

  const determinePremiumTier = useCallback((members: any) => {
    const memberCount = Object.keys(members || {}).length;
    const hasSpouse = Object.values(members || {}).some(
      (member: any) => member.relationship === "spouse",
    );
    const hasChildren = Object.values(members || {}).some(
      (member: any) => member.relationship === "child",
    );

    if (memberCount === 1) return "employeeOnly";
    if (hasSpouse && hasChildren) return "family";
    if (hasSpouse) return "employeePlusSpouse";
    if (hasChildren) return "employeePlusChildren";
    return "employeeOnly";
  }, []);

  const capitalizeFirstLetter = (string: string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const [plans, plansLoading, plansError] =
    useCollection<QuotitHealthPlanDetails>(
      query<QuotitHealthPlanDetails>(
        collection(
          firestore,
          `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`,
        ).withConverter(QuotitPlanConverter),
        orderBy("recommendation.internalScore", "desc"),
      ),
    );

  if (plansLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (plansError) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography color="error">Error loading plans</Typography>
      </Box>
    );
  }

  const household = answers?.get("insuranceDetails.household.members");
  const premiumTier = determinePremiumTier(household);
  const topThreePlans = plans?.docs
    .filter((plan) => plan.get("insuranceType") === "HealthOnExchange")
    .slice(0, 3);

  return (
    <Box>
      <Box sx={{ backgroundColor: "background.default", py: 4 }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                color="primary"
                variant="h1"
                sx={{
                  fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" },
                  fontWeight: 600,
                }}
              >
                Recommended Insurance Plans
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/account/insurance-plans")}
                >
                  View All Policies
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: "brightOrange.main", my: 2 }} />
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "background.default", pb: 8 }}>
        <Container>
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={3}>
              {/* Current Benefit Plan */}
              <Grid item xs={12} md={4}>
                <CurrentBenefitPlan conversationId={conversationId} />
              </Grid>
              {/* Plan Options */}
              <Grid item xs={12} md={8}>
                <PlanOptionsJamieAI
                  effectiveDate={answers?.get("insuranceDetails.effectiveDate") || moment().format("YYYY-MM-DD")}
                  speed={3000}
                  cobraEstimate={answers?.get("insuranceDetails.cobraEstimate") || 0}
                  conversationId={conversationId}
                  sendPrompt={sendPrompt}
                  onPlanSelect={onPlanSelect}
                />
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  p: 4,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.5rem" },
                  }}
                >
                  Want to see different options?
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={async () => {
                    sendPrompt("I would like to update my preferences.");
                  }}
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "0.875rem" },
                    maxWidth: "300px",
                  }}
                >
                  Update Preferences
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  p: 4,
                  bgcolor: "background.paper",
                  borderRadius: 2,
                  boxShadow: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontSize: { xs: "1.2rem", sm: "1.3rem", md: "1.5rem" },
                  }}
                >
                  Want to explore all available plans?
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => navigate("/account/insurance-plans")}
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "0.875rem" },
                    maxWidth: "300px",
                  }}
                >
                  View All Insurance Plans
                </Button>
              </Box>
            </Grid>
          </Grid>

          {rateQuery.loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                mt: 4,
                p: 2,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                }}
              >
                Refreshing Plans
              </Typography>
              <CircularProgress size={24} />
            </Box>
          )}
        </Container>
      </Box>

      {/* Modals */}
      <Dialog
        open={planDetailsOpen}
        onClose={() => setPlanDetailsOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <ViewDetailsModalQuotit
          plan={selectedPlanForModal}
          close={() => setPlanDetailsOpen(false)}
        />
      </Dialog>

      {/* <Dialog
                        open={selectPlanOpen}
                        onClose={() => setSelectPlanOpen(false)}
                        fullWidth
                        maxWidth="lg"
                    >
                        <SelectPlanModalQuotit 
                            plan={selectedPlanForModal} 
                            close={() => setSelectPlanOpen(false)} 
                        />
                    </Dialog> */}
    </Box>
  );
};

export default Recommendations;
