import mixpanel from 'mixpanel-browser';

let mixpanelStarted = false;
export const mixpanelService = {
    init: (token: string, options: any) => {
        mixpanel.init(token, options);
        mixpanelStarted = true;
    },
    opt_out_tracking: () => {
        if (mixpanelStarted) {
            mixpanel.opt_out_tracking();
        }
    },
    clear_opt_in_out_tracking: () => {
        if (mixpanelStarted) {
            mixpanel.clear_opt_in_out_tracking();
        }
    },
    track: (event: string, properties?: any) => {
        if (mixpanelStarted) {
            mixpanel.track(event, properties);
        }
    },
    identify: (id: string) => {
        if (mixpanelStarted) {
            mixpanel.identify(id);
        }
    },
    start_session_recording: () => {
        if (mixpanelStarted) {
            mixpanel.start_session_recording();
        }
    },
    track_pageview: () => {
        if (mixpanelStarted) {
            mixpanel.track_pageview();
        }
    },
    people: {
        set: (property: string, to: any) => {
            if (mixpanelStarted) {
                mixpanel.people.set(property, to);
            }
        }
    }
}