import { Typography, Box, Button, Grid } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { WorkHistory, AttachMoney, TextSnippet, Shield, Phone, Settings } from "@mui/icons-material";
import WhenIcon from "components/common/WhenIcon";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, collectionGroup, orderBy, or, where } from "firebase/firestore";
import { Firestore } from "firebase/firestore";
import CaseActivityTab from "pages/Admin/Case/components/CaseActivityTab";
import CaseApiData from "pages/Admin/Case/components/CaseApiData";
import { CaseTabsEnum } from "pages/Admin/Case/types";
import { Case } from "services/GraphQl";
import CaseWhenBenefit from "pages/Admin/Case/components/CaseWhenBenefit";

interface CaseTabsProps {
  firestore: Firestore;
  theCase: Case;
  dataTab: CaseTabsEnum;
  setDataTab: (tab: CaseTabsEnum) => void;
  caseId: string;
}

function CaseTabs({ firestore, theCase, dataTab, setDataTab, caseId }: CaseTabsProps) {
  const [activity, ,] = useCollection(
    theCase && theCase?.syncUser?.id
      ? query(
          collectionGroup(firestore, `activity`),
          or(where("caseId", "==", theCase.id), where("userId", "==", theCase?.syncUser?.id)),
          orderBy("timestamp", "desc")
        )
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [pushApi, ,] = useCollection<any>(
    query(collection(firestore, "apiRequests/createCase/calls"), where("case", "==", caseId))
  );

  if (!theCase) {
    return <Typography>Case not found</Typography>;
  }

  return (
    <TabContext value={dataTab}>
      <Grid item xs={12} sm={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
            pb: 2,
          }}
        >
          <Button
            startIcon={<WorkHistory />}
            variant={dataTab === CaseTabsEnum.activity ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.activity);
            }}
          >
            Activity
          </Button>
          <Button
            startIcon={<WhenIcon />}
            variant={dataTab === CaseTabsEnum.benefit ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.benefit);
            }}
          >
            Benefits
          </Button>
          <Button
            startIcon={<AttachMoney />}
            variant={dataTab === CaseTabsEnum.severance ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.severance);
            }}
          >
            Severance Packages
          </Button>
          <Button
            startIcon={<TextSnippet />}
            variant={dataTab === CaseTabsEnum.documents ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.documents);
            }}
          >
            Documents
          </Button>
          <Button
            startIcon={<Shield />}
            variant={dataTab === CaseTabsEnum.insurance ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.insurance);
            }}
          >
            Insurance
          </Button>
          <Button
            startIcon={<Phone />}
            variant={dataTab === CaseTabsEnum.contact ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.contact);
            }}
          >
            Communications
          </Button>
          <Button
            startIcon={<Settings />}
            variant={dataTab === CaseTabsEnum.data ? "contained" : undefined}
            sx={{ px: 2 }}
            onClick={() => {
              setDataTab(CaseTabsEnum.data);
            }}
          >
            Data
          </Button>
        </Box>
        <TabPanel value={CaseTabsEnum.benefit} sx={{ p: 0 }}>
          <CaseWhenBenefit theCase={theCase} />
        </TabPanel>
        <TabPanel value={CaseTabsEnum.insurance} sx={{ p: 0 }}>
          <Typography>To be added</Typography>
        </TabPanel>
        <TabPanel value={CaseTabsEnum.documents} sx={{ p: 0 }}>
          <Typography>To be added</Typography>
        </TabPanel>
        <TabPanel value={CaseTabsEnum.severance} sx={{ p: 0, display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography>To be added</Typography>
        </TabPanel>
        {activity && (
          <TabPanel value={CaseTabsEnum.activity} sx={{ p: 0 }}>
            <CaseActivityTab activity={activity} firestore={firestore} />
          </TabPanel>
        )}
        {pushApi && (
          <TabPanel value={CaseTabsEnum.data} sx={{ p: 0, display: "flex", flexDirection: "column", gap: 4 }}>
            <CaseApiData theCase={theCase} apiData={pushApi} />
          </TabPanel>
        )}
      </Grid>
    </TabContext>
  );
}

export default CaseTabs;
