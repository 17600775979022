import { useDownloadURL } from "react-firebase-hooks/storage";
import { ref as storageRef } from "firebase/storage";
import { useFirebase } from "contexts/Firebase";
import { Link } from "react-router-dom";
import { Dialog, Card, Typography, Button, Box } from "@mui/material";
import AdjudicateRequest from "components/WhenBenefit/AdjudicateRequest";
import { useState } from "react";
import moment from "moment";
import { FormattedNumber } from "react-intl";
import { QueryDocumentSnapshot } from "firebase/firestore";
import {
  ReimbursementRequest,
} from "services/Interfaces";

function DownloadFile({ file }: { file: { name: string; type: string; path: string } }) {
  const { storage } = useFirebase();
  const [downloadURL, loading, error] = useDownloadURL(storageRef(storage, file.path));
  if (loading) {
    return <Typography variant="body1">{file.name}</Typography>;
  }
  if (error) {
    return (
      <Typography variant="body1">
        Error: {file.name}: {error.message}
      </Typography>
    );
  }
  if (!downloadURL) return <Typography variant="body1">{file.name}</Typography>;
  return (
    <Link to={downloadURL} target="_blank" download>
      {file.name}
    </Link>
  );
}

function RequestFiles({
  request,
  firstName,
}: {
  request: QueryDocumentSnapshot<ReimbursementRequest>;
  firstName: string;
}) {
  const [showFiles, setShowFiles] = useState(false);
  const [adjudicateRequest, setAdjudicateRequest] = useState(false);

  function modifyToOID(firstCh: any, userId: any) {
    let modifiedID = userId.slice(0, 8);

    if (modifiedID.charAt(7) === "9") {
      modifiedID = modifiedID.substring(0, 7) + "a" + modifiedID.substring(8);
    }
    if (modifiedID.charAt(7) === "z") {
      modifiedID = modifiedID.substring(0, 7) + "A" + modifiedID.substring(8);
    }
    if (modifiedID.slice(-1) === "Z") {
      const secondToLastChar = modifiedID.charAt(6);
      const incrementedChar = String.fromCharCode(secondToLastChar.charCodeAt(0) + 1);
      modifiedID = modifiedID.substring(0, 6) + incrementedChar;
    }

    return `${firstCh}.${modifiedID}`;
  }
  return (
    <Card key={request.id}>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography variant="h3">Request {request.id}</Typography>
        <Typography variant="h4">
          Coverage: {request.data().coverageStartDate?.format("MM/DD/YYYY")} -{" "}
          {request.data().coverageEndDate?.format("MM/DD/YYYY")}
        </Typography>
        <Typography>
          Amount: <FormattedNumber value={request.data().amount} style={`currency`} currency="USD" />
        </Typography>
        <Typography>Status: {request.data().status}</Typography>
        {(request.data().status === "Approved" || request.data().status === "Partially Approved") && (
          <>
            {request.data()?.approvedBy && <Typography>Approved By: {request.data()?.approvedBy}</Typography>}
            {request.data()?.approvedOn && moment(request.data()?.approvedOn).isValid() && (
              <Typography>Approved On: {request.data()?.approvedOn?.format("MM/DD/YYYY")}</Typography>
            )}
            {request.data()?.approvedAmount && (
              <Typography>
                Approved Amount:{" "}
                <FormattedNumber value={request.data().approvedAmount || -1} style={`currency`} currency="USD" />
              </Typography>
            )}
          </>
        )}
        <Typography>
          Files:{" "}
          <Button
            onClick={() => {
              setShowFiles(!showFiles);
            }}
          >
            {showFiles ? "Hide" : "Show"}
          </Button>
        </Typography>
        <Box
          sx={{
            display: showFiles ? "flex" : "none",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {request?.data().files.map((file: { name: string; type: string; path: string }) => {
            return <DownloadFile key={file.name} file={file} />;
          })}
        </Box>
        {request.data().status === "Pending" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAdjudicateRequest(true);
              }}
            >
              Review
            </Button>
          </Box>
        )}
        {request.data().status === "Additional info needed" && (
          <>
            <Button variant="contained" color="primary" onClick={() => setAdjudicateRequest(true)}>
              Make Decision
            </Button>
          </>
        )}
      </Box>
      <Dialog
        open={adjudicateRequest}
        onClose={() => {
          setAdjudicateRequest(false);
        }}
      >
        {" "}
        {/* Render AdjudicateRequest dialog */}
        <Card
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            overflowY: "scroll",
          }}
        >
          <AdjudicateRequest
            reimbursementRequest={request.data()}
            onClose={() => {
              setAdjudicateRequest(false);
            }}
            modifyToOID={modifyToOID}
            firstName={firstName}
          />
        </Card>
      </Dialog>
    </Card>
  );
}

export default RequestFiles;
