/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Button, Divider, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import PreferencesModal from "components/HealthPlan/PreferencesModal";
import { mixpanelService as mixpanel } from 'services/tracking';
import { useAuth } from "contexts/User";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { ModalContext } from "contexts/Modal";
import FindYourNextPlanQuotit from "components/HealthPlan/FindYourNextPlanQuotit";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";
import { useLazyQuery } from '@apollo/client';
import { Queries } from "services/apollo";
import moment from 'moment';
import { useRemoteConfig } from "contexts/RemoteConfig";

function RecommendedPlans() {
  const { settings } = useSettings();
  const { firestore } = useFirebase();
  const [planRefresh, setPlanRefresh] = useState<number>(0);
  const forcePlanRefresh = useRemoteConfig('forcePlanRefresh');
  const FF_ideon = useRemoteConfig("ideon").asBoolean();
  const [getRates] = useLazyQuery(Queries.getRates);

  const navigate = useNavigate();
  const { modalDispatch } = useContext(ModalContext);

  const [missingDetails, setMissingDetails] = useState<Array<string>>([]);
  const { user } = useAuth();
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading, firestoreError] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [openPreferences, setOpenPreferences] = useState<boolean>(false);

  const userDoc = user ? doc(firestore, 'users', user?.uid) : null;
  const [userData] = useDocument(userDoc);
  const isRefreshing = useRef(false);

  useEffect(() => {
    if (firestoreError) {
      console.error(firestoreError);
    }
    if (!answersLoading) {
      // check if all required data is set
      let details = []
      const members = answers?.get("insuranceDetails.household.members");
      const insuranceDetails = answers?.get("insuranceDetails");

      if (members === undefined || Object.keys(members).length === 0) {
        // need to set household
        details.push("members");
      }
      if (insuranceDetails === undefined || insuranceDetails.county === undefined || insuranceDetails.income === undefined || insuranceDetails.type === undefined) {
        details.push("details");
      }
      setMissingDetails(details);
    }
  }, [answers, answersLoading, firestoreError])
  function goto(uri: string) {
    if (!uri.includes("http")) {
      window.location.href = settings.PUBLIC_URL + uri;
    } else {
      window.location.href = uri;
    }
    return null;
  }

  useEffect(() => {
    mixpanel.track("RecommendedPlans.viewed");
  })

  useEffect(() => {
    if (user && userData && answers) {
      const effectiveDate = answers.get("insuranceDetails.effectiveDate");
      const lastPlanSearch = userData.get("lastPlanSearch");
      const forcePlanRefreshDate = moment(forcePlanRefresh._value);

      if (effectiveDate &&
        !isRefreshing.current &&
        (!lastPlanSearch ||
          !lastPlanSearch[effectiveDate] ||
          moment(lastPlanSearch[effectiveDate].toDate()).isBefore(forcePlanRefreshDate))) {

        isRefreshing.current = true;

        getRates({
          variables: {
            effectiveDate,
            source: FF_ideon ? "ideon" : "quotit"
          },
          fetchPolicy: 'network-only'
        }).finally(() => {
          isRefreshing.current = false;
        });
      }
    }
  }, [user, userData, answers, FF_ideon, getRates, forcePlanRefresh]);

  if (missingDetails.length > 0) {
    return (
      <Box>
        <Box sx={{
          backgroundColor: "background.default",
          py: 4,
        }}>
          <Container>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <Typography color={"primary"} variant="h1" >Health Insurance Recommendations</Typography>
                <Box>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: "brightOrange.main", my: 2 }}></Divider>
          </Container>
        </Box>
        <Box sx={{
          backgroundColor: "background.default",
          pb: 8
        }}>
          <Container>

            <Grid container spacing={4} columnSpacing={8} alignItems="center">
              <Grid item xs={12} sm={12} sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                gap: 4
              }}>
                <Typography variant="h2">In order to get recommendations you must first provide your details to Jamie, your Health Insurance Advocate.</Typography>
                <Button variant="contained" sx={{ px: 4 }} onClick={() => { navigate("/jamie") }}>Go to Jamie</Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box sx={{
        backgroundColor: "background.default",
        py: 4
      }}>
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <Typography color={"primary"} variant="h1" sx={{ fontSize: { xs: "1.6rem", sm: "1.8rem", ms: "2.25rem" } }}>Health Insurance Recommendations</Typography>
              <Box>
                {!user?.isAnonymous &&
                  <Button onClick={() => { goto("/account/insurance-plans") }}>View All Policies</Button>
                }
                {user?.isAnonymous &&
                  <Button onClick={() => { modalDispatch({ type: "createAccount" }); }}>Create an account to view all your options</Button>
                }
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ backgroundColor: "brightOrange.main", my: 2 }}></Divider>
        </Container>
      </Box>
      <Box sx={{
        backgroundColor: "background.default",
        pb: 8
      }}>
        <Container>
          <Grid container spacing={4} columnSpacing={8} alignItems="center">
            <Grid item xs={12} sm={12} sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}>
              <FindYourNextPlanQuotit></FindYourNextPlanQuotit>
            </Grid>
            {user?.isAnonymous &&
              <Grid item xs={12} sm={12}>
              </Grid>
            }
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={"primary.main"}>
        <Container sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          py: 8,
        }}>
          <Grid container
            alignItems={"top"}
            spacing={2}
            sx={{
              py: 2,
            }}>

            <Grid item xs={12} display={"flex"} justifyContent={"center"} sx={{

            }}>
              <Box sx={{
                maxWidth: "540px",
                textAlign: "center"
              }}>
                <Typography variant="h1" color="white.main" sx={{ fontSize: { xs: "1.8rem", sm: "2rem", ms: "2.25rem" } }}>How Can When <Box component={"span"} fontWeight={600}>Help?</Box></Typography>
                <Typography color="white.main" sx={{ fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "1rem" } }}>When has worked with Health Insurance Experts to gather the information you need to know about Health Insurance:</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h2" color="white.main" fontWeight={600} sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem", ms: "1.8rem" } }}>Health Insurance Overview</Typography>
                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                      fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                      paddingY: { xs: 0.5, ms: 1 },
                      paddingX: { xs: 1, ms: 2 }
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/health-insurance-overview`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h2" color="white.main" fontWeight={600} sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem", ms: "1.8rem" } }}>What is COBRA?</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                      fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                      paddingY: { xs: 0.5, ms: 1 },
                      paddingX: { xs: 1, ms: 2 }
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/cobra`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h2" color="white.main" fontWeight={600} sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem", ms: "1.8rem" } }}>Picking a Plan</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                      fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                      paddingY: { xs: 0.5, ms: 1 },
                      paddingX: { xs: 1, ms: 2 }
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/picking-a-plan`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography variant="h2" color="white.main" fontWeight={600} sx={{ fontSize: { xs: "1.2rem", sm: "1.3rem", ms: "1.8rem" } }}>Frequently Asked Questions</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                      fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                      paddingY: { xs: 0.5, ms: 1 },
                      paddingX: { xs: 1, ms: 2 }
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/frequently-asked-questions`) }}
                  >Go</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        py: 4
      }}>
        <Container>
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} sm={7} sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
              <Typography variant="h1" sx={{ fontSize: { xs: "1.8rem", sm: "2rem", ms: "2.25rem" } }}>Understanding COBRA and Your Options</Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem", ms: "1rem" } }}>COBRA is not actually insurance. It&apos;s a federal law in the United States that requires employers to allow former employees to remain on an employer-sponsored health plan for up to 18 months. It does not, however, require employers to subsidize those plans. That means you often pay significantly more for COBRA plans than you paid as an employee.</Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem", ms: "1rem" } }}>The Affordable Care Act of 2012 offers some alternatives to COBRA plans. They are often more affordable, but they may not offer the same benefits as your employer plan. Your should read the plan details closely to ensure a plan is right for you and your family.</Typography>
              <Typography sx={{ fontSize: { xs: "0.8rem", sm: "0.9rem", ms: "1rem" } }}>Beyond regular insurance plans, there are other options like Short Term Medical.</Typography>
            </Grid>
            <Grid item xs={12} sm={5}><img src="/images/Thinking.svg" alt="Thinking" width="100%" /></Grid>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={openPreferences}
        onClose={() => { setPlanRefresh(planRefresh + 1); setOpenPreferences(false); }}
      >
        <Box>
          <PreferencesModal close={() => { setPlanRefresh(planRefresh + 1); setOpenPreferences(false); }}></PreferencesModal>
        </Box>
      </Dialog>
    </Box >
  );
}
export default RecommendedPlans;
