import {
  Typography,
  Box,
  Card,
  Button,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Cancel,
} from "@mui/icons-material";
import {
  HIPAAViewReasons,
  HIPAAHumanReasons,
  HIPAAViewReasonsType,
} from "services/Interfaces";

interface HIPPAJustificationDialogProps {
  caseId: string;
  companyId: string;
  reasonDialogOpen: boolean;
  setReasonDialogOpen: (open: boolean) => void;
  reason: HIPAAViewReasonsType | "";
  setReason: (reason: HIPAAViewReasonsType) => void;
  noReasonSelectedMessage: string;
  getCase: (params: { variables: { caseId: string; companyId: string; reason: HIPAAViewReasonsType } }) => void;
  setNoReasonSelectedMessage: (message: string) => void;
}

function HIPPAJustificationDialog({ caseId, companyId, reasonDialogOpen, setReasonDialogOpen, reason, setReason, noReasonSelectedMessage, getCase, setNoReasonSelectedMessage }: HIPPAJustificationDialogProps) {
  return (
    <Dialog maxWidth="md" fullWidth open={reasonDialogOpen}>
        <Card>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              position: "relative",
            }}
          >
            <Typography variant="h2">Please select your reason for viewing this case.</Typography>
            <Select
              value={reason}
              displayEmpty
              onChange={(e) => {
                setReason(e.target.value as HIPAAViewReasonsType);
              }}
            >
              <MenuItem disabled value={""} selected>
                <em>Select a reason</em>
              </MenuItem>
              {Object.keys(HIPAAViewReasons).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {HIPAAHumanReasons(key)}
                  </MenuItem>
                );
              })}
            </Select>
            {noReasonSelectedMessage && !reason && <Typography color="error">{noReasonSelectedMessage}</Typography>}
            <Button
              onClick={() => {
                if (reason) {
                  getCase({ variables: { caseId, companyId, reason } });
                } else {
                  setNoReasonSelectedMessage("Please select reason to view this case");
                }
              }}
            >
              Submit
            </Button>
            <Cancel
              onClick={() => setReasonDialogOpen(false)}
              color="inherit"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            />
          </Box>
        </Card>
      </Dialog>
    );
}

export default HIPPAJustificationDialog;