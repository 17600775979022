import { CobraElectionReasonType, HealthInsuranceDecisionType } from "services/GraphQl";

export const getInsuranceDecisionLabel = (decision: HealthInsuranceDecisionType): string => {
  const labels: Record<HealthInsuranceDecisionType, string> = {
    [HealthInsuranceDecisionType.Cobra]: "Elected COBRA",
    [HealthInsuranceDecisionType.DeclineToDisclose]: "Declined Coverage (No Reason)",
    [HealthInsuranceDecisionType.NewEmployment]: "New Employer Coverage",
    [HealthInsuranceDecisionType.MedicaidExternal]: "Enrolled in Medicaid (External)",
    [HealthInsuranceDecisionType.MedicareExternal]: "Enrolled in Medicare (External)",
    [HealthInsuranceDecisionType.OffExchangeExternal]: "Enrolled Off Exchange (External)",
    [HealthInsuranceDecisionType.AcaOnExchangeExternal]: "Enrolled via ACA On Exchange (External)",
    [HealthInsuranceDecisionType.Parents]: "Parent's Plan",
    [HealthInsuranceDecisionType.Spouse]: "Spouse's Plan",
    [HealthInsuranceDecisionType.UnknownCaseClosed]: "Unknown",
    [HealthInsuranceDecisionType.AcaOnExchangeVelapoint]: "Enrolled via ACA On Exchange (Velapoint)",
    [HealthInsuranceDecisionType.AcaOnExchangeWhen]: "Enrolled via ACA On Exchange (When)",
    [HealthInsuranceDecisionType.MedicareVelapoint]: "Enrolled in Medicare (Velapoint)",
    [HealthInsuranceDecisionType.MedicareWhen]: "Enrolled in Medicare (When)",
    [HealthInsuranceDecisionType.OffExchangeVelapoint]: "Enrolled Off Exchange (Velapoint)",
    [HealthInsuranceDecisionType.OffExchangeWhen]: "Enrolled Off Exchange (When)",
    [HealthInsuranceDecisionType.ShortTermExternal]: "Enrolled Short Term (External)",
    [HealthInsuranceDecisionType.ShortTermVelapoint]: "Enrolled Short Term (Velapoint)",
    [HealthInsuranceDecisionType.ShortTermWhen]: "Enrolled Short Term (When)",
    [HealthInsuranceDecisionType.Pending]: "Pending",
    [HealthInsuranceDecisionType.Uninsured]: "Uninsured",
  };
  return labels[decision];
};

export const getCobraElectionReasonLabel = (reason: CobraElectionReasonType | null): string | null => {
  if (!reason) {
    return null;
  }

  const labels: Record<CobraElectionReasonType, string> = {
    [CobraElectionReasonType.AccumulatorReset]: "Accumulator Reset",
    [CobraElectionReasonType.CobraMoreAffordable]: "COBRA More Affordable",
    [CobraElectionReasonType.ContinuityOfCare]: "Continuity of Care",
    [CobraElectionReasonType.NeededBackdatedCoverage]: "Needed Backdated Coverage",
    [CobraElectionReasonType.NoReasonProvided]: "No Reason Provided",
    [CobraElectionReasonType.PrescriptionDrugCoverage]: "Prescription Drug Coverage",
    [CobraElectionReasonType.ProviderAccess]: "Provider Access",
    [CobraElectionReasonType.Other]: "Other",
  };
  return labels[reason];
};

export const parseEmailToName = (email: string) => {
  // Check if email has a test indicator (+)
  const hasTestIndicator = email.includes("+");
  // Remove everything after + if it exists
  const cleanEmail = email.split("+")[0];
  // Remove domain part
  const localPart = cleanEmail.split("@")[0];
  // Split by dot
  const parts = localPart.split(".");

  // Capitalize first letter of each part
  const formatName = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  let name;
  if (parts.length > 1) {
    // Case: firstname.lastname@...
    name = `${formatName(parts[0])} ${formatName(parts[1])}`;
  } else {
    // Case: firstname@...
    name = formatName(parts[0]);
  }

  // Add (Test) if there was a + in the email
  return hasTestIndicator ? `${name} (Test)` : name;
};
