import RequestFiles from "./RequestFiles";

import { Box, Button, Card, DialogTitle, Dialog, Typography, DialogContent, FormControl, TextField } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { doc, collection, query } from "firebase/firestore";
import { WhenBenefitConverter, WhenReimbursementRequestConverter } from "services/Firestore/User";
import { Case, CaseDocument, useUpdateCaseMutation, WhenBenefitSubstatusType } from "services/GraphQl";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { FormattedNumber } from "react-intl";
import { useState } from "react";

function CaseWhenBenefit({ theCase }: { theCase: Case }) {
  const { firestore } = useFirebase();
  const { userClaims } = useAuth();
  const [addWhenBenefitDialogOpen, setAddWhenBenefitDialogOpen] = useState(false);

  const [userBenefit, ,] = useDocument(
    doc(firestore, "users", theCase.syncUser?.id || "0", "whenBenefits", theCase.id || "").withConverter(
      WhenBenefitConverter
    ),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [reimbursementRequests, ,] = useCollection(
    query(
      collection(
        firestore,
        "users",
        theCase.syncUser?.id || "0",
        "whenBenefits",
        theCase.id || "0",
        "reimbursementRequests"
      )
    ).withConverter(WhenReimbursementRequestConverter),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [bankInfo, bankInfoData] = useLazyQuery(Queries.getUserBankInfo);
  const showBankInfo = async () => {
    bankInfo({ variables: { userId: theCase.syncUser?.id || "" } });
  };

  if (!theCase.whenBenefit?.hasBenefit) {
    return (
      <Box>
        <Typography>No When Benefit found</Typography>
        <Button variant="contained" color="primary" onClick={() => setAddWhenBenefitDialogOpen(true)}>
          Add When Benefit
        </Button>
        <AddWhenBenefitDialog theCase={theCase} open={addWhenBenefitDialogOpen} onClose={() => setAddWhenBenefitDialogOpen(false)}  />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Card>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="h2">When Benefit Provided</Typography>
          <Typography>
            Amount: <FormattedNumber value={theCase.whenBenefit?.amount || 0} style={`currency`} currency="USD" />
          </Typography>
          <Typography>
            Active: {theCase.whenBenefit?.status === WhenBenefitSubstatusType.Closed ? "false" : "true"}
          </Typography>
        </Box>
      </Card>
      <Card>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Typography variant="h2">User&apos;s When Benefit</Typography>
            {userClaims.roles?.reimbursements?.editor && (
              <Button variant="contained" color="primary" onClick={showBankInfo} fullWidth={false}>
                View Bank Info
              </Button>
            )}
          </Box>
          {bankInfoData.data && (
            <Typography>Bank Account Number: {bankInfoData.data?.getUserBankInfo?.accountNumber} </Typography>
          )}
          {bankInfoData.data && (
            <Typography>Bank Routing Number: {bankInfoData.data?.getUserBankInfo?.routingNumber} </Typography>
          )}
          {bankInfoData.data && (
            <Typography>Bank Name on Account: {bankInfoData.data?.getUserBankInfo?.nameOnAccount} </Typography>
          )}
          {bankInfoData.error && <Typography>Bank Info: Not Set</Typography>}
          {!userBenefit?.exists() && <Typography>No When Benefit found</Typography>}
          {userBenefit?.exists() && (
            <>
              <Typography>
                Max Benefit:{" "}
                <FormattedNumber value={userBenefit?.data()?.maxBenefit || -1} style={`currency`} currency="USD" />
              </Typography>
              <Typography>
                Remaining Benefit:{" "}
                <FormattedNumber
                  value={userBenefit?.data()?.remainingBenefit || -1}
                  style={`currency`}
                  currency="USD"
                />
              </Typography>
              <Typography>Status: {userBenefit?.data()?.status}</Typography>
              <Typography>
                First request must be before:{" "}
                {userBenefit
                  ?.data()
                  ?.terminationDate.add(
                    userBenefit?.data()?.time.firstRequest.value,
                    userBenefit?.data()?.time.firstRequest.measure
                  )
                  .format("MM/DD/YYYY")}
              </Typography>
              <Typography>
                Max time between requests: {userBenefit?.data()?.time.firstRequest.value}{" "}
                {userBenefit?.data()?.time.firstRequest.measure}
              </Typography>
            </>
          )}
        </Box>
      </Card>
      <Typography variant="h2">Pending Reimbursement Requests</Typography>
      {reimbursementRequests?.docs
        ?.filter(
          (request) =>
            request.data().status === "Pending" || request.data().status === "Additional Information Requested"
        )
        .map((request) => (
          <RequestFiles key={request.id} request={request} firstName={theCase?.contact?.firstName || ""} />
        ))}
      {reimbursementRequests?.docs?.filter(
        (request) => request.data().status === "Pending" || request.data().status === "Additional Information Requested"
      ).length === 0 && <Typography>No pending requests</Typography>}
      <Typography variant="h2">Processed Reimbursement Requests</Typography>
      {reimbursementRequests?.docs
        ?.filter(
          (request) =>
            request.data().status !== "Pending" && request.data().status !== "Additional Information Requested"
        )
        .map((request) => (
          <RequestFiles key={request.id} request={request} firstName={theCase?.contact?.firstName || ""} />
        ))}
      {reimbursementRequests?.docs?.filter(
        (request) => request.data().status !== "Pending" && request.data().status !== "Additional Information Requested"
      ).length === 0 && <Typography>No processed requests</Typography>}
    </Box>
  
  );
}

function AddWhenBenefitDialog({ theCase, open, onClose }: { theCase: Case, open: boolean, onClose: () => void }) {
  const [amount, setAmount] = useState<string | null>(null);
  const [updateCase] = useUpdateCaseMutation({
    refetchQueries: [CaseDocument],
    awaitRefetchQueries: true,
  });

  const onAddWhenBenefit = async () => {
    if (!amount) {
      return;
    }
    await updateCase({
      variables: {
        companyId: theCase.companyId,
        caseId: theCase.id,
        caseInput: {
          whenBenefit: {
            hasBenefit: true,
            amount: parseFloat(amount),
            releasedDate: new Date().toISOString(),
            status: WhenBenefitSubstatusType.Available,
            detail: {
              amountForfeited: 0,
              amountPending: 0,
              amountRemaining: parseFloat(amount),
              amountSpent: 0,
              total: parseFloat(amount),
            },
          },
        },
      },
    });
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add When Benefit</DialogTitle>
      <DialogContent>
        <FormControl>
          <TextField
            label="Amount"
            type="number"
            inputProps={{ step: "0.01" }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={onAddWhenBenefit}>
            Add When Benefit
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
}

export default CaseWhenBenefit;