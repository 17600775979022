import { MenuItem, Select, Paper, Box, Typography, Button, Modal, FormControl, InputLabel } from "@mui/material";
import { getCobraElectionReasonLabel, getInsuranceDecisionLabel } from "../utils";
import {
  useUpdateCaseHealthInsuranceDecisionMutation,
  HealthInsuranceDecisionType,
  CobraElectionReasonType,
  CaseDocument,
  Case,
  UpdateCaseHealthInsuranceDecisionMutationVariables,
} from "services/GraphQl";
import { useState } from "react";

function HealthInsuranceDecisionModal({
  open,
  onClose,
  caseData,
}: {
  open: boolean;
  onClose: () => void;
  caseData: Case;
}) {
  const [healthInsuranceDecision, setHealthInsuranceDecision] = useState<HealthInsuranceDecisionType>(
    HealthInsuranceDecisionType.Pending
  );
  const [cobraReason, setCobraReason] = useState<CobraElectionReasonType>(CobraElectionReasonType.NoReasonProvided);
  const [updateCaseHealthInsuranceDecision] = useUpdateCaseHealthInsuranceDecisionMutation({
    refetchQueries: [CaseDocument],
    awaitRefetchQueries: true,
  });

  const handleUpdateHealthInsuranceDecision = async () => {
    try {
      let variables: UpdateCaseHealthInsuranceDecisionMutationVariables = {
        companyId: caseData.companyId,
        caseId: caseData.id,
        healthInsuranceDecision,
        cobraElectionReason: cobraReason,
      };

      if (healthInsuranceDecision !== HealthInsuranceDecisionType.Cobra) {
        variables.cobraElectionReason = null;
      }
      await updateCaseHealthInsuranceDecision({ variables });
      onClose();
    } catch (error) {
      console.error("Error updating health insurance decision", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box>
        <Paper
          sx={{
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "100vw", sm: "50vw" },
            transform: "translate(-50%, -50%)",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography>Health Insurance Decision</Typography>
          <FormControl fullWidth>
            <InputLabel id="health-insurance-decision-label">Health Insurance Decision</InputLabel>
            <Select
              fullWidth
              labelId="health-insurance-decision-label"
              id="health-insurance-decision"
              value={healthInsuranceDecision}
              label="Health Insurance Decision"
              onChange={(e) => setHealthInsuranceDecision(e.target.value as HealthInsuranceDecisionType)}
            >
              {Object.values(HealthInsuranceDecisionType).map((decision) => (
                <MenuItem key={decision} value={decision}>
                  {getInsuranceDecisionLabel(decision)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {healthInsuranceDecision === HealthInsuranceDecisionType.Cobra && (
            <>
              <Typography>COBRA Election Reason</Typography>
              <FormControl fullWidth>
                <InputLabel id="cobra-reason-label">COBRA Election Reason</InputLabel>
                <Select
                  fullWidth
                  labelId="cobra-reason-label"
                  id="cobra-reason"
                  value={cobraReason}
                  label="COBRA Election Reason"
                  onChange={(e) => setCobraReason(e.target.value as CobraElectionReasonType)}
                >
                  {Object.values(CobraElectionReasonType).map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {getCobraElectionReasonLabel(reason)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          <Button onClick={handleUpdateHealthInsuranceDecision}>Update</Button>
        </Paper>
      </Box>
    </Modal>
  );
}

export default HealthInsuranceDecisionModal;
