import { Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import {
  Company,
  HIPAAViewReasonsType,
} from "services/Interfaces";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { CompanyConverter } from "services/Firestore";
import { useFirebase } from "contexts/Firebase";
import moment from "moment";
import { useNotifications } from "contexts/Notification";
import { useAuth } from "contexts/User";
import AdminCaseEditDialog from "components/Admin/AdminCaseEditDialog";
import AdminSharedWithEmployeeDialog from "components/Admin/AdminSharedWithEmployeeDialog";
import AdminCreateUserDialog from "components/Admin/AdminCreateUserDialog";
import ReleaseBenefitDialog from "components/Company/ReleaseBenefitDialog";
import HIPPAJustificationDialog from "pages/Admin/Case/components/HIPPAJustification";
import { Mutations, useMutation } from "services/apollo";
import CaseTabs from "pages/Admin/Case/components/CaseTabs";
import { CaseTabsEnum } from "pages/Admin/Case/types";
import CaseInfoDropdown from "pages/Admin/Case/components/CaseInfoDropdown";
import { Case, SharedStatus } from "services/GraphQl";
import { useCaseLazyQuery } from "services/GraphQl";

function CasePage({ caseIdIn, companyIdIn }: { caseIdIn?: string; companyIdIn?: string }) {
  const { hash } = useParams<{ hash: string }>();
  const { notifications } = useNotifications();
  const { user } = useAuth();
  const caseId = caseIdIn || hash?.split("-").pop();
  const companyId = companyIdIn || hash?.split("-").slice(0, -1).join("-");
  const { firestore } = useFirebase();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(true);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const [releaseOpen, setReleaseOpen] = useState(false);
  const [reason, setReason] = useState<HIPAAViewReasonsType | "">("");
  const [noReasonSelectedMessage, setNoReasonSelectedMessage] = useState<string>("");
  const [setReleaseCaseBenefit] = useMutation(Mutations.releaseCaseBenefit);
  const [hasManagedHealth, setHasManagedHealth] = useState(false);
  const [dataTab, setDataTab] = useState<CaseTabsEnum>(
    tab === CaseTabsEnum.benefit ? CaseTabsEnum.benefit : CaseTabsEnum.activity
  ); // controls the page tab
    const [theCase, setTheCase] = useState<Case | null>(null);
    const [getTheCase, { data: theCaseResult, loading: caseLoading, error: caseError }] = useCaseLazyQuery();



  const [getCase, caseAccess] = useLazyQuery(Queries.adminGetCase, {
    fetchPolicy: "network-only",
  });

  const [hipaaRecord, hippaRecordLoading] = useDocument(
    doc(firestore, "hipaa", "access", "hipaa_cases", caseId || "", "hipaa_access", user?.uid || "")
  );

    useEffect(() => {
    if (theCaseResult && theCaseResult.case) {
      setTheCase(theCaseResult.case)
    }
  }, [theCaseResult])

   useEffect(() => {
     if (caseAccess.data?.adminGetCase && moment(caseAccess.data?.adminGetCase.expiration, "x").isAfter(moment())) {
       // reload the page
       console.log("reload the page");
       getTheCase({
         variables: {
           caseId: caseAccess.data?.adminGetCase?.caseId,
           companyId: caseAccess.data?.adminGetCase?.companyId,
         },
       });
       // setCaseDoc(doc(firestore, 'companies', caseAccess.data?.adminGetCase?.companyId,
       // `cases`, caseAccess.data?.adminGetCase?.caseId).withConverter(CaseConverter))
       window.location.reload();
     } else if (caseId && companyId) {
       console.log("get the case");
       getTheCase({ variables: { caseId: caseId, companyId: companyId } });
       // setCaseDoc(doc(firestore, 'companies', companyId,
       //   `cases`, caseId).withConverter(CaseConverter));
     }
   }, [
     caseAccess.data?.adminGetCase?.caseId,
     caseAccess.data?.adminGetCase?.companyId,
     caseId,
     companyId,
     caseAccess.data?.adminGetCase,
     getTheCase,
   ]);

  const [company, ,] = useDocument<Company>(
    caseAccess?.data
      ? doc(firestore, `companies`, caseAccess?.data?.adminGetCase.companyId || "").withConverter(CompanyConverter)
      : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (theCase?.sharedStatus === SharedStatus.Shared) {
      // const hasManagedHealth = caseBenefits?.docs.some((benefit) => {
      //   setWhenBenefitSeverance(benefit?.data());
      //   let type = benefit?.data()?.type;
      //   return type === SeveranceBenefitType.MANAGED_HEALTH;
      // });
      setHasManagedHealth(theCase.whenBenefit?.hasBenefit || false);
    }
  }, [theCase]);


  const setReleaseBenefit = async (values: boolean) => {
    if (!companyId || !caseId) return;
    setReleaseOpen(true);

    if (values === true) {
      try {
        const releaseSet = await setReleaseCaseBenefit({
          variables: {
            companyId: companyId,
            caseId: caseId,
          },
        });

        if (releaseSet?.data?.releaseCaseBenefit) {
          setReleaseOpen(false);
          notifications.success("Benefit released successfully");
        } else {
          notifications.error("Benefit not released, something went wrong.");
        }
      } catch (error) {
        console.error(`Benefit not released, error: ${error}`);
      }
    }
  };

  const isCaseShared = theCase?.shareDate !== undefined;
  const toggleShareDialog = () => setShareDialogOpen(!shareDialogOpen);
  const toggleEditDialog = () => setEditDialogOpen(!editDialogOpen);

  const caseData = theCase;

  const userEmail = caseData?.contact?.email || undefined;
  const userDateOfBirth = caseData?.contact?.dateOfBirth;
  const userZip = caseData?.contact?.address?.zip;

  const addNewUserProps = {
    email: userEmail,
    firstName: (caseData?.contact?.firstName as string) || undefined,
    lastName: (caseData?.contact?.lastName as string) || undefined,
    dateOfBirth: userDateOfBirth || undefined,
    zipCode: (userZip as string) || undefined,
  };

  if (!caseId || !companyId || caseAccess.error) {
    return (
      <Box>
        <Typography variant="h1">Something went wrong</Typography>
        <Typography>{caseError?.message}</Typography>
        <Typography>{caseAccess?.error?.message}</Typography>
      </Box>
    );
  }
  if (hippaRecordLoading) {
    return (
      <Box>
        <Typography>Loading HIPAA Record</Typography>
      </Box>
    );
  }

  const isHIPPAExpired =
    !hipaaRecord?.exists() ||
    (hipaaRecord?.exists() &&
      hipaaRecord?.get("expiration") &&
      moment(hipaaRecord?.get("expiration")).isBefore(moment()));

  if (isHIPPAExpired) {
    return (
      <HIPPAJustificationDialog
        caseId={caseId}
        companyId={companyId}
        reasonDialogOpen={reasonDialogOpen}
        setReasonDialogOpen={setReasonDialogOpen}
        reason={reason}
        setReason={setReason}
        noReasonSelectedMessage={noReasonSelectedMessage}
        getCase={getCase}
        setNoReasonSelectedMessage={setNoReasonSelectedMessage}
      />
    );
  }
  if (caseLoading) {
    return (
      <Box>
        <Typography variant="h1">Loading Case</Typography>
      </Box>
    );
  }

  if (!theCase) {
    return (
      <Box>
        <Typography variant="h1">Case not found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <CaseInfoDropdown
        theCase={theCase}
        company={company}
        toggleEditDialog={toggleEditDialog}
        toggleShareDialog={toggleShareDialog}
        setCreateUserDialogOpen={setCreateUserDialogOpen}
        setReleaseOpen={setReleaseOpen}
        setReleaseBenefit={setReleaseBenefit}
        hasManagedHealth={hasManagedHealth}
      />
      <CaseTabs
        theCase={theCase}
        firestore={firestore}
        dataTab={dataTab}
        setDataTab={setDataTab}
        caseId={caseId}
      />
      <AdminCaseEditDialog
        editCaseOpen={editDialogOpen}
        setEditCaseOpen={toggleEditDialog}
        theCase={theCase}
        caseId={theCase.id}
      />
      <AdminSharedWithEmployeeDialog
        sharedDialogOpen={shareDialogOpen}
        toggleSharedDialog={toggleShareDialog}
        theCase={theCase}
        caseId={theCase.id}
        isCaseShared={isCaseShared}
      />
      <AdminCreateUserDialog
        open={createUserDialogOpen}
        onClose={() => {
          setCreateUserDialogOpen(false);
        }}
        companyId={companyId}
        caseId={caseId}
        newUserProps={addNewUserProps}
      />
      <ReleaseBenefitDialog
        releaseOpen={releaseOpen}
        setReleaseOpen={setReleaseOpen}
        setReleaseBenefit={setReleaseBenefit}
      />
    </Box>
  );
}

export default CasePage;
