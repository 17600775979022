import React from 'react';
import { Button } from '@mui/material';
import { doc, setDoc, Firestore } from 'firebase/firestore';

interface CreateBenefitPlansProps {
  firestore: Firestore;
}

const CreateBenefitPlans = ({ firestore }: CreateBenefitPlansProps) => {
  const benefitPlans = [
    {
      id: 'z5DaipzS8fHeCEdMh5O8',
      data: {
        carrier: "Anthem Blue Cross",
        coverageTierStructure: "4-Tier",
        createdAt: new Date(),
        familyDeductible: 2000,
        familyOutOfPocketMaximum: 6000,
        fourTierPremium: {
          employeeOnly: {
            employeeContribution: 50,
            employerContribution: 500,
            totalPremium: 550
          },
          employeePlusChildren: {
            employeeContribution: 80,
            employerContribution: 900,
            totalPremium: 980
          },
          employeePlusSpouse: {
            employeeContribution: 100,
            employerContribution: 1000,
            totalPremium: 1100
          },
          family: {
            employeeContribution: 150,
            employerContribution: 1700,
            totalPremium: 1850
          }
        },
        individualDeductible: 1000,
        individualOutOfPocketMaximum: 3000,
        name: "Anthem PPO",
        plan: ["Medical"],
        planEndDate: "12/31/2026",
        planNetworkType: "PPO",
        planStartDate: "01/01/2024",
        updatedAt: new Date()
      }
    },
    {
      id: 'qvep8DOaf3b5ICQrf3Xm',
      data: {
        carrier: "Kaiser Permanente",
        coverageTierStructure: "4-Tier",
        createdAt: new Date(),
        familyDeductible: 500,
        familyOutOfPocketMaximum: 2000,
        fourTierPremium: {
          employeeOnly: {
            employeeContribution: 0,
            employerContribution: 400,
            totalPremium: 400
          },
          employeePlusChildren: {
            employeeContribution: 75,
            employerContribution: 800,
            totalPremium: 875
          },
          employeePlusSpouse: {
            employeeContribution: 50,
            employerContribution: 900,
            totalPremium: 950
          },
          family: {
            employeeContribution: 100,
            employerContribution: 1700,
            totalPremium: 1800
          }
        },
        individualDeductible: 250,
        individualOutOfPocketMaximum: 1000,
        name: "Kaiser HMO",
        plan: ["Medical"],
        planEndDate: "12/31/2026",
        planNetworkType: "HMO",
        planStartDate: "01/01/2024",
        updatedAt: new Date()
      }
    }
  ];

  const handleCreateBenefitPlans = async () => {
    try {
      for (const plan of benefitPlans) {
        const planRef = doc(firestore, 'companies/when/benefitPlans', plan.id);
        await setDoc(planRef, plan.data);
      }
      console.log('Benefit plans created successfully');
    } catch (error) {
      console.error('Error creating benefit plans:', error);
    }
  };

  return (
    <Button 
      onClick={handleCreateBenefitPlans}
      className="bg-primary hover:bg-primary/90 text-white"
      // sx={{ marginLeft: 2 }} 
    >
      Create Benefit Plans
    </Button>
  );
};

export default CreateBenefitPlans;