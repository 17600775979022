/** @jsxImportSource @emotion/react */
import {
  Typography,
  Box,
  Button,
  Dialog,
  FormControl,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AdminCaseActivityConverter } from "services/Firestore";
import {
  AdminCaseActivity,
  CaseActivityChange,
  CaseActivityTypes,
} from "services/Interfaces";
import { useEffect, useReducer, useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuth } from "contexts/User";
import { useNotifications } from "contexts/Notification";
import { useFirebase } from "contexts/Firebase";
import { validateEmailFormat, validateZipCode } from "services/validation";
import { Case, CaseEventTypes, TerminationTypes, useUpdateCaseMutation } from "services/GraphQl";
import { convertEnumToString } from "utils/helpers";


function addActivityChange(
  changes: CaseActivityChange[],
  field: string,
  oldValue: any,
  newValue: any
) {
  if (oldValue !== newValue) {
    changes.push({
      field,
      oldValue,
      newValue,
    });
  }
}

interface FormState {
  lastDay: Moment | null;
  terminationDate: Moment | null;
  phone: string;
  email: string;
  terminationType: TerminationTypes;
  eventType: CaseEventTypes;
  // reason: CaseReason | string;
  dateOfBirth: Moment | null;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

type FormAction =
  | { type: "SET_LAST_DAY"; payload: Moment | null }
  | { type: "SET_TERMINATION_DATE"; payload: Moment | null }
  | { type: "SET_PHONE"; payload: string }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_TERMINATION_TYPE"; payload: TerminationTypes }
  | { type: "SET_EVENT_TYPE"; payload: CaseEventTypes }
  | { type: "SET_DATE_OF_BIRTH"; payload: Moment | null }
  | { type: "SET_STREET1"; payload: string }
  | { type: "SET_STREET2"; payload: string }
  | { type: "SET_CITY"; payload: string }
  | { type: "SET_STATE"; payload: string }
  | { type: "SET_ZIP"; payload: string };

const initialState: FormState = {
  lastDay: null,
  terminationDate: null,
  phone: "",
  email: "",
  terminationType: TerminationTypes.Involuntary,
  eventType: CaseEventTypes.InvoluntaryTermination,
  dateOfBirth: null,
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
};

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case "SET_LAST_DAY":
      return { ...state, lastDay: action.payload };
    case "SET_TERMINATION_DATE":
      return { ...state, terminationDate: action.payload };
    case "SET_PHONE":
      return { ...state, phone: action.payload };
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_TERMINATION_TYPE":
      return { ...state, terminationType: action.payload };
    case "SET_EVENT_TYPE":
      return { ...state, eventType: action.payload };
    case "SET_DATE_OF_BIRTH":
      return { ...state, dateOfBirth: action.payload };
    case "SET_STREET1":
      return { ...state, street1: action.payload };
    case "SET_STREET2":
      return { ...state, street2: action.payload };
    case "SET_CITY":
      return { ...state, city: action.payload };
    case "SET_STATE":
      return { ...state, state: action.payload };
    case "SET_ZIP":
      return { ...state, zip: action.payload };
    default:
      return state;
  }
}

const AdminCaseEditDialog = ({
  editCaseOpen,
  setEditCaseOpen,
  theCase,
  caseId,
}: {
  editCaseOpen: boolean;
  setEditCaseOpen: Function;
  theCase?: Case;
  caseId?: any;
}) => {
  const { firestore } = useFirebase();
  const [error, setError] = useState<string>("");
  const { userData } = useAuth();
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [invalidTerminationDate, setInvalidTerminationDate] = useState("");
  const [invalidLastDate, setInvalidLastDate] = useState("");
  const { notifications } = useNotifications();
  const [updateCase] = useUpdateCaseMutation();

  // when the case loads set the values
  useEffect(() => {
    if (theCase) {
      dispatch({
        type: "SET_TERMINATION_DATE",
        payload: moment(theCase?.terminationDate, "MM/DD/YYYY"),
      });
      dispatch({
        type: "SET_LAST_DAY",
        payload: moment(theCase?.lastDay, "MM/DD/YYYY"),
      });
      dispatch({
        type: "SET_PHONE",
        payload: theCase?.contact?.phone || "",
      });
      dispatch({
        type: "SET_EMAIL",
        payload: theCase?.contact?.email || "",
      });
      dispatch({
        type: "SET_TERMINATION_TYPE",
        payload: theCase?.terminationType!,
      });
      dispatch({ type: "SET_EVENT_TYPE", payload: theCase?.event?.type! });
      dispatch({
        type: "SET_DATE_OF_BIRTH",
        payload: theCase?.contact?.dateOfBirth
          ? moment(theCase?.contact.dateOfBirth)
          : null,
      });
      dispatch({
        type: "SET_STREET1",
        payload: theCase?.contact?.address?.street1 || "",
      });
      dispatch({
        type: "SET_STREET2",
        payload: theCase?.contact?.address?.street2 || "",
      });
      dispatch({
        type: "SET_CITY",
        payload: theCase?.contact?.address?.city || "",
      });
      dispatch({
        type: "SET_STATE",
        payload: theCase?.contact?.address?.state || "",
      });
      dispatch({
        type: "SET_ZIP",
        payload: theCase?.contact?.address?.zip || "",
      });
    }
  }, [theCase, editCaseOpen]);

  const handleCancel = () => {
    setEditCaseOpen(false);
    setError("");
  };

  const handleSave = async () => {
    if (formState.terminationDate) {
      if (!userData || !theCase) return;

      if (formState.lastDay?.isAfter(formState.terminationDate)) {
        setError("Last Day of Work cannot be after Termination Date");
        return;
      }

      if (!validateEmailFormat(formState.email)) {
        setError("Please enter a valid email address");
        return;
      }

      if (!validateZipCode(formState.zip)) {
        setError("Invalid Zip Code format");
        return;
      }

      if (invalidTerminationDate || invalidLastDate) return;
      const changes: CaseActivityChange[] = [];

      const formatDate = (date: Moment | null | undefined) =>
        date ? date.format("MM/DD/YYYY") : null;

      addActivityChange(
        changes,
        "Termination Date",
        formatDate(theCase?.terminationDate),
        formatDate(formState.terminationDate)
      );
      addActivityChange(
        changes,
        "Last Day",
        formatDate(theCase?.lastDay),
        formatDate(formState.lastDay)
      );
      addActivityChange(
        changes,
        "Street Address 1",
        theCase?.contact?.address?.street1,
        formState.street1
      );
      addActivityChange(
        changes,
        "Street Address 2",
        theCase?.contact?.address?.street2,
        formState.street2
      );
      addActivityChange(
        changes,
        "City",
        theCase?.contact?.address?.city,
        formState.city
      );
      addActivityChange(
        changes,
        "State",
        theCase?.contact?.address?.state,
        formState.state
      );
      addActivityChange(
        changes,
        "Zip Code",
        theCase?.contact?.address?.zip,
        formState.zip
      );
      addActivityChange(
        changes,
        "Date of Birth",
        formatDate(theCase?.contact?.dateOfBirth),
        formatDate(formState.dateOfBirth)
      );
      addActivityChange(
        changes,
        "Email",
        theCase?.contact?.email,
        formState.email
      );
      addActivityChange(
        changes,
        "Phone",
        theCase?.contact?.phone,
        formState.phone
      );
      addActivityChange(
        changes,
        "Termination Type",
        theCase?.event?.termination?.type,
        formState.terminationType
      );
      addActivityChange(
        changes,
        "Event Type",
        theCase?.event?.type,
        formState.eventType
      );


      // should not trigger klaviyo event yet, just make an edit to the doc
      await updateCase({
        variables: {
          caseId: caseId,
          companyId: theCase?.companyId,
          caseInput: {
            contact: {
              email: formState.email,
              phone: formState.phone,
              address: {
                street1: formState.street1,
                street2: formState.street2,
                city: formState.city,
                state: formState.state,
                zip: formState.zip,
              },
              dateOfBirth: formState.dateOfBirth?.format("MM/DD/YYYY"),
            },
            event: {
              type: formState.eventType,
              date: formState.terminationDate.format("MM/DD/YYYY"),
              termination: {
                type: formState.terminationType,
              }
            },
            terminationDate: formState.terminationDate.format("MM/DD/YYYY"),
            lastDay: formState.lastDay?.format("MM/DD/YYYY"),
            terminationType: formState.terminationType,
          }
        }
      })


      await addDoc<AdminCaseActivity>(
        collection(
          firestore,
          "companies",
          theCase?.companyId,
          "cases",
          theCase.id as string,
          "activity"
        ).withConverter(AdminCaseActivityConverter),
        {
          activity: CaseActivityTypes.CASE_UPDATED,
          type: "admin_action",
          userId: userData.id,
          caseId: theCase.id!,
          companyId: theCase?.companyId,
          displayName: userData.data()?.name?.display || "Name Missing",
          message: "Case Updated",
          accessedBy: userData.id,
          changes,
          timestamp: moment(),
        }
      );
      setEditCaseOpen(false);
      notifications.success("Case updated successfully");
    }
  };

  return (
    <Dialog
      open={editCaseOpen}
      onClose={() => {
        setEditCaseOpen(false);
        setError("");
      }}
    >
      <Box
        sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h2">Edit Case</Typography>
        <FormControl fullWidth sx={{ display: "flex", gap: 2 }}>
          <DatePicker
            label="Last Day of Work"
            value={formState.lastDay}
            onChange={(date) => {
              dispatch({ type: "SET_LAST_DAY", payload: date });
              setError("");
            }}
            onError={(newError) => {
              setInvalidLastDate(
                newError
                  ? "Please provide a date with the following format MM/DD/YYYY"
                  : ""
              );
            }}
          ></DatePicker>
          {invalidLastDate && (
            <Typography color="error">{invalidLastDate}</Typography>
          )}
          <DatePicker
            label="Termination Date"
            value={formState.terminationDate}
            onChange={(date) => {
              dispatch({ type: "SET_TERMINATION_DATE", payload: date });
              setError("");
            }}
            onError={(newError) => {
              setInvalidTerminationDate(
                newError
                  ? "Please provide a date with the following format MM/DD/YYYY"
                  : ""
              );
            }}
          ></DatePicker>
          {invalidTerminationDate && (
            <Typography color="error">{invalidTerminationDate}</Typography>
          )}
          <TextField
            fullWidth
            label="Phone Number"
            value={formState.phone}
            onChange={(e) =>
              dispatch({ type: "SET_PHONE", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Contact Email"
            value={formState.email}
            onChange={(e) => {
              dispatch({ type: "SET_EMAIL", payload: e.target.value });
              setError("");
            }}
            margin="dense"
          />
          <FormControl>
            <FormLabel aria-labelledby="termination-type-label">
              Termination Type
            </FormLabel>
            <RadioGroup
              row
              aria-label="termination-type"
              name="termination-type"
              value={formState.terminationType}
              onChange={(e) => {
                if (
                  Object.values(TerminationTypes).includes(e.target.value as TerminationTypes)
                ) {
                  dispatch({
                    type: "SET_TERMINATION_TYPE",
                    payload: e.target.value as TerminationTypes,
                  });
                }
              }}
            >
              {
                Object.values(TerminationTypes).map((key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={convertEnumToString(key)}
                    />
                  );
                })
              }
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel aria-labelledby="event-type-label">
              Event Type
            </FormLabel>
            <RadioGroup
              row
              aria-label="event-type"
              name="event-type"
              value={formState.eventType}
              onChange={(e) => {
                if (
                  Object.values(CaseEventTypes).includes(e.target.value as CaseEventTypes)
                ) {
                  dispatch({
                    type: "SET_EVENT_TYPE",
                    payload: e.target.value as CaseEventTypes,
                  });
                }
              }}
            >
              {
                Object.values(CaseEventTypes).map((key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={<Radio />}
                      label={convertEnumToString(key)}
                    />
                  );
                })
              }
            </RadioGroup>
          </FormControl>
          <DatePicker
            label="Date of Birth"
            value={formState.dateOfBirth}
            onChange={(date) =>
              dispatch({ type: "SET_DATE_OF_BIRTH", payload: date })
            }
          />
          <TextField
            fullWidth
            label="Street Address 1"
            value={formState.street1}
            onChange={(e) =>
              dispatch({ type: "SET_STREET1", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Street Address 2"
            value={formState.street2}
            onChange={(e) =>
              dispatch({ type: "SET_STREET2", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="City"
            value={formState.city}
            onChange={(e) =>
              dispatch({ type: "SET_CITY", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="State"
            value={formState.state}
            onChange={(e) =>
              dispatch({ type: "SET_STATE", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Zip Code"
            value={formState.zip}
            onChange={(e) => {
              dispatch({ type: "SET_ZIP", payload: e.target.value });
              setError("");
            }}
            margin="dense"
          />
        </FormControl>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          disabled={!!error}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Box>
    </Dialog>
  );
};
export default AdminCaseEditDialog;
