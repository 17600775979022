export const EventTypes = {
    INVOLUNTARY_TERMINATION: "INVOLUNTARY_TERMINATION",
    VOLUNTARY_TERMINATION: "VOLUNTARY_TERMINATION",
    DEPENDENT_LOSS_OF_COVERAGE: "DEPENDENT_LOSS_OF_COVERAGE",
    FAMILY_SEPARATION: "FAMILY_SEPARATION",
    HOURS_REDUCTION: "HOURS_REDUCTION",
    MEDICARE_ELIGIBLE: "MEDICARE_ELIGIBLE",
    LOSS_OF_COVERAGE: "LOSS_OF_COVERAGE",
    STUDENT_LOSS_OF_COVERAGE: "STUDENT_LOSS_OF_COVERAGE",
    HIRE: "HIRE",
    ONBOARDING: "ONBOARDING",
    OPEN_ENROLLMENT: "OPEN_ENROLLMENT",
    COBRA_OE: "COBRA_OE"
} as const;

export const getInitialMessage = (caseType: string, companyName: string = "[company_name]"): string => {
    const caseTypeKey = caseType.toUpperCase().replace(/ /g, '_');
    console.log("Generating initial message for case type:", caseTypeKey);
    
    switch (caseTypeKey) {
        case EventTypes.INVOLUNTARY_TERMINATION:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides during transition out of ${companyName}. I can help with many things from helping you save on health insurance coverage, preparing for interviews, finding and applying to jobs, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case EventTypes.VOLUNTARY_TERMINATION:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides during transition out of ${companyName}. I can help with many things from ensuring you have affordable health insurance coverage, rolling over your 401(k) into an individual account, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case "EARLY_RETIREMENT":
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides during the transition out of ${companyName}. I can help with many things from navigating health insurance options pre- and post-Medicare eligibility, financial planning assistance, finding lower cost prescription drugs, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case EventTypes.DEPENDENT_LOSS_OF_COVERAGE:
        case EventTypes.FAMILY_SEPARATION:
        case EventTypes.HOURS_REDUCTION:
        case EventTypes.MEDICARE_ELIGIBLE:
        case EventTypes.LOSS_OF_COVERAGE:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides during your life event. Whether you or a dependent are losing coverage, I can help find affordable replacement health insurance on the individual market, check eligibility for premium tax credits, and find lower cost prescription drugs. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case EventTypes.STUDENT_LOSS_OF_COVERAGE:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides during the transition out of ${companyName}. I can help with many things from finding alternative health insurance to your company health plan, finding lower prices on your prescriptions, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case EventTypes.HIRE:
        case EventTypes.ONBOARDING:
        case EventTypes.OPEN_ENROLLMENT:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides to employees of ${companyName}. I can help with many things from finding alternative health insurance to your company health plan, finding lower prices on your prescriptions, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
        
        case EventTypes.COBRA_OE:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you access the wide range of resources and support that When provides to former employees of ${companyName}. I can help with many things from finding more affordable health insurance options than COBRA, accessing lower prices on your prescriptions, and more. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
            
        default:
            return `Hi, my name is Jamie. I am When's AI-powered assistant and I'm here to help you. At any point in this process, you can connect with our Concierge Member Support team at 831-228-6412. Let's get started!`;
    }
};

export const formatEventType = (eventType: string): string => {
    return eventType.replace(/_/g, ' ');
}; 