import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { useFirebase } from '../../contexts/Firebase';
import { Container, Typography, Card, List, ListItem, Button, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { format, isValid } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { getInitialMessage } from './JamieComponents/utils';

interface Feedback {
  additionalFeedback: string;
  feedbackType: string;
  messageId: string;
  timestamp: any;
}

interface InsuranceDetails {
  householdIncome: number;
  taxFilingStatus: string;
}

interface Conversation {
  id: string;
  userId: string;
  chatType: string;
  feedbacks: Feedback[];
  hasCobra: boolean;
  messages: any[];
  startTimestamp: any;
  additionalFeedback: string;
  canContact: boolean;
  sentiment: string;
  messageCount: number; 
  feedbackCount: number;
  insuranceDetails: InsuranceDetails;
  eventType: string;
}

interface Plan {
  name: string;
  rate: number;
  deductible: number;
  outOfPocketMax: number;
}

interface BenefitPlanDetails {
  id: string;
  name: string;
  carrier: string;
  planNetworkType: string;
  individualDeductible: number;
  familyDeductible: number;
  individualOutOfPocketMaximum: number;
  familyOutOfPocketMaximum: number;
  fourTierPremium: {
    employeeOnly: {
      employeeContribution: number;
      employerContribution: number;
      totalPremium: number;
    };
    employeePlusChildren: {
      employeeContribution: number;
      employerContribution: number;
      totalPremium: number;
    };
    employeePlusSpouse: {
      employeeContribution: number;
      employerContribution: number;
      totalPremium: number;
    };
    family: {
      employeeContribution: number;
      employerContribution: number;
      totalPremium: number;
    };
  };
}


const ConversationDetails = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const { firestore, auth } = useFirebase();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [, setRawData] = useState<any>(null);
  const navigate = useNavigate();
  const [highlightedMessageId, setHighlightedMessageId] = useState<string | null>(null);
  const [highlightColor, setHighlightColor] = useState<string>('');
  const [animationKey, setAnimationKey] = useState(0); // Used to trigger animation on highlighted message
  const [messageDataVisibility, setMessageDataVisibility] = useState<{ [key: string]: boolean }>({});
  const [companyName, setCompanyName] = useState<string>("[company_name]");

  const toggleMessageData = (messageId: string) => {
    setMessageDataVisibility(prev => ({
      ...prev,
      [messageId]: !prev[messageId]
    }));
  };

  const parseDate = (dateValue: any): Date | null => {
    if (dateValue instanceof Date) {
      return isValid(dateValue) ? dateValue : null;
    }
    
    if (typeof dateValue === 'string') {
      const parsedDate = new Date(dateValue);
      if (isValid(parsedDate)) {
        return parsedDate;
      }
      
      const parts = dateValue.split(' ');
      if (parts.length >= 6) {
        const [month, day, year, time, timezone] = parts;
        const [hours, minutes, seconds] = time.split(':');
        const newDateString = `${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timezone.slice(3, -1)}`;
        const manuallyParsedDate = new Date(newDateString);
        return isValid(manuallyParsedDate) ? manuallyParsedDate : null;
      }
    }
    
    if (typeof dateValue === 'object' && dateValue !== null) {
      if (dateValue.__time__) {
        const firestoreDate = new Date(dateValue.__time__);
        return isValid(firestoreDate) ? firestoreDate : null;
      } else if (dateValue.seconds) {
        const firestoreDate = new Date(dateValue.seconds * 1000);
        return isValid(firestoreDate) ? firestoreDate : null;
      }
    }
    
    // console.error("Unable to parse date:", dateValue);
    return null;
  };

  const formatDate = (date: any): string => {
    if (!date) return 'Invalid Date';
    const parsedDate = parseDate(date);
    return parsedDate ? format(parsedDate, 'PPpp') : 'Invalid Date';
  };

  const getFeedbackColor = (feedbackType: string) => {
    switch (feedbackType) {
      case 'veryDissatisfied': return 'rgba(255, 0, 0, 0.1)';
      case 'dissatisfied': return 'rgba(255, 165, 0, 0.1)';
      case 'neutral': return 'rgba(255, 255, 0, 0.1)';
      case 'satisfied': return 'rgba(0, 128, 0, 0.1)';
      case 'verySatisfied': return 'rgba(0, 0, 255, 0.1)';
      case 'positive': return 'rgba(0, 255, 0, 0.1)'; 
      case 'negative': return 'rgba(255, 0, 0, 0.1)'; 
      default: return 'rgba(211, 211, 211, 0.1)';
    }
  };

  const getFeedbackBorderColor = (feedbackType: string) => {
    switch (feedbackType) {
      case 'positive': return 'green'; // Green border for positive feedback
      case 'negative': return 'red'; // Red border for negative feedback
      default: return 'transparent'; // No border for other feedback types
    }
  };

  const getFeedbackLabel = (feedbackType: string) => {
    switch (feedbackType) {
      case 'veryDissatisfied': return 'Very Dissatisfied';
      case 'dissatisfied': return 'Dissatisfied';
      case 'neutral': return 'Neutral';
      case 'satisfied': return 'Satisfied';
      case 'verySatisfied': return 'Very Satisfied';
      case 'positive': return 'Positive'; 
      case 'negative': return 'Negative'; 
      default: return 'Unknown';
    }
  };

  const getFeedbackShadowColor = (feedbackType: string): string => {
    switch (feedbackType) {
      case 'veryDissatisfied': return 'rgba(139, 0, 0, 0.8)';      // Dark red
      case 'dissatisfied': return 'rgba(205, 92, 92, 0.8)';        // Indian red
      case 'neutral': return 'rgba(189, 183, 107, 0.8)';           // Dark khaki
      case 'satisfied': return 'rgba(85, 107, 47, 0.8)';           // Dark olive green
      case 'verySatisfied': return 'rgba(70, 130, 180, 0.8)';      // Steel blue
      case 'positive': return 'rgba(34, 139, 34, 0.8)';            // Forest green
      case 'negative': return 'rgba(139, 69, 19, 0.8)';            // Saddle brown
      default: return 'rgba(72, 61, 139, 0.8)';                    // Dark slate blue
    }
  };

  const renderBenefitPlanDetails = (planDetails: BenefitPlanDetails) => {
    return (
      <Box 
        sx={{ 
          mt: 2, 
          p: 2, 
          mb: 4, 
          bgcolor: '#f5f5f5', 
          borderRadius: 2, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' 
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="subtitle1" component="div">
            <strong>Selected Benefit Plan Details:</strong>
          </Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell><strong>Plan Name</strong></TableCell>
                <TableCell>{planDetails.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Carrier</strong></TableCell>
                <TableCell>{planDetails.carrier}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Network Type</strong></TableCell>
                <TableCell>{planDetails.planNetworkType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Individual Deductible</strong></TableCell>
                <TableCell>${planDetails.individualDeductible.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Family Deductible</strong></TableCell>
                <TableCell>${planDetails.familyDeductible.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Individual Out-of-Pocket Maximum</strong></TableCell>
                <TableCell>${planDetails.individualOutOfPocketMaximum.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>Family Out-of-Pocket Maximum</strong></TableCell>
                <TableCell>${planDetails.familyOutOfPocketMaximum.toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
  
          <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
            <strong>Premium Tiers:</strong>
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><strong>Coverage Type</strong></TableCell>
                <TableCell align="right"><strong>Employee Contribution</strong></TableCell>
                <TableCell align="right"><strong>Employer Contribution</strong></TableCell>
                <TableCell align="right"><strong>Total Premium</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Employee Only</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeeOnly.employeeContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeeOnly.employerContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeeOnly.totalPremium.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Employee + Children</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusChildren.employeeContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusChildren.employerContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusChildren.totalPremium.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Employee + Spouse</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusSpouse.employeeContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusSpouse.employerContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.employeePlusSpouse.totalPremium.toLocaleString()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Family</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.family.employeeContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.family.employerContribution.toLocaleString()}</TableCell>
                <TableCell align="right">${planDetails.fourTierPremium.family.totalPremium.toLocaleString()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  };

  const renderRecommendedPlans = (plans: Plan[]) => {
    if (!plans || plans.length === 0) return null;  


    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" component="div">Recommended Plans</Typography>
        <Box sx={{ overflowX: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Premium</TableCell>
                <TableCell>Deductible</TableCell>
                <TableCell>Out of Pocket Max</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plans.map((plan, index) => (
                <TableRow key={index}>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>${plan.rate.toFixed(2)}</TableCell>
                  <TableCell>{plan.deductible}</TableCell>
                  <TableCell>{plan.outOfPocketMax}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  };

  const renderHouseholdInfo = (householdData: any) => {
    if (!householdData || !householdData.members) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" component="div">Household Members</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Relationship</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(householdData.members).map(([key, member]: [string, any]) => (
              <TableRow key={key}>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.dob}</TableCell>
                <TableCell>{member.gender}</TableCell>
                <TableCell>{member.relationship}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  useEffect(() => {
    const fetchCompanyName = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const companyId = userDoc.data()?.companyId;
        if (companyId) {
          const companyDoc = await getDoc(doc(firestore, "companies", companyId));
          if (companyDoc.exists()) {
            setCompanyName(companyDoc.data()?.name || "[company_name]");
          }
        }
      }
    };

    fetchCompanyName();
  }, [firestore, auth]);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("User is not authenticated");
        }
    
        const convosRef = collection(firestore, `users/${currentUser.uid}/convos`);
        const convosSnap = await getDocs(convosRef);
    
        const convoDoc = convosSnap.docs.find(doc => doc.id === conversationId);
    
        if (!convoDoc) {
          throw new Error("Conversation not found");
        }
    
        const convoData = convoDoc.data();
        setRawData(convoData);
        console.log("Raw conversation data:", convoData);
    
        // Fetch all messages for the user
        const messagesRef = collection(firestore, `users/${currentUser.uid}/messages`);
        const messagesSnap = await getDocs(messagesRef);
    
        console.log("Number of messages fetched:", messagesSnap.size);
    
        const messageTextMap = new Map();
        messagesSnap.forEach(doc => {
          const messageData = doc.data();
          console.log("Message data:", messageData);
          if (messageData.text) {
            messageTextMap.set(messageData.text, doc.id);
          } else {
            console.warn("Message without text field:", doc.id, messageData);
          }
        });
    
        console.log("Message Text Map size:", messageTextMap.size);
    
        const formattedConvo: Conversation = {
          id: convoDoc.id,
          userId: convoData.userId || 'Unknown', 
          chatType: convoData.chatType || 'Unknown',
          hasCobra: convoData.hasCobra || false,
          startTimestamp: convoData.startTimestamp,
          eventType: convoData.eventType || 'Unknown',
          messages: (convoData.messages || []).map((message: any) => {
            let messageRefId = 'Not found';
            if (message.text) {
              messageRefId = messageTextMap.get(message.text) || 'Not found';
              if (messageRefId === 'Not found') {
                console.warn(`No matching message found for text: ${message.text.substring(0, 50)}...`);
              }
            } else {
              console.warn("Message in conversation without text field:", message);
            }
            return {
              ...message,
              data: message.data || {},
              dynamicTemplate: message.dynamicTemplate || '',
              text: message.text || 'No text available',
              timestamp: message.timestamp,
              type: message.type || 'Unknown',
              user: message.user,
              id: message.id,
              messageRefId: messageRefId,
            };
          }),
          feedbacks: (convoData.feedbacks || []).map((feedback: any) => ({
            ...feedback,
            timestamp: feedback.timestamp,
          })),
          additionalFeedback: convoData.additionalFeedback || '',
          canContact: convoData.canContact || false,
          sentiment: convoData.sentiment || '',
          messageCount: (convoData.messages || []).length,
          feedbackCount: (convoData.feedbacks || []).length,
          insuranceDetails: {
            householdIncome: 0,
            taxFilingStatus: 'Not Specified',
          },
        };
    
        // Generate the initial message using the eventType and companyName
        const initialMessage = getInitialMessage(formattedConvo.eventType, companyName);

        // Add the initial message to the beginning of the messages array
        formattedConvo.messages.unshift({
          id: 'initial-message',
          text: initialMessage,
          user: false,
          timestamp: formattedConvo.startTimestamp,
          type: 'text',
          dynamicTemplate: '',
          data: {}
        });
    
        const insuranceDetailsRef = collection(firestore, `users/${currentUser.uid}/answers`);
        const insuranceDetailsSnap = await getDocs(insuranceDetailsRef);
        const insuranceDetailsDoc = insuranceDetailsSnap.docs.find(doc => doc.id === currentUser.uid);
    
        if (insuranceDetailsDoc) {
          const insuranceDetailsData = insuranceDetailsDoc.data();
          formattedConvo.insuranceDetails = {
            householdIncome: insuranceDetailsData.householdIncome || 0,
            taxFilingStatus: insuranceDetailsData.taxFilingStatus || 'Not specified',
          };
        }
    
        console.log("Formatted conversation:", formattedConvo);
        setConversation(formattedConvo);
      } catch (err) {
        console.error("Error fetching conversation:", err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [firestore, auth, conversationId, companyName]);


  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  if (!conversation) {
    return <Typography>No conversation data found</Typography>;
  }

  const CustomParagraph = (props: any) => {
    return <Typography variant="body1" component="p" sx={{ mb: 1, p: 2, borderRadius:10 }} {...props} />;
  };

  const scrollToMessage = (messageId: string, feedbackType: string) => {
    console.log('Attempting to scroll to message:', messageId);
    const element = document.getElementById(`message-${messageId}`);
    if (element) {
      console.log('Found element, scrolling into view');
      setHighlightColor(getFeedbackShadowColor(feedbackType));
      setHighlightedMessageId(messageId);
      setAnimationKey(prev => prev + 1);
      
      element.scrollIntoView({ 
        behavior: 'smooth',
        block: 'center'
      });
      
      setTimeout(() => {
        setHighlightedMessageId(null);
      }, 3000);
    } else {
      console.log('Element not found for messageId:', messageId);
    }
  };

  const scrollToFeedback = (messageId: string) => {
    console.log('Attempting to scroll to feedback for message:', messageId);
    const feedback = conversation?.feedbacks.find(f => f.messageId === messageId);
    
    if (feedback) {
      const element = document.getElementById(`feedback-${feedback.messageId}`);
      if (element) {
        console.log('Found feedback element, scrolling into view');
        setHighlightColor(getFeedbackShadowColor(feedback.feedbackType));
        setHighlightedMessageId(feedback.messageId);
        setAnimationKey(prev => prev + 1);
        
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'center'
        });
        
        setTimeout(() => {
          setHighlightedMessageId(null);
        }, 3000);
      }
    }
  };


  return (
    <Container sx={{ py: 4 }}>
      <Button onClick={() => navigate('/admin/jadmin')} variant="outlined" sx={{ mb: 2 }}>
        Back to Conversations
      </Button>
      <Typography variant="h2" component="h1" sx={{ pb: 2 }}>Conversation Details</Typography>
      <Card sx={{ mb: 4, p: 3 }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Typography variant="body1" component="span">
            <strong>User ID: </strong>
            {conversation.userId && conversation.userId !== 'Unknown' ? (
              <Button
                onClick={() => navigate(`/admin/users/${conversation.userId}`)}
                variant="text"
                size="small"
                sx={{ 
                  minWidth: 'auto',
                  padding: '0 4px',
                  textTransform: 'none',
                  fontFamily: 'inherit',
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  fontWeight: 'inherit',
                  color: 'primary.main',
                  '&:hover': {
                    textDecoration: 'underline',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                {conversation.userId}
              </Button>
            ) : (
              'Unknown'
            )}
          </Typography>
          
          <Typography variant="body1"><strong>Conversation ID: </strong>{conversation.id}</Typography>
          <Typography variant="body1"><strong>Message Count: </strong>{conversation.messageCount}</Typography>
          <Typography variant="body1"><strong>Feedback Count: </strong>{conversation.feedbackCount}</Typography>
          <Typography variant="body1"><strong>Chat Type: </strong>{conversation.chatType}</Typography>
          <Typography variant="body1"><strong>Event Type: </strong>{conversation.eventType}</Typography>
          <Typography variant="body1"><strong>Has Cobra: </strong>{conversation.hasCobra ? 'Yes' : 'No'}</Typography>
          <Typography variant="body1"><strong>Start Timestamp: </strong>{formatDate(conversation.startTimestamp)}</Typography>
          <Typography variant="body1"><strong>Can Contact: </strong>{conversation.canContact ? 'Yes' : 'No'}</Typography>
          <Typography variant="body1"><strong>Last Sentiment: </strong>{conversation.sentiment}</Typography>
          <Typography variant="body1"><strong>Final Feedback: </strong>{conversation.additionalFeedback}</Typography>
          {conversation.messages?.some(msg => msg.selectedPlanId) && (
            <>
              <Typography variant="body1">
                <strong>Selected Plan ID: </strong>
                {conversation.messages.find(msg => msg.selectedPlanId)?.selectedPlanId || 'Not found'}
              </Typography>
              <Typography variant="body1">
                <strong>Selected Plan Name: </strong>
                {conversation.messages.find(msg => msg.selectedPlanDetails)?.selectedPlanDetails?.name || 'Not found'}
              </Typography>
            </>
          )}
        </Box>
      </Card>
      <Typography variant="h4" component="h2">Messages</Typography>
      <List>
      {conversation.messages.map((message: any, index: number) => (
          <ListItem key={index} alignItems="flex-start">
          <Card 
            id={`message-${message.id}`}
            key={`${message.id}-${animationKey}-${highlightedMessageId === message.id ? 'highlighted' : ''}`}
            sx={{ 
              width: '100%', 
              p: 3, 
              mb: 2, 
              bgcolor: message.user ? 'rgba(0, 0, 255, 0.2)' : 'rgba(0, 128, 0, 0.2)',
              transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
              animation: highlightedMessageId === message.id ? 'shadowPulse 3s ease-in-out' : 'none',
              border: highlightedMessageId === message.id ? `2px solid ${highlightColor}` : 'none',
              '@keyframes shadowPulse': {
                '0%': {
                  boxShadow: '0 0 0 rgba(0, 0, 0, 0)'
                },
                '20%': {
                  boxShadow: `0 0 25px ${highlightColor}`
                },
                '60%': {
                  boxShadow: `0 0 15px ${highlightColor.replace(', 0.8)', ', 0.5)')}`
                },
                '100%': {
                  boxShadow: '0 0 0 rgba(0, 0, 0, 0)'
                }
              }
            }}
          >

            <Box sx={{ bgcolor: '#ffffff', p: 1, pr: 2, pl: 2, pt: .5, pb: .5, m: 2, boxShadow: 'none', borderRadius:2 }}>
              <ReactMarkdown components={{
                p: ({node, ...props}) => <CustomParagraph {...props} /> 
              }}>
                {message.text || 'No text available'}
              </ReactMarkdown>
            </Box>

            {message.data && Object.keys(message.data).length > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Button 
                  onClick={() => toggleMessageData(message.id)}
                  variant="contained"
                  size="small"
                  sx={{ m:2 }}
                >
                  {messageDataVisibility[message.id] ? 'Hide Message Data' : 'Show Message Data'}
                </Button>
              </Box>
            )}

            {messageDataVisibility[message.id] && message.data && Object.keys(message.data).length > 0 && (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {message.data && message.data.oldInsuranceDetails && (
                      <Box sx={{ flex: 1, mr: 2, p:2 }}>
                        <Typography variant="subtitle1" component="div">
                          <strong>Old Insurance Details:</strong>
                        </Typography>
                        <Typography variant="caption" component="div">
                          Zipcode: {message.data.oldInsuranceDetails.zipcode}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Effective Date: {message.data.oldInsuranceDetails.effectiveDate}
                        </Typography>
                        <Typography variant="caption" component="div">
                        Check Premium Tax Credits: {message.data.oldInsuranceDetails.checkPremiumTaxCredits ? 'Yes' : 'No'}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Native American/Alaskan Native: {message.data.oldInsuranceDetails.native ? 'Yes' : 'No'}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Household Income: ${message.data.oldInsuranceDetails.householdIncome}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Tax Filing Status: {message.data.oldInsuranceDetails.taxFilingStatus}
                        </Typography>
                        {renderHouseholdInfo(message.data.oldInsuranceDetails.household)}
                      </Box>
                    )}
                    {message.data && message.data.newInsuranceDetails && (
                      <Box sx={{ flex: 1, p:2 }}>
                        <Typography variant="subtitle1" component="div">
                          <strong>New Insurance Details:</strong>
                        </Typography>
                        <Typography variant="caption" component="div">
                          Zipcode: {message.data.newInsuranceDetails.zipcode}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Effective Date: {message.data.newInsuranceDetails.effectiveDate}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Check Premium Tax Credits: {message.data.newInsuranceDetails.checkPremiumTaxCredits ? 'Yes' : 'No'}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Native American/Alaskan Native: {message.data.newInsuranceDetails.native ? 'Yes' : 'No'}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Household Income: ${message.data.newInsuranceDetails.householdIncome}
                        </Typography>
                        <Typography variant="caption" component="div">
                          Tax Filing Status: {message.data.newInsuranceDetails.taxFilingStatus}
                        </Typography>
                        {renderHouseholdInfo(message.data.newInsuranceDetails.household)}
                      </Box>
                    )}
                  </Box>
                  {message.data && (message.data.oldPreferences || message.data.newPreferences) && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', mt: 2, width: '100%' }}>
                      {message.data.oldPreferences && (
                        <Box sx={{ flex: 1, mr: 2 }}>
                          <Typography variant="subtitle1" component="div">
                            <strong>Old Preferences</strong>
                          </Typography>
                          <Table size="small">
                            <TableBody>
                              {message.data.oldPreferences.lowPremium !== undefined && (
                                <TableRow>
                                  <TableCell>Low Premium</TableCell>
                                  <TableCell>{message.data.oldPreferences.lowPremium.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.oldPreferences.comprehensiveCoverage !== undefined && (
                                <TableRow>
                                  <TableCell>Comprehensive Coverage</TableCell>
                                  <TableCell>{message.data.oldPreferences.comprehensiveCoverage.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.oldPreferences.dental !== undefined && (
                                <TableRow>
                                  <TableCell>Dental</TableCell>
                                  <TableCell>{message.data.oldPreferences.dental.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.oldPreferences.lowDeductible !== undefined && (
                                <TableRow>
                                  <TableCell>Low Deductible</TableCell>
                                  <TableCell>{message.data.oldPreferences.lowDeductible.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.oldPreferences.mentalHealth !== undefined && (
                                <TableRow>
                                  <TableCell>Mental Health</TableCell>
                                  <TableCell>{message.data.oldPreferences.mentalHealth.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.oldPreferences.planType && (
                                <>
                                  {message.data.oldPreferences.planType.hmo !== undefined && (
                                    <TableRow>
                                      <TableCell>HMO</TableCell>
                                      <TableCell>{message.data.oldPreferences.planType.hmo.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                  {message.data.oldPreferences.planType.ppo !== undefined && (
                                    <TableRow>
                                      <TableCell>PPO</TableCell>
                                      <TableCell>{message.data.oldPreferences.planType.ppo.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                  {message.data.oldPreferences.planType.shortterm !== undefined && (
                                    <TableRow>
                                      <TableCell>Short Term</TableCell>
                                      <TableCell>{message.data.oldPreferences.planType.shortterm.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                </>
                              )}
                              {message.data.oldPreferences.vision !== undefined && (
                                <TableRow>
                                  <TableCell>Vision</TableCell>
                                  <TableCell>{message.data.oldPreferences.vision.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      )}
                      {message.data.newPreferences && (
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" component="div">
                            <strong>New Preferences</strong>
                          </Typography>
                          <Table size="small">
                            <TableBody>
                              {message.data.newPreferences.lowPremium !== undefined && (
                                <TableRow>
                                  <TableCell>Low Premium</TableCell>
                                  <TableCell>{message.data.newPreferences.lowPremium.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.newPreferences.comprehensiveCoverage !== undefined && (
                                <TableRow>
                                  <TableCell>Comprehensive Coverage</TableCell>
                                  <TableCell>{message.data.newPreferences.comprehensiveCoverage.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.newPreferences.dental !== undefined && (
                                <TableRow>
                                  <TableCell>Dental</TableCell>
                                  <TableCell>{message.data.newPreferences.dental.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.newPreferences.lowDeductible !== undefined && (
                                <TableRow>
                                  <TableCell>Low Deductible</TableCell>
                                  <TableCell>{message.data.newPreferences.lowDeductible.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.newPreferences.mentalHealth !== undefined && (
                                <TableRow>
                                  <TableCell>Mental Health</TableCell>
                                  <TableCell>{message.data.newPreferences.mentalHealth.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                              {message.data.newPreferences.planType && (
                                <>
                                  {message.data.newPreferences.planType.hmo !== undefined && (
                                    <TableRow>
                                      <TableCell>HMO</TableCell>
                                      <TableCell>{message.data.newPreferences.planType.hmo.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                  {message.data.newPreferences.planType.ppo !== undefined && (
                                    <TableRow>
                                      <TableCell>PPO</TableCell>
                                      <TableCell>{message.data.newPreferences.planType.ppo.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                  {message.data.newPreferences.planType.shortterm !== undefined && (
                                    <TableRow>
                                      <TableCell>Short Term</TableCell>
                                      <TableCell>{message.data.newPreferences.planType.shortterm.toFixed(1)}</TableCell>
                                    </TableRow>
                                  )}
                                </>
                              )}
                              {message.data.newPreferences.vision !== undefined && (
                                <TableRow>
                                  <TableCell>Vision</TableCell>
                                  <TableCell>{message.data.newPreferences.vision.toFixed(1)}</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      )}
                    </Box>
                  )}
                  </Box>
                  {message.data.recommendedPlans && renderRecommendedPlans(message.data.recommendedPlans)}
                  {message.data.selectedBenefitPlan && renderBenefitPlanDetails(message.data.selectedBenefitPlan)}
                </Box>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pt: 3 }}>
                <Typography variant="caption" component="div">
                  <strong>Message </strong>#{index + 1}
                </Typography>
                {message.id !== 'initial-message' && (
                  <Typography variant="caption" component="div">
                    <strong>Message ID:</strong> {message.messageRefId}
                  </Typography>
                )}
                {conversation.feedbacks.some(f => f.messageId === message.id) && (
                  <Typography variant="caption" component="div">
                    <strong>Feedback ID:</strong>{' '}
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => scrollToFeedback(message.id)}
                      sx={{ 
                        minWidth: 'auto',
                        padding: '0 4px',
                        textTransform: 'none',
                        textDecoration: 'underline',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        fontWeight: 'inherit',
                        color: 'primary.main',
                        '&:hover': {
                          textDecoration: 'underline',
                          backgroundColor: 'transparent'
                        }
                      }}
                    >
                      {message.id}
                    </Button>
                  </Typography>
                )}
                {message.selectedPlanId && (
                  <Typography variant="caption" component="div">
                    <strong>Selected Plan ID:</strong> {message.selectedPlanId}
                  </Typography>
                )}
                <Typography variant="caption" component="div">
                  <strong>Timestamp:</strong> {formatDate(message.timestamp)}
                </Typography>
              </Box>
            </Card>
          </ListItem>
        ))}
      </List>
      <Typography variant="h4" component="h2">Feedback</Typography>
      <List>
        {conversation.feedbacks.map((feedback, index) => (
          <ListItem key={index}>
            <Card 
              id={`feedback-${feedback.messageId}`}
              sx={{ 
                width: '100%', 
                p: 2, 
                mb: 2, 
                bgcolor: getFeedbackColor(feedback.feedbackType),
                border: `2px solid ${getFeedbackBorderColor(feedback.feedbackType)}`,
                transition: 'box-shadow 0.3s ease-in-out, border 0.3s ease-in-out',
                animation: highlightedMessageId === feedback.messageId ? 'shadowPulse 3s ease-in-out' : 'none',
                '@keyframes shadowPulse': {
                  '0%': {
                    boxShadow: '0 0 0 rgba(0, 0, 0, 0)'
                  },
                  '20%': {
                    boxShadow: `0 0 25px ${highlightColor}`
                  },
                  '60%': {
                    boxShadow: `0 0 15px ${highlightColor.replace(', 0.8)', ', 0.5)')}`
                  },
                  '100%': {
                    boxShadow: '0 0 0 rgba(0, 0, 0, 0)'
                  }
                }
              }}
            >
              <Box>
              {feedback.additionalFeedback && feedback.additionalFeedback.trim() !== '' && (
                  <Typography variant="body1" component="div">{feedback.additionalFeedback}</Typography>
                )}<Typography variant="caption" component="div"><strong>Sentiment:</strong> {getFeedbackLabel(feedback.feedbackType)}</Typography>
                <Typography variant="caption" component="div">
                  <strong>Feedback ID:</strong>{' '}
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => scrollToMessage(feedback.messageId, feedback.feedbackType)}
                    sx={{ 
                      minWidth: 'auto',
                      padding: '0 4px',
                      textTransform: 'none',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                      lineHeight: 'inherit',
                      fontWeight: 'inherit',
                      color: 'primary.main',
                      '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent'
                      }
                    }}
                  >
                    {feedback.messageId}
                  </Button>
                </Typography>
                <Typography variant="caption" component="div"><strong>Timestamp:</strong> {formatDate(feedback.timestamp)}</Typography>
              </Box>
            </Card>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default ConversationDetails;