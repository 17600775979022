import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import moment from "moment";
import { doc, DocumentData, Firestore, QuerySnapshot } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";

function CaseActivityTab({ activity, firestore }: { activity: QuerySnapshot<DocumentData>; firestore: Firestore }) {
  return (
    <>
      {activity?.docs
        ?.map((act) => {
          const data = act.data();
          if (data.timestamp && data.timestamp.toDate) {
            data.timestamp = moment(data.timestamp.toDate());
          } else if (data.timestamp && typeof data.timestamp === "string") {
            data.timestamp = moment(data.timestamp);
          }
          return data;
        })
        .sort((a, b) => b.timestamp.diff(a.timestamp))
        .map((activity) => {
          return (
            <Accordion key={activity.id} sx={{ mb: 1 }}>
              <AccordionSummary>
                <Box>
                  <Typography variant="h3" color="primary">
                    {activity.activity}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                    <Typography variant="subtitle1">user: </Typography>
                    <UserName uid={activity.userId} firestore={firestore} />
                  </Box>
                  <Typography variant="subtitle1">
                    time: {activity.timestamp.format && activity.timestamp.format("MM/DD/YYYY H:mm:ss A")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {activity.message ? <Typography variant="body1">message: {activity.message}</Typography> : null}
              </AccordionDetails>
            </Accordion>
          );
        })}
      ;
    </>
  );
}

function UserName({ uid, firestore }: { uid: string; firestore: Firestore }) {
  const [user, ,] = useDocument(doc(firestore, "users", uid));
  if (!user || !user.get("name.display")) {
    return <Typography>{uid}</Typography>;
  }
  return <Typography>{user.get("name.display")}</Typography>;
}

export default CaseActivityTab;
