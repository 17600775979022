import { Box, Button, Chip, Typography } from "@mui/material";
import { CaseStatus, SharedStatus, Case } from "services/GraphQl";
import { Business, Info, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
interface CaseUserDetailsProps {
  company: any;
  userName: string;
  toggleEditDialog: () => void;
  toggleShareDialog: () => void;
  setCreateUserDialogOpen: (open: boolean) => void;
  setReleaseOpen: (open: boolean) => void;
  hasManagedHealth: boolean;
  caseData: Case;
}

function CaseUserDetails({ company, userName, toggleEditDialog, toggleShareDialog, setCreateUserDialogOpen, setReleaseOpen, hasManagedHealth, caseData }: CaseUserDetailsProps) {
  const navigate = useNavigate();
  const isCaseShared = caseData?.sharedStatus === SharedStatus.Shared;
  const shouldShowAddNewUser = !caseData?.syncUser?.id && caseData?.sharedStatus === SharedStatus.Shared;

  console.log("====== CASE DATA =======", caseData);
  return (
    <>
      {company?.get("logo") && (
        <Box
          component={"img"}
          src={company.get("logo")}
          width={"200px"}
          sx={{
            mx: "auto",
          }}
        ></Box>
      )}
      {!company?.get("logo") && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h3">{userName}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Button variant="contained" color="inherit" onClick={toggleEditDialog}>
                Edit Case
              </Button>
              <Button variant="contained" color="primary" onClick={toggleShareDialog}>
                {isCaseShared ? "Re-share Case" : "Share Case"}
              </Button>
              {shouldShowAddNewUser && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCreateUserDialogOpen(true);
                  }}
                >
                  Create User
                </Button>
              )}
              {caseData?.sharedStatus === SharedStatus.Shared &&
                hasManagedHealth &&
                caseData?.status !== CaseStatus.Closed && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={() => {
                      setReleaseOpen(true);
                    }}
                  >
                    Release Benefit
                  </Button>
                )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Chip
              label={
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                  <Business /> <Typography>{caseData?.companyId}</Typography>
                </Box>
              }
            />
            {caseData?.childCompany?.name && (
              <Chip
                label={
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                    <Business /> <Typography>{caseData?.childCompany?.name}</Typography>
                  </Box>
                }
              />
            )}
            {caseData?.syncUser && (
              <Chip
                onClick={() => {
                  navigate(`/admin/users/${caseData?.syncUser?.id}`);
                }}
                label={
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                    <Person />
                    User Profile
                  </Box>
                }
              />
            )}
            {!caseData?.syncUser && (
              <Chip
                label={
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                    <Person />
                    No User
                  </Box>
                }
              />
            )}
            <Chip
              label={
                <Box
                  sx={{
                    color: ["active", "OPEN", "completed"].includes(caseData?.status)
                      ? "success.main"
                      : "error.main",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Info />
                  <Typography>{caseData?.status}</Typography>
                </Box>
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default CaseUserDetails;
