import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Slider, Button, Grid } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { doc, getDoc, setDoc } from "firebase/firestore";

type PlanTypePreferences = {
    hmo: number;
    ppo: number;
    shortterm: number;
};

type Preferences = {
    comprehensiveCoverage: number;
    dental: number;
    lowDeductible: number;
    lowPremium: number;
    mentalHealth: number;
    networkSize: number;
    planType: PlanTypePreferences;
    vision: number;
};

const initialPreferences: Preferences = {
    comprehensiveCoverage: 0,
    dental: 0,
    lowDeductible: 0,
    lowPremium: 0,
    mentalHealth: 0,
    networkSize: 0,
    planType: {
        hmo: 0.5,
        ppo: 0.5,
        shortterm: 0.5,
    },
    vision: 0,
};

const preferenceLabels: { [key in keyof Preferences]: string } = {
    comprehensiveCoverage: "Comprehensive Coverage",
    dental: "Dental",
    lowDeductible: "Low Deductible",
    lowPremium: "Low Premium",
    mentalHealth: "Mental Health",
    networkSize: "Network Size",
    planType: "Plan Type",
    vision: "Vision",
};

interface EnrollmentPreferencesProps {
    onPreferencesSaved?: () => void;
    onPreferencesChanged?: (hasUnsavedChanges: boolean) => void;
}

const EnrollmentPreferences: React.FC<EnrollmentPreferencesProps> = ({ 
    onPreferencesSaved,
    onPreferencesChanged 
}) => {
    const { firestore } = useFirebase();
    const { user } = useAuth();
    const [preferences, setPreferences] = useState<Preferences>(initialPreferences);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        const fetchPreferences = async () => {
            if (user?.uid) {
                const docRef = doc(firestore, `users/${user.uid}/answers/preferences`);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data() as Preferences;
                    setPreferences(data);
                }
            }
        };
        fetchPreferences();
    }, [firestore, user?.uid]);

    const updatePreferences = useCallback((key: keyof Preferences, value: number) => {
        setPreferences(prev => ({ ...prev, [key]: value }));
        setHasChanges(true);
        onPreferencesChanged?.(true);
    }, [onPreferencesChanged]);

    const updatePlanTypePreferences = useCallback((key: keyof PlanTypePreferences, value: number) => {
        setPreferences(prev => ({
            ...prev,
            planType: { ...prev.planType, [key]: value }
        }));
        setHasChanges(true);
        onPreferencesChanged?.(true);
    }, [onPreferencesChanged]);

    const savePreferences = useCallback(async () => {
        if (!user?.uid) {
            console.error("User is not authenticated");
            return;
        }

        const preferencesRef = doc(firestore, `users/${user.uid}/answers/preferences`);
        await setDoc(preferencesRef, preferences, { merge: true });
        setHasChanges(false);
        onPreferencesChanged?.(false);
        onPreferencesSaved?.();
    }, [user?.uid, firestore, preferences, onPreferencesSaved, onPreferencesChanged]);

    return (
        <Box sx={{ maxWidth: '100%', width: '100%' }}>
            <Grid 
                container 
                spacing={2} 
                sx={{ 
                    maxWidth: '600px',  // Limit maximum width
                    margin: '0 auto'    // Center the content
                }}
            >
                <Grid item xs={12}>
                    <Typography color="primary" variant="subtitle1" fontWeight={600}>Plan Types</Typography>
                </Grid>
                
                {Object.entries(preferences.planType).map(([key, value]) => (
                    <Grid item xs={12} key={key}>
                        <Box sx={{ px: 1 }}>
                            <Typography>
                                {key === "shortterm" ? "Short Term" : key.toUpperCase()}
                            </Typography>
                            <Slider
                                value={value}
                                onChange={(_, v) => updatePlanTypePreferences(key as keyof PlanTypePreferences, v as number)}
                                step={0.1}
                                marks
                                min={0}
                                max={1}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(v) => {
                                    if (v <= 0.3) return "Not Interested";
                                    if (v <= 0.6) return "Somewhat Interested";
                                    return "Strongly Prefer";
                                }}
                            />
                        </Box>
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <Typography color="primary" variant="subtitle1" fontWeight={600} sx={{ mt: 1 }}>
                        Coverage Preferences
                    </Typography>
                </Grid>
                
                {["lowPremium", "lowDeductible", "comprehensiveCoverage", "mentalHealth", "dental", "vision"].map((key) => {
                    const value = preferences[key as keyof Preferences];
                    const label = preferenceLabels[key as keyof Preferences];
                    return (
                        <Grid item xs={12} key={key}>
                            <Box sx={{ px: 1 }}>
                                <Typography>{label}</Typography>
                                <Slider
                                    value={value as number}
                                    onChange={(_, newValue) => updatePreferences(key as keyof Preferences, newValue as number)}
                                    min={0}
                                    max={0.9}
                                    step={0.1}
                                    valueLabelDisplay="auto"
                                    valueLabelFormat={(v) => {
                                        if (v <= 0.3) return "Not Important";
                                        if (v <= 0.6) return "Somewhat Important";
                                        return "Very Important";
                                    }}
                                />
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
            
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!hasChanges}
                    onClick={savePreferences}
                >
                    Save Preferences
                </Button>
            </Box>
        </Box>
    );
};

export default EnrollmentPreferences; 