import { Box, Divider, IconButton, Typography } from "@mui/material";
import { FormattedNumber } from "react-intl";
import { Case, HealthInsuranceDecisionType } from "services/GraphQl";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { getCobraElectionReasonLabel, getInsuranceDecisionLabel } from "../utils";
import HealthInsuranceDecisionModal from "./HealthInsuranceDecisionModal";
import AdminCaseAssigneeDialog from "components/Admin/AdminCaseAssigneeDialog";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, where } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { parseEmailToName } from "pages/Admin/Case/utils";

interface CaseDetailsListProps {
  caseData: Case;
}

interface DetailFieldProps {
  label: string;
  value: any;
  isNumber?: boolean;
  isAssignee?: boolean;
  onAssignClick?: () => void;
}

function AssigneeField({ label, value, onAssignClick }: DetailFieldProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Typography
        component="span"
        sx={{
          color: "text.secondary",
          width: "250px",
          flexShrink: 0,
        }}
      >
        {label}:
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 1, justifyContent: "space-between" }}>
        <Typography component="span">{value || "None"}</Typography>
        <IconButton onClick={onAssignClick} size="small" sx={{ color: (theme) => theme.palette.primary.main }}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}

function DetailField({ label, value, isNumber = false }: DetailFieldProps) {
  const formattedValue = isNumber ? <FormattedNumber value={value} /> : value;

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Typography
        component="span"
        sx={{
          color: "text.secondary",
          width: "250px",
          flexShrink: 0,
        }}
      >
        {label}:
      </Typography>
      <Typography component="span">{formattedValue || "—"}</Typography>
    </Box>
  );
}

function HealthInsuranceDecisionDetail({
  healthInsuranceDecision,
  setHealthDecisionModalOpen,
}: {
  healthInsuranceDecision: HealthInsuranceDecisionType | null;
  setHealthDecisionModalOpen: (open: boolean) => void;
}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <DetailField
        label="Health Insurance Decision"
        value={getInsuranceDecisionLabel(healthInsuranceDecision || HealthInsuranceDecisionType.Pending)}
      />
      <IconButton color="primary" size="small" onClick={() => setHealthDecisionModalOpen(true)}>
        <EditIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

function CaseDetailsList({ caseData }: CaseDetailsListProps) {
  const [healthDecisionModalOpen, setHealthDecisionModalOpen] = useState(false);
  const { firestore } = useFirebase();
  const [assigneeDialogOpen, setAssigneeDialogOpen] = useState(false);

  // Get list of admin users to find the assignee's name
  const [adminUsers] = useCollection(
    query(collection(firestore, 'adminUsers'), where('active', '==', true)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const getAssigneeName = () => {
    if (!caseData?.assignee || !adminUsers) return null;
    
    const assigneeDoc = adminUsers.docs.find(doc => doc.id === caseData.assignee);
    if (!assigneeDoc) return null;

    const userData = assigneeDoc.data();
    if (userData.name?.first) {
      return `${userData.name.first} ${userData.name.last}`;
    }
    return userData.email ? parseEmailToName(userData.email) : null;
  };

  const handleAssigneeClick = () => {
    setAssigneeDialogOpen(true);
  };

  const handleAssigneeClose = async () => {
    setAssigneeDialogOpen(false);
  };

  const fullAddress = `${caseData?.contact?.address?.street1}, ${
    caseData?.contact?.address?.street2 ? `${caseData?.contact?.address?.street2}, ` : ""
  }${caseData?.contact?.address?.city}, ${caseData?.contact?.address?.state} ${caseData?.contact?.address?.zip}`;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
          <DetailField label="Date of Birth" value={caseData?.contact?.dateOfBirth} />
          <DetailField label="Phone" value={caseData?.contact?.phone} />
          <DetailField label="Email" value={caseData?.contact?.email} />
          <DetailField label="Address" value={fullAddress} />
          <DetailField label="Event Date" value={caseData?.event?.date} />
          <DetailField
            label="Reason"
            value={caseData?.event?.termination?.reason?.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase())}
          />
          <DetailField
            label="Event"
            value={caseData?.event?.type?.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase())}
          />
          <DetailField label="Created At" value={caseData?.createdAt} />
          <DetailField label="Last Updated" value={caseData?.updatedAt} />
          <HealthInsuranceDecisionDetail
            healthInsuranceDecision={caseData?.healthInsuranceDecision || HealthInsuranceDecisionType.Pending}
            setHealthDecisionModalOpen={setHealthDecisionModalOpen}
          />
          <DetailField label="COBRA Reason" value={getCobraElectionReasonLabel(caseData?.cobraElectionReason || null)} />
        </Box>

        <Divider orientation="vertical" flexItem />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, flex: 1 }}>
          <DetailField label="When Benefit Sub-Status" value={caseData?.whenBenefit?.status} />
          <DetailField label="When Benefit Detail" value={null} />
          <DetailField
            label="Current monthly benefits cost"
            value={caseData?.event?.cobra?.currentContribution || 0}
            isNumber
          />
          <DetailField label="Monthly COBRA cost estimate" value={caseData?.event?.cobra?.estimate || 0} isNumber />
          <DetailField label="Share Status" value={caseData?.sharedStatus} />
          <DetailField label="Share Date" value={caseData?.shareDate} />
          <DetailField label="UID" value={caseData?.syncUser?.id} />
          <DetailField label="Last Activity" value={null} />
          <DetailField label="Follow-up By" value={null} />
          <DetailField label="Case Sync Status" value={caseData?.syncUser ? "Synced" : "Not Synced"} />
          <AssigneeField
            label="Assignee"
            value={getAssigneeName()}
            onAssignClick={handleAssigneeClick}
          />
        </Box>
      </Box>
      <HealthInsuranceDecisionModal
        open={healthDecisionModalOpen}
        onClose={() => setHealthDecisionModalOpen(false)}
        caseData={caseData}
      />
      <AdminCaseAssigneeDialog
        open={assigneeDialogOpen}
        onClose={handleAssigneeClose}
        theCase={caseData}
      />
    </>
  );
}

export default CaseDetailsList;
