import { User } from "firebase/auth";
import { Firestore, doc, setDoc, collection } from "firebase/firestore";
import { Dispatch, SetStateAction } from "react";
import { Button, SxProps } from "@mui/material";

export interface ResetChatButtonProps {
    user: User | null | undefined;
    firestore: Firestore;
    chatType: string;
    hasCobra: boolean;
    selectedEventType: string;
    clear: () => void;
    setConversationId: Dispatch<SetStateAction<string | null>>;
    setIsNewConversation: Dispatch<SetStateAction<boolean>>;
    setMessageThread: Dispatch<SetStateAction<any[]>>;
    setFeedback: Dispatch<SetStateAction<{ [key: string]: string }>>;
    setAdditionalFeedback: Dispatch<SetStateAction<string>>;
    setSelectedSentiment: Dispatch<SetStateAction<string | null>>;
    setFeedbackSubmitted: Dispatch<SetStateAction<boolean>>;
    setSentimentVisible: Dispatch<SetStateAction<boolean>>;
    setShowAllFeedback: Dispatch<SetStateAction<boolean>>;
    setIsResetting: Dispatch<SetStateAction<boolean>>;
    onReset?: () => void;
    buttonText?: string;
    sx?: SxProps;
    disabled: boolean;
}

export function ResetChatButton(props: ResetChatButtonProps) {
    const { 
        user,
        firestore,
        chatType,
        hasCobra,
        selectedEventType,
        clear,
        setConversationId,
        setIsNewConversation,
        setMessageThread,
        setFeedback,
        setAdditionalFeedback,
        setSelectedSentiment,
        setFeedbackSubmitted,
        setSentimentVisible,
        setShowAllFeedback,
        setIsResetting,
        onReset,
        buttonText = "Save/Reset",
        sx,
        disabled
    } = props;

    const reset = async () => {
        if (!user?.uid) {
            console.error("User is not authenticated");
            return;
        }

        setIsResetting(true);
        try {
            // Generate a new conversation ID
            const newConversationId = doc(collection(firestore, "users", user.uid, "convos")).id;
            setConversationId(newConversationId);
            setIsNewConversation(true);

            // Create a new conversation document with current settings
            const convoRef = doc(firestore, `users/${user.uid}/convos/${newConversationId}`);
            const convoData = {
                startTimestamp: new Date(),
                chatType: chatType,
                hasCobra: hasCobra,
                userId: user.uid,
                eventType: selectedEventType,
                messages: [],
                feedbacks: [],
                currentSettings: {
                    insuranceStatus: false,
                    effectiveDate: null,
                    selectedPlan: null,
                    whenBenefits: [],
                    benefitPlan: null,
                    eventType: selectedEventType
                }
            };

            console.log("Creating new conversation with data:", convoData);
            await setDoc(convoRef, convoData);

            // Update user's conversationId
            await setDoc(doc(firestore, "users", user.uid), {
                conversationId: newConversationId
            }, { merge: true });

            // Reset all states
            clear();
            setMessageThread([]);
            setFeedback({});
            setAdditionalFeedback("");
            setSelectedSentiment(null);
            setFeedbackSubmitted(false);
            setSentimentVisible(true);
            setShowAllFeedback(false);

            console.log("Chat reset successfully. New conversation ID prepared:", newConversationId);

            // Call onReset if provided
            if (onReset) {
                onReset();
            } else {
                // Refresh the window if no onReset function is provided
                window.location.reload();
            }

        } catch (error) {
            console.error("Error resetting chat:", error);
            setIsResetting(false);
        }
    };

    return (
        <Button 
            onClick={reset}
            variant="contained"
            color="primary"
            disabled={disabled}
            sx={{ padding: "5px 10px", margin: "10px", bgcolor: 'primary.light', ...sx }}
        >
            {buttonText}
        </Button>
    );
}